import { Injectable } from "@angular/core";
import { Bracelet } from "src/app/models/abm-bracelet/bracelet.model";
import { Observable, catchError, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { EntityResponse } from "src/app/models/entity-response.model";
import { AbstractService } from "./abstract.service";
import { HelperService } from "./helper.service";

@Injectable({
    providedIn: 'root'
})
export class AbmBraceletService extends AbstractService {
    public comboToCancel: string;
    private ms_sellers: string = "/mssellers/api";

    constructor(protected override http: HttpClient, private helperService: HelperService) {
        super(http)
        this.comboToCancel = "0";
    }

    public getBracelet(apies: string, page:number, size:number ): Observable<EntityResponse> {
        return super.httpWafGet(`${this.ms_sellers}/seller_bracelets`, `fuel_station_id:${apies}`, 'id:desc', page, size).pipe(
            map(response => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public addRegister(data: Bracelet): Observable<EntityResponse> {
        return super.httpWafPost(`${this.ms_sellers}/seller_bracelets`, data).pipe(
            map((response) => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public updateRegister(id: number, data: Bracelet): Observable<EntityResponse> {
        return super.httpWafPut(`${this.ms_sellers}/seller_bracelets`, data, id).pipe(
            map((response) => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public unlink(id: number): Observable<EntityResponse> {
        return super.httpWafDelete(`${this.ms_sellers}/seller_bracelets/${id}`).pipe(
            map((response) => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }
}