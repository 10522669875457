import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { Station } from "src/app/modules/app.generated.module";
import { PreventaService } from "src/app/services/preventa.service";
import { SecurityService } from "src/app/services/security.service";
import { StationService } from "src/app/services/station.service";
import { PreventaComandaCombo, PreventaDatePicker, PreventaDatePickerDay } from "src/app/models/datepicker/preventa-date-picker.model";
import { AlertMessage, Status } from "src/app/models/entity-response.model";

@Component({
    selector: 'preventa',
    templateUrl: './preventa.component.html',
    styleUrls: ['../admin/admin.component.scss', './preventa.component.scss']
})

export class PreventaComponent implements OnInit {
    public currentViewName: string = "";
    private apies: number;
    public selectedStation: Station;
    public alert: AlertMessage;
    // bandera para mostrar una u otra vista
    public isLiveView: boolean;
    public isEditView: boolean;
    public isPrevisualizerView: boolean;
    public dataConfig: PreventaDatePicker;
    public beginDate: Date;
    public endDate: Date;
    public isValidTime = true;
    private dataReseted: any;
    public dataPromoConst: string;
    public dataPromo: PreventaComandaCombo[];
    public dataPromoPrevisualizer: PreventaComandaCombo[];
    public isLoadData: boolean;
    private comboCountLimit: number;

    constructor(private stationService: StationService,
        private preventaService: PreventaService,
        private securityService: SecurityService,
        private router: Router) {
        this.isLiveView = true;
        this.isEditView = false;
        this.isPrevisualizerView = false;

        this.isLoadData = false;
        this.comboCountLimit = 4;
        this.alert = new AlertMessage();
        this.dataPromoPrevisualizer = [];
    }

    ngOnInit(): void {
        var self = this;
        console.log(this.apies)
        this.isLoadData = true;
    }

    onChangeCurrentViewName(name: string) {
        this.currentViewName = name;
    }

    // Change view methods
    public liveView(): void {
        this.isLiveView = true;
        this.isEditView = false;
        this.isPrevisualizerView = false;
    }
    public editView(): void {
        this.isLiveView = false;
        this.isEditView = true;
        this.isPrevisualizerView = false;
    }
    public previsualizerView(): void {
        this.dataPromoPrevisualizer = this.dataPromo.filter((x) => x.active == true);
        this.isLiveView = false;
        this.isEditView = false;
        this.isPrevisualizerView = true;
    }
    public publishView() {
        this.setPreventa()
    }
    public unsaveChanges() {
        this.dataPromo = JSON.parse(this.dataPromoConst);
        this.liveView();
    }

    // Allow to activate or not the time zome
    changeStatusTimeZone(status: boolean) {
        let activeCombos = this.dataPromo.filter((x) => x.active == true);
        if (activeCombos.length <= 0 && this.dataPromo.length > 0) {
            this.dataPromo[0].active = true;
            this.dataConfig.active = false;
        }
        this.dataConfig.active = !status;
        this.setDatePickerConfiguration();
    }

    // get Initial data for components
    getData() {
        forkJoin([this.preventaService.getConfigDatePicker(this.apies.toString()),
        this.preventaService.getPromo(this.apies.toString())])
            .subscribe({
                next: (results) => {
                    if (results[0].status == Status.success) {
                        this.dataConfig = <PreventaDatePicker>results[0].data;
                        this.dataReseted = JSON.parse(JSON.stringify(this.dataConfig))
                        this.setTimePickers();
                    }
                    if (results[1].status == Status.success) {
                        this.dataPromo = <PreventaComandaCombo[]>results[1].data;
                        this.dataPromoConst = JSON.stringify(this.dataPromo);
                    }
                    this.isLoadData = true;
                    this.liveView();
                },
                error: (err) => console.error(err)
            });
    }

    // Set data for promo in pre-venta by click
    setDatePickerConfiguration() {
        if (this.beginDate == null || this.endDate == null) {
            this.isValidTime = false;
            return;
        }
        this.isValidTime = true;
        this.setDataConfigTime();

        this.preventaService.setConfigDatePicker(this.apies.toString(), this.dataConfig)
            .subscribe({
                next: (result) => {
                    this.dataReseted = JSON.parse(JSON.stringify(this.dataConfig))
                    this.alert.set("", "success", "La configuración de Franja horaria se <b>actualizó correctamente.</b>", true, 5000);
                },
                error: (error) => console.log("Error :: " + error)
            });
    }

    // Set Time for timepickers
    private setTimePickers(): void {
        console.warn("setTimePickers beginDate " + this.dataConfig.partDayIni)
        console.warn("setTimePickers partDayEnd " + this.dataConfig.partDayEnd)
        this.beginDate = new Date();
        this.beginDate.setMinutes(parseInt(this.dataConfig.minuteIni));
        if (this.dataConfig.partDayIni == 'PM') {
            if (this.dataConfig.hourIni == "12") {
                this.beginDate.setHours(12)
            }
            else {
                this.beginDate.setHours(12 + parseInt(this.dataConfig.hourIni))
            }
        } else {
            this.beginDate.setHours(parseInt(this.dataConfig.hourIni))
        }
        this.endDate = new Date();
        this.endDate.setMinutes(parseInt(this.dataConfig.minuteEnd));
        if (this.dataConfig.partDayEnd == 'PM') {
            if (this.dataConfig.hourEnd == "12") {
                this.endDate.setHours(12)
            }
            else {
                this.endDate.setHours(12 + parseInt(this.dataConfig.hourEnd))
            }
        } else {
            this.endDate.setHours(parseInt(this.dataConfig.hourEnd))
        }
    }

    private setDataConfigTime() {
        this.dataConfig.minuteIni = this.beginDate.getMinutes().toString();
        this.dataConfig.minuteEnd = this.endDate.getMinutes().toString();
        if (this.beginDate.getHours() > 11) {
            this.dataConfig.hourIni = (this.beginDate.getHours() - 12).toString();
            this.dataConfig.partDayIni = 'PM';
        } else {
            this.dataConfig.hourIni = (this.beginDate.getHours()).toString();
            this.dataConfig.partDayIni = 'AM';
        }
        if (this.endDate.getHours() > 11) {
            this.dataConfig.hourEnd = (this.endDate.getHours() - 12).toString();
            this.dataConfig.partDayEnd = 'PM';
        } else {
            this.dataConfig.hourEnd = (this.endDate.getHours()).toString();
            this.dataConfig.partDayEnd = 'AM';
        }
    }

    // Get initial letter for day
    GetDay(dayName: string) {
        return dayName.charAt(0);
    }

    //Update state for day config
    UpdateDataConfigDay(day: PreventaDatePickerDay) {
        this.dataConfig.days.forEach(function (d) {
            if (d.Day === day.Day)
                d.Active = !day.Active;
        })
    }

    // Set data for promo in pre-venta by click
    setPromoDataByClick(combo: PreventaComandaCombo) {
        if (this.dataConfig.active == false) return;
        var t = this.dataPromo.filter(x => x.active == true)
        if (t.length == 1 && combo.active) {
            this.dataConfig.active = false;
        }
        if (t.length > this.comboCountLimit - 1 && !combo.active) {
            return this.alert.set("", "warning", "No puede exceder la selección de " + this.comboCountLimit + " combos", true, 5000);
        }
        this.dataPromo.forEach(function (e) {
            if (e.id == combo.id) {
                e.active = !e.active
            }
        })
    }

    // Set data for promo complete object
    setPreventa(): void {
        var set_combos_active = false;
        var set_combos_inactive = false;
        var set_datePicker = false;
        // set combos true
        let combosTrue = this.dataPromo.filter(x => x.active == true);
        let rt = combosTrue.map(function (x) { return x.id; });
        // set combos false
        let combosFalse = this.dataPromo.filter(x => x.active == false);
        let rf = combosFalse.map(function (x) { return x.id; });
        var t = forkJoin([
            this.preventaService.setPromoById(this.apies.toString(), rt, true),
            this.preventaService.setPromoById(this.apies.toString(), rf, false),
            this.preventaService.setConfigDatePicker(this.apies.toString(), this.dataConfig)
        ]).subscribe({
            next: (results) => {
                if (results[0].status == Status.success) {
                    set_combos_active = results[0].data
                }
                if (results[1].status == Status.success) {
                    set_combos_inactive = results[1].data
                }
                if (results[2].status == Status.success) {
                    set_datePicker = results[2].data
                }
                console.log("show result ---- " + (set_combos_active == set_combos_inactive == set_datePicker))
            },
            error: (err) => console.error(err)
        });
    }

    // Reset data
    resetData() {
        this.dataConfig = JSON.parse(JSON.stringify(this.dataReseted))
        this.setTimePickers();
    }
}