<div
    [ngClass]="{'surtidor-container-full': (surtidor.status == 'any' || surtidor.status == 'Error'),'surtidor-container': (surtidor.status != 'any' && surtidor.status != 'Error'),'surtido-container--focus':isFocused}">
    <panel-surtidor [surtidor]="surtidor" [position]="position" [isFocused]="isFocused" class="panel-surtidor"
        [comandaOrder]="comandaOrder" [cliente]="dataCliente" [mqttError]="mqttError" class="panel-detalle-surtidor">
    </panel-surtidor>
    <panel-vendedor *ngIf="(!(isLive || isDebug) || !(isDisabled_column || mqttError)) && (isLive || isDebug) && (surtidor.status != 'any' && surtidor.status != 'Error')"
        [lector]="sellerPanelReader" [surtidor]="surtidor" class="panel-detalle-vendedor">
    </panel-vendedor>
    <ng-template #elseBlock>
        <div *ngIf="(isLive || isDebug) && clienteError && !(isDisabled_column || mqttError)"
            [ngClass]="['sutidor-layout','conexion','isDisabled','fix-ie-isDisabled']">
            <div class="sutidor-layout-content">
                <header class="sutidor-layout-header">
                    <figure><img src="../../../assets/images/icono-surtidor-sin-conexion.png" /></figure>
                </header>
                <div class="sutidor-layout-message">
                    <span class="sutidor-layout-message-error">
                        Sin conexi&#243;n Serviclub
                    </span>
                    <div class="sutidor-layout-message-separator">
                        <div></div>
                    </div>
                    <span class="sutidor-layout-message-text">
                        No es posible mostrar datos en este momento
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="((isLive || isDebug) && (!isDisabled_column && mqttError)) && (isLive || isDebug)"
            [ngClass]="['sutidor-layout','isDisabled','fix-ie-isDisabled']">
            <div class="sutidor-layout-content">
                <header class="sutidor-layout-header">
                    <figure><img src="../../../assets/images/icono-surtidor-sin-conexion.png" /></figure>
                </header>
                <div class="sutidor-layout-message">
                    <span class="sutidor-layout-message-error">
                        Panel sin informaci&#243;n
                    </span>
                    <div class="sutidor-layout-message-separator">
                        <div></div>
                    </div>
                    <span class="sutidor-layout-message-text">
                        No es posible mostrar datos de la operaci&#243;n en este momento, pero a&#250;n <b>pod&#233;s
                            despachar</b>.
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="((isLive || isDebug) && (isDisabled_column && !mqttError)) && (isLive || isDebug)"
            [ngClass]="['sutidor-layout-disabled','isDisabled','fix-ie-isDisabled']">
            <div class="sutidor-layout-disabled-free"></div>
            <div class="sutidor-layout-disabled-content">
                <header class="sutidor-layout-disabled-header">
                    <figure><img src="../../../assets/images/icono-panel-surtidor-inhabilitado.png" /></figure>
                </header>
                <div class="sutidor-layout-disabled-message">
                    <span class="sutidor-layout-disabled-message-error">
                        Surtidor inhabilitado
                    </span>
                    <div class="sutidor-layout-disabled-message-separator">

                    </div>
                    <span class="sutidor-layout-disabled-message-text">

                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="((isLive || isDebug) && surtidor.status == 'Error' && !mqttError)"
            [ngClass]="['sutidor-layout','isDisabled','fix-ie-isDisabled']"
            style="background-color:#fceeec; max-height:84%; height:84%">
            <div class="sutidor-layout-content">
                <header class="sutidor-layout-header">
                    <figure><img src="../../../assets/images/icon_surtidor_no_disponible.png" /></figure>
                </header>
                <div class="sutidor-layout-message">
                    <span class="sutidor-layout-message-error" style="font-size:28px">
                        Surtidor no disponible
                    </span>
                    <div class="sutidor-layout-message-separator-red">
                        <span></span>
                    </div>
                    <span class="sutidor-layout-message-text" style="font-size:16px">
                        <b class="display-block" style="font-size:20px; margin-bottom:16px">Esperá unos minutos</b>
                        <b>Si el problema persiste levantá una incidencia.</b>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="!isLive && !isDebug" [ngClass]="['sutidor-layout-admin','isDisabled','fix-ie-isDisabled']">
            <div *ngIf="(isLive || isDebug)" class="sutidor-layout-admin-free"></div>
            <div *ngIf="(isLive || isDebug)" class="sutidor-layout-admin-content"></div>
        </div>

    </ng-template>
</div>