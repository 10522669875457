import { Component, OnInit, ViewChild } from "@angular/core";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { SalesReportRequest, Seller } from "src/app/models/abm-bracelet/seller.model";
import { AlertMessage } from "src/app/models/entity-response.model";
import { DispatchFilter } from "src/app/models/sales-report/dispatch-filter.model";
import { Station } from "src/app/modules/app.generated.module";
import { SalesService } from "src/app/services/sales.service";
import { SecurityService } from "src/app/services/security.service";
import { StationBossService } from "src/app/services/station-boss.service";
import { StationService } from "src/app/services/station.service";
import { ConstantRegEx } from "src/app/utils/constant-values";
declare var $: any;

@Component({
    selector: 'sales-report',
    templateUrl: './sales-report.component.html',
    styleUrls: ['../admin/admin.component.scss', './sales-report.component.scss']
})

export class SalesReportComponent implements OnInit {
    @ViewChild('dieselpercentage') dieselpercentage: any;
    @ViewChild('naftapercentage') naftapercentage: any;
    public requestData: SalesReportRequest;
    public isLoadData: boolean;
    public alert: AlertMessage;
    public alertFilter: AlertMessage;
    private fuel_station_id: number = 0;
    public stationSelectedInput: number = 0;
    public stationsFinder: Station[] = [];
    public isStationEmpty: boolean;
    public sellerSelectedInput: string;
    public sellers: Seller[];
    public isSellerEmpty: boolean;
    public isloadingSeller: boolean;
    public isPdfFormat: boolean;
    private _beginDate: Date;
    public beginDate: Date;
    private _endDate: Date;
    public endDate: Date;
    public today: Date;
    private _compareBeginDate: Date;
    public compareBeginDate: Date | undefined;
    private _compareEndDate: Date;
    public compareEndDate: Date | undefined;
    public isHideTimerBegin: boolean;
    public isHideCompareTimerBegin: boolean;
    public isHideCompareTimerEnd: boolean;
    public isHideTimerEnd: boolean;
    public isNafta: boolean;
    public isDiesel: boolean;
    public shiftSummary: boolean;
    public isApp: boolean;
    public NaftaPercentage: number;
    public DieselPercentage: number;
    readonly ConstantRegEx = ConstantRegEx;
    public isShowEditModal: boolean;
    public dispatchFilters: DispatchFilter[];
    public nameFilter: string;
    public showErrorAlreadyExists: boolean;
    public enableSaveFilterButton: boolean;
    public filerToRemoveId: number;

    constructor(private stationService: StationService,
        private stationBossService: StationBossService,
        private securityService: SecurityService,
        private salesService: SalesService) {
        this.fuel_station_id = this.securityService.getApies();
        this.isPdfFormat = false;
        this.sellerSelectedInput = "";
        this.requestData = {
            Format: "XLS",
            Dni: "",
            DateFromEpoch: this.getEpochDate(new Date()),
            DateToEpoch: this.getEpochDate(new Date()),
            DateFromCompareEpoch: this.getEpochDate(new Date()),
            DateToCompareEpoch: this.getEpochDate(new Date()),
            Apies: "",
            DieselTarget: 0,
            IsDiesel: false,
            IsNafta: false,
            NaftaTarget: 0,
            ShiftSummary: false,
            IsApp: false
        };
        this.isLoadData = false;
        this.isStationEmpty = true;
        this.isSellerEmpty = true;
        this.isloadingSeller = false;
        this.sellers = [];
        this.beginDate = this._beginDate = this.endDate = this._endDate = this.today = new Date();
        this._compareBeginDate = this._compareEndDate = new Date();
        this.compareBeginDate = this.compareEndDate = undefined;
        this.isHideTimerBegin = true;
        this.isHideTimerEnd = true;
        this.isHideCompareTimerBegin = true;
        this.isHideCompareTimerEnd = true;
        this.isShowEditModal = true;
        this.alert = new AlertMessage();
        this.alertFilter = new AlertMessage();
        this.showErrorAlreadyExists = false;
        this.enableSaveFilterButton = true;
        this.filerToRemoveId = 0;
        this.isNafta = false;
        this.isDiesel = false;
        this.shiftSummary = false;
        this.isApp = false;
    }

    ngOnInit(): void {
        this.stationService.getStationByApies(this.fuel_station_id)
            .subscribe((result) => {
                this.isLoadData = true;
                var apiesText = result.name;
                result.name = `${result.apiesText} - ${result.name}`;
                this.stationsFinder = new Array(result);
                this.isSellerEmpty = true;
                this.isloadingSeller = true;
                this.stationBossService.getAllSellers(apiesText)
                    .subscribe({
                        next: (result) => {
                            this.sellers = result.map(x => { x.name = `${x.id} - ${x.name}`; return x; });
                            if (this.sellers.length > 0) this.isSellerEmpty = false;
                            else this.isSellerEmpty = true;
                            this.isloadingSeller = false;
                        },
                        error: (errors) => {
                            console.error(errors);
                            this.isSellerEmpty = true;
                            this.isloadingSeller = false;
                        }
                    });
                this.getDispatchFilters();
            });
        this.isStationEmpty = false;
    }

    showTimeBegin() {
        if (this._beginDate != this.beginDate)
            this.isHideTimerBegin = false;
    }

    showTimeCompareBegin() {
        if (this.compareBeginDate != this._compareBeginDate)
            this.isHideCompareTimerBegin = false;
    }

    setDateBegin(date: Date) {
        this.beginDate = date;
        if (date != undefined) {
            this.requestData.DateFromEpoch = this.getEpochDate(date);
        }
    }

    setCompareDateBegin(date: Date) {
        this.compareBeginDate = date;
        this.requestData.DateFromCompareEpoch = date != undefined ? this.getEpochDate(date) : undefined;
    }

    hideTimeBegin(date: Date) {
        this._beginDate = date;
        this.isHideTimerBegin = true;
    }

    hideCompareTimeBegin(date: Date | undefined) {
        if (date != undefined) {
            this._compareBeginDate = date;
            this.isHideCompareTimerBegin = true;
        }
    }

    showTimeEnd() {
        if (this._endDate != this.endDate)
            this.isHideTimerEnd = false;
    }

    showTimeCompareEnd() {
        if (this._compareEndDate != this.compareEndDate)
            this.isHideCompareTimerEnd = false;
    }

    setDateEnd(date: Date) {
        this.endDate = date;
        if (date != undefined) {
            this.requestData.DateToEpoch = this.getEpochDate(date);
        }
    }

    setCompareDateEnd(date: Date) {
        this.compareEndDate = date;
        this.requestData.DateToCompareEpoch = date != undefined ? this.getEpochDate(date) : undefined;
    }

    hideTimeEnd(date: Date) {
        this._endDate = date;
        this.isHideTimerEnd = true;
    }

    hideCompareTimeEnd(date: Date | undefined) {
        if (date != undefined) {
            this._compareEndDate = date;
            this.isHideCompareTimerEnd = true;
        }
    }

    onSelectStation(data: any) {
        var str = "" + data.target.value;
        var pad = "00000"
        var apiesText = pad.substring(0, pad.length - str.length) + str
        this.requestData.Apies = data.target.value;
        var apiesText = apiesText;
        this.isSellerEmpty = true;
        this.isloadingSeller = true;
        this.stationBossService.getAllSellers(apiesText)
            .subscribe({
                next: (result) => {
                    this.sellers = result.map(x => { x.name = `${x.id} - ${x.name}`; return x; });
                    if (this.sellers.length > 0) this.isSellerEmpty = false;
                    else this.isSellerEmpty = true;
                    this.isloadingSeller = false;
                },
                error: (errors) => {
                    console.error(errors);
                    this.isSellerEmpty = true;
                    this.isloadingSeller = false;
                }
            });
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        console.log('Selected value: ', e.value);
    }

    onSelectSeller(data: any) {
        this.requestData.Dni = data.item.id;
    }

    setFormatPdf() {
        this.isPdfFormat = !this.isPdfFormat;
        if (this.isPdfFormat) this.requestData.Format = "PDF";
        else this.requestData.Format = "XLS";
    }

    validateDownloadReport() {
        if (this.beginDate == undefined) {
            return false;
        }

        if (this.endDate == undefined) {
            return false;
        }

        if (!this.isNafta && !this.isDiesel) {
            return false;
        }

        if (this.naftapercentage && !this.naftapercentage?.valid) {
            return false;
        }

        if (this.dieselpercentage && !this.dieselpercentage?.valid) {
            return false;
        }

        return true;
    }

    validateSaveDispatchFilter() {
        if (this.beginDate == undefined) {
            return false;
        }

        if (this.endDate == undefined) {
            return false;
        }

        if (!this.isNafta && !this.isDiesel) {
            return false;
        }

        if (this.dispatchFilters?.length == 20) {
            return false;
        }

        if (this.naftapercentage && !this.naftapercentage?.valid) {
            return false;
        }

        if (this.dieselpercentage && !this.dieselpercentage?.valid) {
            return false;
        }

        return true;
    }

    downloadReport() {

        var daysCurrentPeriod = this.calculateDaysCount(this.beginDate, this.endDate);

        if (daysCurrentPeriod > 90) {
            $('#errorDatesModalShow').modal('show');
            return;
        }

        if (this.compareBeginDate != undefined && this.compareEndDate != undefined) {

            if (this.compareBeginDate > this.beginDate || this.compareEndDate > this.beginDate) {
                $('#errorCompareDatesModalShow').modal('show',);
                return
            }

            var daysComparePeriod = this.calculateDaysCount(this.compareBeginDate, this.compareEndDate);

            if (daysComparePeriod > 90) {
                $('#errorDatesModalShow').modal('show',);
                return
            }
        }

        if (this.sellerSelectedInput == "") {
            this.requestData.Dni = "";
        }

        var seller = this.sellers.filter(x => x.name == this.sellerSelectedInput);
        if (seller.length <= 0 && this.requestData.Dni != "") this.requestData.Dni = "";
        var station = this.stationsFinder.filter(x => x.apies == this.fuel_station_id);
        this.requestData.Apies = this.fuel_station_id.toString();
        if (station.length == 0) {
            return this.alertFilter.set("", "warning", "Asegurese de que los campos tengan los datos correctos", true, 5000);
        }
        if (isNaN(parseInt(this.requestData.DateFromEpoch)) || isNaN(parseInt(this.requestData.DateFromEpoch))) {
            return this.alertFilter.set("", "danger", "El formato de la fecha es incorrecto. Favor de verificar.", true, 5000);
        }
        if (parseInt(this.requestData.DateFromEpoch) > parseInt(this.requestData.DateFromEpoch)) {
            return this.alertFilter.set("", "danger", "La fecha de inicio no puede ser mayor que la fecha fin. Favor de verificar.", true, 5000);
        }

        this.requestData.IsDiesel = this.isDiesel;
        this.requestData.IsNafta = this.isNafta;
        this.requestData.NaftaTarget = this.NaftaPercentage;
        this.requestData.DieselTarget = this.DieselPercentage;
        this.requestData.ShiftSummary = this.shiftSummary;
        this.requestData.IsApp = this.isApp;
        this.salesService.downloadReport(this.requestData);
        return this.alertFilter.set("", "info", "Descargando archivo ...", true, 5000);
    }

    getEpochDate(date: Date): string {
        return (date.getTime()).toString().substr(0, 10);
    }

    getDateFromEpoc(epoch: number) {
        var d = new Date(0);
        d.setUTCSeconds(epoch);
        return d;
    }

    onSelectNafta() {
        this.isNafta = !this.isNafta;
    }

    onSelectDiesel() {
        this.isDiesel = !this.isDiesel;
    }

    onSelectShiftSummary() {
        this.shiftSummary = !this.shiftSummary;
    }

    onSelectIsApp() {
        this.isApp = !this.isApp;
    }

    showModalSaveFilter() {
        this.nameFilter = "";
        this.isShowEditModal = true;
    }

    saveFilter(data: any) {
        this.enableSaveFilterButton = false;
        this.showErrorAlreadyExists = false;
        var dispatchFilter = new DispatchFilter();
        dispatchFilter.apies = this.fuel_station_id;
        dispatchFilter.dateFromEpoch = parseInt(this.requestData.DateFromEpoch);
        dispatchFilter.dateToEpoch = parseInt(this.requestData.DateToEpoch);
        if (this.requestData.DateFromCompareEpoch != undefined)
            dispatchFilter.dateFromCompare = parseInt(this.requestData.DateFromCompareEpoch);
        if (this.requestData.DateToCompareEpoch != undefined)
            dispatchFilter.dateToCompare = parseInt(this.requestData.DateToCompareEpoch);
        dispatchFilter.description = this.nameFilter;
        dispatchFilter.dni = this.requestData.Dni;
        dispatchFilter.naftaTarget = this.NaftaPercentage;
        dispatchFilter.dieselTarget = this.DieselPercentage;
        dispatchFilter.isNafta = this.isNafta;
        dispatchFilter.isDiesel = this.isDiesel;
        dispatchFilter.shiftSummary = this.shiftSummary;
        dispatchFilter.isApp = this.isApp;

        this.salesService.addDispatchFilter(dispatchFilter)
            .subscribe({
                next: (result) => {
                    if (result.data) {
                        this.showErrorAlreadyExists = false;
                        $('#saveFilterModalShow').modal('hide');
                        this.enableSaveFilterButton = true;
                        this.alertFilter.set("", "success", "El filtro fue creado <b> exitosamente</b>", true, 5000);
                        this.getDispatchFilters();
                    }
                    else {
                        this.alertFilter.set("", "danger", result.errors.value, true, 5000);
                    }
                },
                error: (err) => {
                    this.enableSaveFilterButton = true;
                    err.error.data.errors.forEach((element: any) => {
                        if (element.error_code == 4300) {
                            this.showErrorAlreadyExists = true;
                        }
                        if (element.error_code == 4301) {
                            $('#saveFilterModalShow').modal('hide');
                            this.alertFilter.set("", "danger", "Llego al límite máximo de filtros, elimine uno para crear otro nuevo", true, 5000);
                        }
                    });
                }
            })
    }

    calculateDaysCount(dateFrom: Date, dateTo: Date) {
        var diference = dateTo.getTime() - dateFrom.getTime();
        var days = Math.floor(diference / (1000 * 60 * 60 * 24));
        return days;
    }

    getDispatchFilters() {
        this.salesService.getDispatchFilters(this.fuel_station_id)
            .subscribe({
                next: (result) => {
                    this.dispatchFilters = result;
                },
                error: (errors) => {
                    console.error(errors);
                }
            });
    }

    validateSaveFilter() {
        return this.enableSaveFilterButton;
    }

    scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    setFilterIdToRemove(id: number) {
        this.filerToRemoveId = id;
    }

    removeFilter() {
        this.salesService.deleteDispatchFilter(this.filerToRemoveId)
            .subscribe({
                next: (result) => {
                    this.scrollToTop();
                    this.alert.set("", "success", "El filtro fue eliminado <b> exitosamente</b>", true, 5000);
                    this.getDispatchFilters();
                },
                error: (errors) => {
                    console.error(errors);
                    errors.error.data.errors.forEach((element: any) => {
                        if (element.error_code == 4302) {
                            this.alert.set("", "danger", "No existe un filtro con el ID recibido", true, 5000);
                        }
                    });
                }
            });
    }

    selectDispatchFilter(id: number) {
        this.salesService.getDispatchFilterById(id)
            .subscribe({
                next: (result) => {
                    this.sellerSelectedInput = "";
                    if (result.dni != null) {
                        var sellers = this.sellers.filter(x => x.id == result.dni);
                        if (sellers.length > 0) {
                            this.sellerSelectedInput = sellers[0].name;
                            this.requestData.Dni = sellers[0].id;
                        }
                    }

                    this.beginDate = this.getDateFromEpoc(result.dateFromEpoch);
                    this.endDate = this.getDateFromEpoc(result.dateToEpoch);
                    if (result.dateFromCompare != undefined) {
                        this.compareBeginDate = this.getDateFromEpoc(result.dateFromCompare);
                    } else {
                        this.compareBeginDate = undefined;
                    }

                    if (result.dateToCompare != undefined) {
                        this.compareEndDate = this.getDateFromEpoc(result.dateToCompare);
                    } else {
                        this.compareEndDate = undefined;
                    }
                    this.isDiesel = result.isDiesel;
                    this.isNafta = result.isNafta;
                    this.NaftaPercentage = result.naftaTarget;
                    this.DieselPercentage = result.dieselTarget;
                    this.shiftSummary = result.shiftSummary;
                    this.isApp = result.isApp;
                },
                error: (errors) => {

                }
            });
    }
}