import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ComandaOrderCombo, StatusCombo } from "src/app/models/comanda/comanda.model";

@Component({
    animations: [
        trigger('comboStateSliderAnimation', [
            state('hide', style({
                width: '5px'
            })),
            state(StatusCombo.Preparing, style({
                width: '100%'
            })),
            transition('hide => ' + StatusCombo.Preparing, animate('250ms')),
        ]),
        trigger('centerToOutAnimation', [
            state('hidden', style({
                width: 0,
                height: 0,
                top: '50%',
                left: '40%',
                transform: 'scale(1)'
            })),
            state('visible', style({
                width: "50px",
                height: "50px",
                transform: 'scale(80)',
                top: '50%',
                left: '40%'
            })),
            transition('hidden => visible', animate('250ms ease-in'))
        ]),
        trigger('fadeInImageAnimation', [
            state('hidden', style({
                opacity: 0
            })),
            state('visible', style({
                opacity: 1
            })),
            transition('visible => hidden', animate('600ms ease-out')),
            transition('hidden => visible', animate('1000ms ease-in'))
        ]),
        trigger('comboOut', [
            state(StatusCombo.Pending, style({
                opacity: 1
            })),
            state(StatusCombo.Preparing, style({
                opacity: 0
            })),
            state(StatusCombo.Prepared, style({
                display: 'block',
                opacity: 1,
            })),
            state(StatusCombo.Canceling, style({
                height: 0,
                marginLeft: '100%',
                display: 'none'
            })),
            state(StatusCombo.Delivering, style({
                height: 0,
                marginLeft: '100%',
                display: 'none'
            })),
            state(StatusCombo.Delivered, style({
                display: 'block',
                opacity: 1,
            })),

            transition('Pending => Preparing', [
                animate('250ms ease-out')
            ]),
            transition('Preparing => Prepared', [
                animate('250ms')
            ]),
            transition('Pending => Canceling', [
                animate('250ms ease-out')
            ]),
            transition('Prepared => Canceling', [
                animate('250ms')
            ]),
            transition('Prepared => Delivering', [
                animate('100ms')
            ]),
        ])
    ],
    selector: 'comanda-combo',
    templateUrl: './comanda-combo.component.html',
    styleUrls: ['./comanda-combo.component.scss'],
})

/// <summary>
/// DatePeicker component
/// </summary>
export class ComandaComboComponent implements OnInit, OnChanges {

    /* Properties */
    @Input() data: ComandaOrderCombo;
    @Output() cancelEmitter: EventEmitter<string>;
    @Output() comboToChangeStatusEmitter: EventEmitter<ComandaOrderCombo>;
    public statusCombo = StatusCombo;
    private timeElapse: string;
    public showCancelButton: boolean = false;
    private comboStateSlider: string;
    private fadeInAnimation: boolean;
    private centerToOutAnimation: boolean;
    private fadeInImageAnimation: boolean;

    /* Constructor */
    constructor() {
        this.cancelEmitter = new EventEmitter<string>();
        this.comboToChangeStatusEmitter = new EventEmitter<ComandaOrderCombo>();
        this.timeElapse = "";
        this.comboStateSlider = 'hide';
        this.fadeInAnimation = false;
        this.centerToOutAnimation = false;
    }

    /* Initialize  */
    ngOnInit(): void {
        this.setTimeElapse();
        //animations
        let self = this;
        setTimeout(() => { self.fadeInAnimation = true; }, 400);
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    get initialState() {
        return this.data.status;
    }

    get comboStateSliderStaus() {
        return this.comboStateSlider;
    }

    get centerToOutAnimationStatus() {
        return this.centerToOutAnimation ? 'visible' : 'hidden';
    }

    get fadeInImageAnimationStatus() {
        return this.fadeInImageAnimation ? 'visible' : 'hidden';
    }

    private setTimeElapse() {
        if (this.data.status == StatusCombo.Pending || this.data.status == StatusCombo.Prepared) {
            this.timeElapse = this.transform((new Date().getTime() - this.data.orderDate));
            this.showCancelButton = parseInt(this.timeElapse.split(":")[0]) >= 1;
            if (this.data.status == StatusCombo.Pending) {
                setInterval(() => {
                    this.timeElapse = this.transform((new Date().getTime() - this.data.orderDate));
                }, 1000)
            }
        }
    }

    transform(value: number): string {
        value = Math.round(value / 1000)
        let hours = Math.floor(value / 3600);
        let minutes = Math.floor((value - (hours * 3600)) / 60);
        let seconds = value - (hours * 3600) - (minutes * 60);
        // round seconds
        seconds = Math.round(seconds * 100) / 100
        let h = hours - 3;
        let result = (h < 10 ? "0" + h : h) + ":";
        result += (minutes < 10 ? "0" + minutes : minutes);
        result += ":" + (seconds < 10 ? "0" + seconds : seconds);
        return result;
    }

    resetSlider() {
        if (this.data.status == StatusCombo.Prepared || this.data.status == StatusCombo.Delivered || this.data.status == StatusCombo.Canceled) {
            this.comboStateSlider = 'hide';
        }
    }

    changeStatusSlide(id: string, status: StatusCombo) {

        if (status == StatusCombo.Delivered) {
            this.toChangeStatus(id, status);
            return;
        }
        this.comboStateSlider = status;
        if (this.comboStateSlider == StatusCombo.Preparing) {
            setTimeout(() => {
                this.centerToOutAnimation = true;
                this.fadeInImageAnimation = true;
            }, 500);
        }
        setTimeout(() => {
            this.toChangeStatus(id, StatusCombo.Prepared);
        }, 1500)
    }

    /* Methods */
    toCancelCombo(idCombo: string) {
        this.cancelEmitter.emit(idCombo);
    }

    toChangeStatus(idCombo: string, status: StatusCombo) {
        var data = new ComandaOrderCombo();
        data.status = status;
        data.id = idCombo;
        this.comboToChangeStatusEmitter.emit(data);
    }
}