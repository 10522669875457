import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Station } from "src/app/modules/app.generated.module";
import { StateChange, StationService, ViewChange } from "src/app/services/station.service";

@Component({
    selector: 'selected-station',
    templateUrl: './selected-station.component.html',
    styleUrls: ['./selected-station.component.scss', '../sass/grid-layout.scss', '../sass/ie_grid_fix.scss']
})

export class SelectedStationComponent implements OnDestroy, OnInit {

    private subscriptionStation: Subscription;
    private subscriptionView: Subscription;
    private subscriptionState: Subscription;
    public station: Station;
    public isPanelView: boolean = true;

    dummyCallback(): void {

    }
    public currentView: ViewChange = {
        isHidden: true,
        name: "",
        callback: this.dummyCallback
    } as ViewChange;

    public currentState: StateChange = {
        excedeLimite: false,
        hasChanged: false
    } as StateChange;

    ngOnDestroy(): void {
        this.subscriptionStation.unsubscribe();
        this.subscriptionView.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptionStation = this.stationService.getStation().subscribe(station => { this.station = station; });
        this.subscriptionView = this.stationService.getCurrentView().subscribe(view => {
            this.currentView = view;
        });

        this.subscriptionState = this.stationService.getCurrentState().subscribe(view => {
            this.currentState = view;
        });

        var st = this.stationService.getSelectedStation();
        if (st) {
            this.station = st;
        } else {
            this.station = {} as Station;
        }
        var self = this;
    }

    editarPanel() {
        this.stationService.setButtonView("editarPanel");
    }

    previsualizar() {
        this.stationService.setButtonView("previsualizar");
    }

    onCancelar() {
        this.stationService.setButtonView("onCancelar");
    }

    onPublicar() {
        this.stationService.setButtonView("onPublicar");
    }

    constructor(private stationService: StationService, private activatedRoute: ActivatedRoute) {
    }
}