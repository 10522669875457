export class DispatchFilter {
    id: number;
    apies: number;
    dateFromEpoch: number;
    dateToEpoch: number;
    dateFromCompare: number;
    dateToCompare: number;
    description: string;
    dni: string;
    naftaTarget: number;
    dieselTarget: number;
    isNafta: boolean;
    isDiesel: boolean;
    shiftSummary: boolean;
    isApp: boolean;
}