export class YpfToken {
    public fuel_station_id: string;
    public exp: number;
    public jti: string;
    public sub: string;
    public session: any;
}

export enum SecurityType {
    jtw = 1,
    apiKey = 2
}


