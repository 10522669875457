import { Injectable } from "@angular/core";
import { HttpRequest, HttpInterceptor, HttpEvent, HttpHandler } from "@angular/common/http"
import { SecurityService } from "../services/security.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators"
import { environment } from "src/environments/environment";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(private securityService: SecurityService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log("intercepted request ... " + req.url);

        let ypfToken: string = <string>sessionStorage.getItem('ypfToken');
        let securityType: string = <string>sessionStorage.getItem('_stype');
        let apim_subscription = environment.apim_subscription;


        const authReq = req.clone({
            headers: req.headers
                .set(securityType == '2' ? 'X-ACCESS-TOKEN' : 'Authorization', securityType == '2' ? ypfToken : `Bearer ${ypfToken}`)
                .set("Ocp-Apim-Subscription-Key", apim_subscription)
                .set('SecurityType', securityType)
                .set('If-Modified-Since', 'Mon, 26 Jul 1997 05:00:00 GMT')
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
        });

        console.log("Sending request with new header now ...");
        console.log(authReq)

        return next.handle(authReq).pipe(catchError((error, caught) => {
            // call to errorInterceptor
            switch (error.status) {
                case 401:
                    console.warn("Error 401")
                    console.warn(this.securityService)
                    return this.securityService.updateToken()
                        .pipe(
                            map((result: boolean) => {
                                //if got new access token - retry request
                                if (result) {
                                    console.warn("retry intial call")
                                    return this.intercept(req, next);
                                }
                                //otherwise - throw error
                                else {
                                    console.warn("Can\'t refresh the token");
                                    return throwError(() => new Error('Can\'t refresh the token'));
                                }
                            })
                        )
                case 403:
                    console.warn("Error 403");
                    return throwError(() => error);
                default:
                    console.warn("Error occur: " + error.status);
                    return throwError(() => error);
            }
        }) as any);
    }
}