import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { ComandaService } from "src/app/services/comanda.service";
import { ComandaOrderCombo, StatusCombo } from "src/app/models/comanda/comanda.model";

@Component({
    selector: 'comanda',
    templateUrl: './comanda.component.html',
    styleUrls: ['./comanda.component.scss']
})
export class ComandaComponent implements OnInit {

    private apies: number;
    public dataAllCombos: ComandaOrderCombo[];
    public dataPreparePending: ComandaOrderCombo[];
    public dataDeliveryCanceled: ComandaOrderCombo[];
    public isLoadData: boolean;
    public comboToCancel: string
    public _comandaService: ComandaService;
    public isNewEntry: boolean;
    public countEntries: number;
    public dataPreparePendingTemp: number;
    public firtsLoad: boolean;
    public isCancelTab: boolean;
    public dateDesktop: string;
    private soundInterval: any = null;

    constructor(private activatedRoute: ActivatedRoute, private comandaService: ComandaService, private router: Router) {
        this._comandaService = comandaService;
        this.isLoadData = false;
        this.comboToCancel = "0";
        this.countEntries = this.dataPreparePendingTemp = 0;
        this.isCancelTab = false;
        this.isNewEntry = false;
        this.firtsLoad = true;
        this.dateDesktop = (new Date().getHours() + ':' + (new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()))
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        console.log("click para detener sonido")
        this.turnOffSound();
    }

    ngOnInit(): void {
        var self = this;
        this.activatedRoute.params.subscribe(params => {
            var apies = params["apies"];
            if (apies) {
                this.apies = apies;
            } else {
                this.router.navigate(['/home']);
            }
        });

        this.getData();
        this.getDataByInterval();
        setInterval(() => { this.dateDesktop = (new Date().getHours() + ':' + (new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes())) }, 60000)
    }

    getData() {
        forkJoin([this._comandaService.getPendingPreparedCombos(this.apies.toString()),
        this._comandaService.getDeliveredCanceledCombos(this.apies.toString())])
            .subscribe({
                next: (result) => {
                    this.dataAllCombos = (result[0]).concat(result[1]);
                    this.updateComboArrayData(this.dataAllCombos);
                    this.isLoadData = true;
                },
                error: (err) => console.error(err)
            });
    }

    // Set data by interval
    getDataByInterval() {
        setInterval(() => { this.getData() }, 30000)
    }

    checkCancelTab() { this.isCancelTab = true }
    checkPendingTab() { this.isCancelTab = false; this.isNewEntry = false; }


    private updateComboArrayData(data: ComandaOrderCombo[]) {
        this.dataPreparePending = data.filter(x => x.status == StatusCombo.Pending || x.status == StatusCombo.Prepared || x.status == StatusCombo.Preparing || x.status == StatusCombo.Canceling);
        this.dataDeliveryCanceled = data.filter(x => x.status == StatusCombo.Delivered || x.status == StatusCombo.Canceled);

        // Modificar y pasar para una funcion
        if (this.dataPreparePending.length > this.dataPreparePendingTemp && !this.firtsLoad) {
            this.turnOnSound(this);
        }
        this.firtsLoad = false;

        if (!this.isCancelTab) this.dataPreparePendingTemp = this.dataPreparePending.length;
        else this.checkNewEntries();
    }

    public turnOnSound(e: ComandaComponent) {
        var x = document.getElementById("comanda-audio-alert") as HTMLAudioElement;
        let playPromise = x.play().then(_ => { /* execute here*/ }).catch(error => { console.warn("Se intento ejecutar el audio cuando no estaba listo") })
        if (e.soundInterval == null) {
            e.soundInterval = setInterval(() => {
                let playPromise = x.play();
                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                    })
                        .catch(error => {
                            console.warn("Se intento ejecutar el audio cuando no estaba listo")
                        });
                }
            }, 30000)
        }
    }

    public turnOffSound() {
        if (this.soundInterval != null) {
            clearInterval(this.soundInterval);
            this.soundInterval = null;
            console.log("Dentro de turnOffSound")
        }
    }

    public automaticActionDOM() {
        var button = document.createElement('button');
        button.onclick = function () {
            alert('here be dragons'); return false;
        };
        // where do we want to have the button to appear?
        // you can append it to another element just by doing something like
        // document.getElementById('foobutton').appendChild(button);
        document.body.appendChild(button);
        button.click();
    }

    onCancelCombo(idCombo: string) {
        this.comboToCancel = idCombo;
    }

    setComboStatusCancel(idCombo: string) {
        var data = new ComandaOrderCombo();
        data.id = idCombo;
        data.status = StatusCombo.Canceled;
        this.setComboStatus(data);
        this.comboToCancel = "0";
    }

    setComboStatus(dataCombo: ComandaOrderCombo) {
        this._comandaService.setComboStatus(this.apies.toString(), dataCombo.id, dataCombo.status);
        let self = this;
        this.dataAllCombos.forEach(function (e) {
            if (e.id == dataCombo.id) {
                e.status = self.middleStateAnimation(dataCombo.status);
                setTimeout(() => {
                    e.status = dataCombo.status;
                    self.updateComboArrayData(self.dataAllCombos);
                    self.getData();
                }, 250)
            }
        })
        this.updateComboArrayData(this.dataAllCombos);
    }

    middleStateAnimation(state: StatusCombo): StatusCombo {
        switch (state) {
            case StatusCombo.Canceled:
                return StatusCombo.Canceling;

            case StatusCombo.Prepared:
                return StatusCombo.Preparing;

            //case StatusCombo.Delivered:
            //    return StatusCombo.Delivering;

            default:
                return StatusCombo.Canceling;
        }
    }

    checkNewEntries() {
        if (this.isCancelTab && this.dataPreparePending.length > this.dataPreparePendingTemp) {
            this.countEntries = this.dataPreparePending.length - this.dataPreparePendingTemp;
            this.isNewEntry = true;
        }
    }
}