import { ICommandListener } from "src/app/interfaces/icommand-listener.interface";
import { CommandCodeDictionary } from "./command-code-dictionary.service";
import { FocusKeyboardManager } from "./focus-key-board-manager";

export class KeyCommandProcessor {

    private dictionary: CommandCodeDictionary;
    private commandPattern: RegExp;
    private lastAcceptedCommand: string;

    constructor(private _iCommandListener: ICommandListener,
        _dictionary: CommandCodeDictionary,
        _commandPattern: RegExp,
        private focusManager: FocusKeyboardManager) {

        this.commandPattern = _commandPattern;
        this.dictionary = _dictionary;
        this.lastAcceptedCommand = "";
    }

    processKey(keyCode: number, key: string) {
        console.log("keyCode: "+keyCode+" key:  "+key)
        let action: string = this.dictionary.getCommandAction(keyCode, key);
        let command: string = this._iCommandListener.currentCommand.join('');
        let isNoActionCommand: boolean = action != 'scape' && action != 'enter';

        if (isNoActionCommand && !this.isValidCommand(command + action)) {
            this._iCommandListener.proccessNoValidCommand(command);
            this._iCommandListener.currentCommand = [];
            return;
        }

        let pathCommandCode: string = `${this.focusManager.getFocusArrayCurrentIndex()}.${command}`;

        if (action === 'scape') {
            this.actionScapeFunction();
        } else if (action === 'enter') {
            if (this._iCommandListener.elementCode == pathCommandCode && this.getCurrentFocusListenerCode() != pathCommandCode) {
                this._iCommandListener.onEnter();
                this.lastAcceptedCommand = pathCommandCode;
            }
            this._iCommandListener.currentCommand = [];
        } else {
            this._iCommandListener.lastKey = key;
            this._iCommandListener.currentCommand.push(key);
        }
    }

    isValidCommand(command: string): boolean {
        return this.commandPattern.test(command);
    }

    getCurrentFocusListenerCode(): string {
        let focus = this.focusManager.getCurrentFocus();
        return focus ? focus.elementCode : "";
    }

    actionScapeFunction() {
        if (this._iCommandListener.elementCode == this.lastAcceptedCommand) {
            this.lastAcceptedCommand = "";
            this._iCommandListener.onEscape(this.lastAcceptedCommand);
        }
        this._iCommandListener.currentCommand = [];
    }
}