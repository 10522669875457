/// <summary>
/// Represent de datePicker configuration

import { ComandaCombo } from "../comanda/comanda.model";

/// </summary>
export class PreventaDatePicker
{
    days: PreventaDatePickerDay[];
    hourIni: string;
    minuteIni: string;
    partDayIni: string;
    hourEnd: string;
    minuteEnd: string;
    partDayEnd: string;
    active: boolean;
}

export class PreventaDatePickerDay {
    Day: string;
    Active: boolean;
}

/// <summary>
/// Represent the Combo for pre-venta
/// </summary>
export class PreventaComandaCombo extends ComandaCombo
{
   active: boolean;
}

/// <summary>
/// Represent the Combo request for pre-venta
/// </summary>
export class PreventaPromoRequest
{
    Active: boolean;
}