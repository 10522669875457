import { Injectable } from "@angular/core";
import { ClienteMetadata } from "../modules/app.generated.module";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "./abstract.service";
import { Observable, catchError, map } from "rxjs";
import { HelperService } from "./helper.service";

@Injectable({
    providedIn: 'root'
})
export class ClienteService extends AbstractService {

    private web_01: string = "/web01/api";

    constructor(protected override http: HttpClient, private helperService: HelperService) {
        super(http)
    }

    getClienteMetadata(apies: string, id_corto: string): Observable<ClienteMetadata> {
        return super.httpDirectGet(`${this.web_01}/sinthesys/getsinthesystempdata?apies=${apies}&vendedor=${id_corto}`)
            .pipe(map(resp => {
                var clienteMetadata = new ClienteMetadata();
                clienteMetadata.fechaCreacion = resp.data.fechaCreacion;
                clienteMetadata.id = resp.data.id;
                clienteMetadata.kms = resp.data.kms == "0" ? "sin datos" : resp.data.kms;
                clienteMetadata.nombreSocio = resp.data.nombreSocio;

                return clienteMetadata;
            }));
    }

    deleteClienteMetadata(id: number): Observable<boolean> {
        return super.httpDelete(`${this.web_01}/sinthesys/deletetempdata/${id}`).pipe(
            map((response) => {
                console.log(response)
                return true;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }
}