<div *ngIf="isLoadData">
    <main class="main-comanda row">
        <div class="col-12">
            <div class="nav-head-tab">
                <div class="row header-comanda">
                    <div class="col-6 col-md-4">
                        <img src="../../../assets/images/Full.png" alt="full imagen" class="img-fluid">
                    </div>
                    <div class="col-12 col-md-4 text-center mt-4">
                        <h3>Comanda</h3>
                    </div>
                    <div class="col-6 col-md-4">
                        <p class="comanda-clock">{{dateDesktop}}</p>
                    </div>
                </div>
                <ul class="nav nav-tabs">
                    <li class="nav-item col-6 text-center p-0" role="tab">
                        <a class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#tab-comanda-1"
                            href="#" (click)="checkPendingTab()">PENDIENTES Y PREPARADOS <span *ngIf="isNewEntry"
                                class="badge badge-red">{{countEntries}}</span></a>
                    </li>
                    <li class="nav-item col-6 text-center p-0" role="tab">
                        <a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-comanda-2" href="#"
                            (click)="checkCancelTab()">ENTREGADOS Y CANCELADOS</a>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <div class="tab-pane active" role="tabpanel" id="tab-comanda-1">
                    <div *ngIf="dataPreparePending.length > 0 ;else emptyResult1"
                        [@fadeInCommandaAnimation]="dataPreparePending.length">
                        <div class="comanda-combo-component--pendding" *ngFor="let data of dataPreparePending">
                            <comanda-combo [data]="data" (cancelEmitter)="onCancelCombo($event)"
                                (comboToChangeStatusEmitter)="setComboStatus($event)">
                            </comanda-combo>
                        </div>
                    </div>
                    <ng-template #emptyResult1>
                        <div class="emptyResults">
                            <img src="../../../assets/images/sin_pedidos.png" alt="Alternate Text" />
                            <h3>A&uacute;n no hay pedidos</h3>
                        </div>
                    </ng-template>
                </div>
                <div class="tab-pane" role="tabpanel" id="tab-comanda-2">
                    <div *ngIf="dataDeliveryCanceled.length > 0 ;else emptyResult2">
                        <comanda-combo *ngFor="let data of dataDeliveryCanceled" [data]="data"></comanda-combo>
                    </div>
                    <ng-template #emptyResult2>
                        <div class="emptyResults">
                            <img src="../../../assets/images/sin_pedidos.png" alt="Alternate Text" />
                            <h3>A&uacute;n no hay pedidos</h3>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </main>
</div>
<iframe src="../../../assets/sounds/silence.mp3" allow="autoplay" id="audio" style="display:none"></iframe>
<audio id="comanda-audio-alert" src="../../../assets/sounds/beep.wav" type="audio/mp3"></audio>
<div id="comandaModalShow" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="comandaModalShow"
    aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <h4>Cancelar pedido N° {{comboToCancel | slice:-4}}</h4>
                <p>Si cancelas el pedido no podras recuperarlo</p>
                <button class="btn btn-default" data-bs-dismiss="modal">VOLVER</button>
                <button class="btn btn-default" data-bs-dismiss="modal"
                    (click)="setComboStatusCancel(comboToCancel)">CANCELAR PEDIDO</button>
            </div>
        </div>
    </div>
</div>