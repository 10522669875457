import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { HomeComponent } from './components/home/home.component';
import { PanelsComponent } from './components/panels/panels.component';
import { StationSelectorComponent } from './components/station/station-selector.component';
import { SelectedStationComponent } from './components/station/selected-station.component';
import { AdminComponent } from './components/admin/admin.component';
import { PreventaComponent } from './components/preventa/preventa.component';
import { ComandaComponent } from './components/comanda/comanda.component';
import { ComandaComboComponent } from './components/comanda-combo/comanda-combo.component';
import { AbmReadersComponent } from './components/abm-readers/abm-readers.component';
import { AbmBraceletsComponent } from './components/abm-bracelets/abm-bracelets.component';
import { SecurityComponent } from './components/security/security.component';
import { PanelPrecompraSummaryComponent } from './components/panel-precompra-summary/panel-precompra-summary.component';
import { PanelPrecompraContainerComponent } from './components/panel-precompra-container/panel-precompra-container.component';
import { PanelPrecompraComboComponent } from './components/panel-precompra-combo/panel-precompra-combo.component';
import { AbmPanelsComponent } from './components/abm-panels/abm-panels.component';
import { AbmPanelConcentratorComponent } from './components/abm-panels/abm-panel-concentrator.component';
import { TipoAlertaPromoFilter } from './pipes/tipo-alerta-promo-filter.pipe';
import { PanelSurtidorContainerComponent } from './components/panel-surtidor-container/panel-surtidor-container.component';
import { PanelGridItemContainerComponent } from './components/panels-grid-item-container/panels-grid-item-container.component';
import { PanelAlertItemComponent } from './components/panel-alert-item/panel-alert-item.component';
import { PanelMessageItemComponent } from './components/panel-message-item/panel-message-item.component';
import { ArgentinaDecimalPipe } from './pipes/argentina-decimal.pipe';
import { TipoAreaPromoFilter } from './pipes/tipo-area-promo-filter.pipe';
import { SlicePlusPipe } from './pipes/slice-plus.pipe';
import { HourFromEpochPipe } from './pipes/hour-from-epoch.pipe';
import { MillisecondsToTimePipe } from './pipes/milliseconds-to-time.pipe';
import { AbmTextPipe } from './pipes/abm-text.pipe';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RouterModule } from '@angular/router';
import { LiveComponent } from './components/live/live.component';
import { PanelAdminAuthGuardService } from './services/panel-admin-auth-guard.service';
import { CommandCodeDictionary } from './services/logic/command-code-dictionary.service';
import { FocusKeyboardManager } from './services/logic/focus-key-board-manager';
import { CustomHttpInterceptor } from './modules/custom-http-interceptor.module';
import { PanelPromotionItemComponent } from './components/panel-promotion-item/panel-promotion-item.component';
import { AlertaPromoComponent } from './components/alerta-promos/alerta-promo.component';
import { PanelClienteComponent } from './components/panel-cliente/panel-cliente.component';
import { PanelVendedorComponent } from './components/panel-vendedor/panel-vendedor.component';
import { PanelSurtidorComponent } from './components/panel-surtidor/panel-surtidor.component';
import { PanelGridComponent } from './components/alerta-promos/panel-grid.component';
import { AbmPanelsItemComponent } from './components/abm-panels/abm-panels-item.component';
import { StationBossComponent } from './components/station-boss/station-boss.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SalesReportComponent } from './components/sales-report/sales-report.component';
import { CustomMessageComponent } from './components/custom-message/custom-message.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    //Components
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    PanelsComponent,
    StationSelectorComponent,
    SelectedStationComponent,
    AdminComponent,
    PreventaComponent,
    ComandaComponent,
    ComandaComboComponent,
    AbmReadersComponent,
    AbmBraceletsComponent,
    SecurityComponent,
    PanelPrecompraSummaryComponent,
    PanelPrecompraContainerComponent,
    PanelPrecompraComboComponent,
    AbmPanelsComponent,
    AbmPanelConcentratorComponent,
    PanelSurtidorContainerComponent,
    PanelGridItemContainerComponent,
    PanelAlertItemComponent,
    PanelMessageItemComponent,
    //Custom Components
    AlertaPromoComponent,
    PanelPromotionItemComponent,
    PanelClienteComponent,
    PanelVendedorComponent,
    PanelSurtidorComponent,
    PanelPromotionItemComponent,
    LiveComponent,
    PanelGridComponent,
    AbmPanelsItemComponent,
    StationBossComponent,
    SalesReportComponent,
    CustomMessageComponent,
    // Filters
    TipoAlertaPromoFilter,
    TipoAreaPromoFilter,
    // Pipes
    ArgentinaDecimalPipe,
    SlicePlusPipe,
    HourFromEpochPipe,
    MillisecondsToTimePipe,
    AbmTextPipe,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NgSelectModule,
    // Bootstrap ----------------------------
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    RouterModule.forRoot([
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'panels', component: AdminComponent, canActivate: [PanelAdminAuthGuardService] },
      { path: 'comanda/apies/:apies', component: ComandaComponent },
      { path: 'SecureRedirect', component: SecurityComponent },
      { path: 'live/ws/:ws', component: LiveComponent },
      { path: '**', redirectTo: 'home' }
    ]),
  ],
  providers: [
    CommandCodeDictionary,
    FocusKeyboardManager,
    { provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
