import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AdminComponent } from './components/admin/admin.component';
import { PanelAdminAuthGuardService } from './services/panel-admin-auth-guard.service';
import { ComandaComponent } from './components/comanda/comanda.component';
import { SecurityComponent } from './components/security/security.component';
import { LiveComponent } from './components/live/live.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'panels', component: AdminComponent, canActivate: [PanelAdminAuthGuardService] },
  { path: 'comanda/apies/:apies', component: ComandaComponent },
  { path: 'SecureRedirect', component: SecurityComponent },
  { path: 'live/ws/:ws', component: LiveComponent },
  { path: '**', redirectTo: 'home' }
];

export const AppRoutingModule: ModuleWithProviders<any> = RouterModule.forRoot(routes);
