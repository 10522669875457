import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SecurityService } from "./security.service";
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class PanelAdminAuthGuardService implements CanActivate {

    private jwtHelperService: JwtHelperService = new JwtHelperService();

    constructor(private securityService: SecurityService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): 
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.warn("busco el token en canActivate")
        let ypfToken: string = <string>sessionStorage.getItem('ypfToken');

        console.warn("paso a verificar si es posible actualizarlo el token en canActivate")
        try {
            let fuel_station_id = this.securityService.getApies();
            if (fuel_station_id > 0) {
                console.warn("apies mayor que 0")
                if (!this.jwtHelperService.isTokenExpired(ypfToken)) {
                    console.warn("y no esta expirado el token")
                    return true;
                }
                else {
                    console.warn("y esta expirado el token")
                    return this.securityService.updateToken();
                }
            }
            else {
                console.warn("apies no existente")
                this.router.navigate(['/home']);
                return false;
            }
        } catch (e) {
            this.router.navigate(['/home']);
            return false;
        }
    }
}