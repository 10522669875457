
<div *ngIf="surtidor.status != 'any' && surtidor.status != 'Error'" class="vendedor">
    <div class="vendedor-content">
        <div *ngIf="lector.status == 'any' || lector.status == 'Available' || lector.status == 'Calling'">
            <img src="../../../assets/images/icon-sinidentificar.svg" />
        </div>
        <div *ngIf="lector.status == 'Authorized' || lector.status == 'Dispatching' || lector.status == 'End'">
            <img src="../../../assets/images/icon-identificado.svg" />
        </div>
        <div class="vendedor-label">
            <div *ngIf="lector.status == 'any' || lector.status == 'Available' || lector.status == 'Calling'"
                class="vendedor-label-detail">
                Identificate para operar</div>
            <div *ngIf="lector.status == 'Authorized' || lector.status == 'Dispatching' || lector.status == 'End'"
                class="vendedor-label-detail">
                {{lector.embajador}}</div>
        </div>
        <div class="vendedor-tag">
            <div *ngIf="lector.status == 'any' || lector.status == 'Available' || lector.status == 'Calling'"
                class="vendedor-tag-label">
                Surtidor disponible</div>
            <div *ngIf="lector.status == 'Authorized'" class="vendedor-tag-label">
                Ya pod&#233;s operar</div>
            <div *ngIf="lector.status == 'Dispatching'" class="vendedor-tag-label">
                Est&#225;s operando</div>
            <div *ngIf="lector.status == 'End'" class="vendedor-tag-label">
                Operaci&#243;n exitosa</div>
        </div>
    </div>
</div>