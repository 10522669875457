import { Injectable } from "@angular/core";
import { Observable, catchError, forkJoin, map, of, throwError } from "rxjs";
import { EntityResponse, Status } from "src/app/models/entity-response.model";
import { PreventaComandaCombo, PreventaDatePicker, PreventaPromoRequest } from "src/app/models/datepicker/preventa-date-picker.model";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "./abstract.service";

@Injectable({
    providedIn: 'root'
})

export class PreventaService extends AbstractService {

    private web_01: string = "/web01/api";

    constructor(protected override http: HttpClient) {
        super(http)
    }

    // Get Promo for Pre-Venta
    getPromo(apies: string): Observable<EntityResponse> {
        return super.httpDirectGetEntityResponse(`${this.web_01}/prepurchase?apies=${apies}`).pipe(
            map(response => {
                return <EntityResponse>response;
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }

    // Set Promo acive value
    setPromoById(apies: string, idCombo: number[], isActive: boolean): Observable<EntityResponse> {
        if (idCombo.length == 0) {
            var response = new EntityResponse();
            response.status = Status.success;
            response.data = true;
            return of(response);
        }

        var body = new PreventaPromoRequest();
        body.Active = isActive;

        var combo_url: string = "";
        idCombo.forEach((x) => {
            combo_url += `&combo=${x}`;
        });

        return super.httpPatch(`${this.web_01}`, body, `prepurchase?apies=${apies}${combo_url}`).pipe(
            map(response => {
                return <EntityResponse>response.data;
            })
        );
    }

    // Set pre-venta 
    setPreventa(apies: string, combos: PreventaComandaCombo[], datepicker: PreventaDatePicker): Observable<boolean> {
        var set_combos_active = false;
        var set_combos_inactive = false;
        var set_datePicker = false;
        var servicesComponent = this;

        // set combos true
        let combosTrue = combos.filter(x => x.active == true);
        let rt = combosTrue.map(function (x) { return x.id; });

        // set combos false
        let combosFalse = combos.filter(x => x.active == false);
        let rf = combosFalse.map(function (x) { return x.id; });

        var t = forkJoin([servicesComponent.setPromoById(apies, rt, true),
        servicesComponent.setPromoById(apies, rf, false),
        servicesComponent.setConfigDatePicker(apies, datepicker)])
            .subscribe({
                next: (results) => {
                    if (results[0].status == Status.success) {
                        set_combos_active = results[0].data
                    }
                    if (results[1].status == Status.success) {
                        set_combos_inactive = results[1].data
                    }
                    if (results[2].status == Status.success) {
                        set_datePicker = results[2].data
                    }

                    console.log("show result ---- " + (set_combos_active == set_combos_inactive == set_datePicker))
                    return of<boolean>(set_combos_active == set_combos_inactive == set_datePicker);
                },
                error: (err) => console.error(err)
            });
        return of<boolean>(set_combos_active == set_combos_inactive == set_datePicker);
    }


    // Get DatePicker pre-venta configuration
    getConfigDatePicker(apies: string): Observable<EntityResponse> {
        return super.httpDirectGetEntityResponse(`${this.web_01}/prepurchase/schedule?apies=${apies}`).pipe(
            map(response => {
                return <EntityResponse>response;
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }

    // Se DatePicker pre-venta configuration
    setConfigDatePicker(apies: string, dataConfig: PreventaDatePicker): Observable<EntityResponse> {
        return super.httpPatch(`${this.web_01}/prepurchase`, dataConfig, `schedule?apies=${apies}`).pipe(
            map(response => {
                return <EntityResponse>response.data;
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }
}