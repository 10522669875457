import { Component, Input } from "@angular/core";
import { Lector, Surtidor } from "src/app/modules/app.generated.module";

@Component({
    selector: 'panel-vendedor',
    templateUrl: './panel-vendedor.component.html',
    styleUrls: ['./panel-vendedor.component.scss', '../sass/grid-layout.scss', '../sass/ie_grid_fix.scss']
})
export class PanelVendedorComponent {
    @Input() lector: Lector;
    @Input() surtidor: Surtidor;
}