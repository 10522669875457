import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ClienteMetadata, Surtidor } from "src/app/modules/app.generated.module";
import { ComandaOrderCombo } from "src/app/models/comanda/comanda.model";

@Component({
    selector: 'panel-surtidor',
    templateUrl: './panel-surtidor.component.html',
    styleUrls: ['./panel-surtidor.component.scss']
})
export class PanelSurtidorComponent implements OnInit, OnChanges {

    @Input() surtidor: Surtidor;
    @Input() position: string;
    @Input() isFocused: boolean;
    @Input() comandaOrder: ComandaOrderCombo;
    @Input() cliente: ClienteMetadata;
    @Input() mqttError: boolean;

    public totalAmount: number;

    isEnableTotalSummary: boolean;

    constructor() {
        this.isEnableTotalSummary = false;
        console.log("-----panelSurtidor-----" + this.comandaOrder + "-------------");
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateTotalSummary();
    }

    ngOnInit(): void {
        this.updateTotalSummary();
    }

    updateTotalSummary() {
        if (this.comandaOrder.combos.length == 0) {
            this.totalAmount = 0;
            this.isEnableTotalSummary = false;
        }
        else {
            this.totalAmount = this.comandaOrder.combos.map(x => x.price * x.quantity).reduce((sum, current) => sum += current)
            this.isEnableTotalSummary = true;
        }
    }

    isIE() {
        var ua = window.navigator.userAgent;
        // IE 11
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            if (this.surtidor.placeholder == 1)
                return '1.8em';
            else
                return '5em';
        }
        return 0;
    }
}