import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ReportStationBossRequest, Seller } from "src/app/models/abm-bracelet/seller.model";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "./abstract.service";
import { saveAs } from 'file-saver';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class StationBossService extends AbstractService {

    private seller_dotation: string = "/seller-dotation";
    private ms_dispatches: string = "/msdispatches/api"

    constructor(protected override http: HttpClient) {
        super(http)
    }

    getAllSellers(apiesText: string): Observable<Seller[]> {
        return super.httpWafSellerDotationDirectGet(apiesText).pipe(
            map((resp: any) => {
                return <Seller[]>resp.sellers;
            })
        );
    }

    downloadReport(data: ReportStationBossRequest) {

        let url = `${environment.url_waf}${this.ms_dispatches}/dispatch/exportbossreport?Apies=${data.Apies}&Dni=${(data.Dni != "" ? data.Dni : "")}&DateFromEpoch=${data.FechaDesdeEpoch}&DateToEpoch=${data.FechaHastaEpoch}&formatoDescarga=${data.Format}`;

        return this.http.get(url, { responseType: 'blob' })
            .subscribe((blob) => {
                saveAs(blob, 'ReporteJefe.xlsx');
            });
    }
}