<section class="panel p-4 border panel-default panel-body gray-background">
    <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
    <form #panelEditForm="ngForm" (ngSubmit)="panelEditForm.valid && onSubmitPanel(panelEditForm.value)">
        <div class="row">
            <div [ngClass]="{'col-sm-9':true, 'col-xs-12':isEditPanel,'col-xs-8':!isEditPanel}">
                <h4 class="weight-700">Panel {{index}}</h4>
                <p *ngIf="!isEditPanel;else panelEditDescription">{{entity.description}}</p>
                <ng-template #panelEditDescription>
                    <input type="text" name="description" #description="ngModel" [(ngModel)]="entity.description"
                        maxlength="50" required class="form-control">
                    <div *ngIf="description.errors && (description.dirty || description.touched || panelEditForm.submitted)"
                        class="alert alert-danger">
                        <p *ngIf="description.errors?.['required']">
                            Campo requerido.
                        </p>
                    </div>
                </ng-template>
            </div>
            <div class="col-xs-4 col-sm-3 text-end" *ngIf="!this.panelService.getEditing()">
                <span class="btn btn-sm" (click)="setPanelDeleted(entity.id)" data-bs-toggle="modal"
                    data-bs-target="#abmPanelsModalShow" data-backdrop="static">
                    <i class="bi bi-trash"></i>
                </span>
                <span class="btn btn-sm" (click)="editPanel(entity)">
                    <i class="bi bi-pencil-fill"></i>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-xs-12">
                <h5 class="weight-700 pt-3">Dirrección MAC</h5>
                <b *ngIf="!isEditPanel;else panelEditMAC">{{entity.mac_address}}</b>
                <ng-template #panelEditMAC>
                    <input type="text" name="mac_address" #mac_address="ngModel" [(ngModel)]="entity.mac_address"
                        required class="form-control" placeholder="AA-BB-CC-00-11-22"
                        pattern="^([0-9A-Fa-f]{2}[-]){5}([0-9A-Fa-f]{2})$" maxlength="17">
                    <div *ngIf="mac_address.errors && (mac_address.dirty || mac_address.touched || panelEditForm.submitted)"
                        class="alert alert-danger">
                        <p *ngIf="mac_address.errors?.['required']">
                            Campo requerido.
                        </p>
                        <p *ngIf="mac_address.errors?.['pattern']">
                            Formato de MAC incorrecto.
                        </p>
                    </div>
                </ng-template>
            </div>
            <div class="col-md-4 col-xs-6">
                <h5 class="weight-700 pt-3">Surtidor A(Izq)</h5>
                <b *ngIf="!isEditPanel;else panelEditSurtidorA">{{entity.left_fuel_dispenser_name}}</b>
                <ng-template #panelEditSurtidorA>
                    <select name="left_fuel_dispenser" #left_fuel_dispenser="ngModel" class="form-control"
                        [(ngModel)]="entity.left_fuel_dispenser">
                        <option>0</option>
                        <option *ngFor="let pump of pumpListTemp" [ngValue]="pump.pumpId"
                            [selected]="pump.pumpId == entity.left_fuel_dispenser">{{pump.pumpName}}</option>
                    </select>
                </ng-template>
            </div>
            <div class="col-md-4 col-xs-6">
                <h5 class="weight-700  pt-3">Surtidor B(Der)</h5>
                <b *ngIf="!isEditPanel;else panelEditSurtidorB">{{entity.right_fuel_dispenser_name}}</b>
                <ng-template #panelEditSurtidorB>
                    <select name="right_fuel_dispenser" #right_fuel_dispenser="ngModel" class="form-control"
                        [(ngModel)]="entity.right_fuel_dispenser">
                        <option>0</option>
                        <option *ngFor="let pump of pumpListTemp" [ngValue]="pump.pumpId"
                            [selected]="pump.pumpId == entity.right_fuel_dispenser">{{pump.pumpName}}</option>
                    </select>
                </ng-template>
            </div>
        </div>
        <div class="row" *ngIf="isEditPanel">
            <div class="col-md-12">
                <button type="submit" class="btn btn-info panels-footer-buttom">Guardar</button>
                <span class="btn btn-default panels-footer-buttom cancel" (click)="cancelUpdatePanel()">Cancelar</span>
            </div>
        </div>
    </form>
</section>