import { Component, Input, OnInit } from "@angular/core";
import { forkJoin, map } from "rxjs";
import { AbmPanelsService } from "src/app/services/panel.service";
import { PumpService } from "src/app/services/pump.service";
import { SecurityService } from "src/app/services/security.service";
import { Concentrator } from "src/app/models/abm-panels/concentrator.model";
import { Panel } from "src/app/models/abm-panels/panel.model";
import { Pump } from "src/app/models/abm-panels/pump.model";
import { AlertMessage } from "src/app/models/entity-response.model";

@Component({
    selector: 'abm-panels',
    templateUrl: './abm-panels.component.html',
    styleUrls: ['./abm-panels.component.scss'],
})

export class AbmPanelsComponent implements OnInit {

    @Input() concentrator: Concentrator;
    isLoadData: boolean;
    private apies: number;
    public alert: AlertMessage;
    public panel = new Panel();
    public panels: Panel[] = [];
    public addingPanel: boolean = false;
    public pumpList: Pump[];
    public pumpListTemp: Pump[];

    constructor(private securityService: SecurityService,
        public panelService: AbmPanelsService,
        private pumpService: PumpService) {
        this.alert = new AlertMessage();
        this.apies = this.securityService.getApies();
        this.isLoadData = false;
        this.pumpList = this.pumpListTemp = [];
    }

    ngOnInit(): void {
        this.getData();
    }

    // Get initial data 
    getData() {
        forkJoin([
            this.panelService.getPanels(this.concentrator.id, 1, 20),
            this.pumpService.getPump(this.apies.toString()).pipe(map(e => e))
        ]).subscribe({
            next: (data) => {
                this.panels = <Panel[]>data[0].data;
                this.pumpList = <Pump[]>data[1].data
                this.pumpListTemp = this.takePumps(this.pumpList, this.panels);
                this.isLoadData = true;
            },
            error: (err) => console.error(err)
        });
    }

    private clonePumps(from: Pump[]): Pump[] {
        return JSON.parse(JSON.stringify(from))
    }

    private getPanels() {
        this.isLoadData = false;
        this.panelService.getPanels(this.concentrator.id, 1, 20)
            .subscribe({
                next: (result) => {
                    this.panels = <Panel[]>result.data;
                    this.pumpListTemp = this.takePumps(this.pumpList, this.panels);
                    this.isLoadData = true;
                },
                error: (err) => console.error(err)
            })
    }

    private takePumps(pumps: Pump[], panels: Panel[]): Pump[] {
        var show: Pump[] = [];
        pumps.forEach((pump) => {
            let d = panels.filter((x) => { return x.left_fuel_dispenser == pump.pumpId || x.right_fuel_dispenser == pump.pumpId })
            if (d.length == 0) show.push(pump);
        });
        return show;
    }

    public addPanel() {
        this.panel = new Panel();
        this.addingPanel = true;
        this.panelService.setEditing(true);
    }

    public deletePanel() {
        var t = this.panelService.deletePanel()
            .subscribe((result) => {
                console.log(result);
                this.getPanels();
            })
    }

    public cancelAddPanel() {
        this.addingPanel = false;
        this.panelService.setEditing(false);
    }

    public onSubmitPanel(data: any) {

        this.panel = data;

        if (this.panel.left_fuel_dispenser + this.panel.right_fuel_dispenser == 0) {
            return this.alert.set("", "danger", "Debe seleccionar un surtidor como mínimo", true, 6000);
        }

        if (this.panel.left_fuel_dispenser == this.panel.right_fuel_dispenser) {
            return this.alert.set("", "danger", "No debe seleccionar dos surtidores iguales para este panel.", true, 6000);
        }

        this.panels = [];


        console.warn(this.panel.left_fuel_dispenser)

        let left_pumps = this.pumpList.filter(x => x.pumpId == this.panel.left_fuel_dispenser);
        if (left_pumps.length > 0)
            this.panel.left_fuel_dispenser_name = left_pumps[0].pumpName
        else
            this.panel.left_fuel_dispenser_name = "";

        let right_pumps = this.pumpList.filter(x => x.pumpId == this.panel.right_fuel_dispenser)
        if (right_pumps.length > 0)
            this.panel.right_fuel_dispenser_name = right_pumps[0].pumpName
        else
            this.panel.right_fuel_dispenser_name = "";

        this.panel.concentrator_id = this.concentrator.id;

        console.log(this.panel)
        var t = this.panelService.setPanel(this.panel, this.panel.id)
            .subscribe({
                next: (result) => {
                    let self = this;
                    console.log(result);
                    this.panel = <Panel>result.data;
                    this.panelService._panelFocus = new Panel();
                    this.getPanels();
                    this.panelService.setEditing(false);
                    this.addingPanel = false;
                },
                error: (err) => {
                    console.error(err);
                    this.panelService.setEditing(false);
                    this.addingPanel = false;
                    this.getPanels();
                }
            })
    }
}