<div *ngIf="!isLoadData; else elseBlock">
    <img src="../../../assets/images/loader-gris.svg" /> Cargando datos...
</div>
<ng-template #elseBlock>
    <section>
        <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <h6><b>Vincular lector y surtidor</b></h6>
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="col-md-5">
                                <label class="gray-labels">ID Surtidor</label>
                            </td>
                            <td class="col-md-5">
                                <label class="gray-labels">ID Lector</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-md-5 border-top-none">
                                <select class="form-control" (change)="pumpSelected($event)">
                                    <option value="0"></option>
                                    <option *ngFor="let pump of pumpListTemp" value="{{pump.pumpId}}">{{pump.pumpName}}
                                    </option>
                                </select>
                            </td>
                            <td class="col-md-5 border-top-none">
                                <div class="form-group">
                                    <input type="text" class="form-control text-uppercase" placeholder="0QWERTY"
                                        [(ngModel)]="elementToCreate.readerName">
                                </div>
                            </td>
                            <td class="text-end border-top-none">
                                <button class="btn btn-info" (click)="addLink()"><i
                                        class="bi glyphicon-abm-link"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h6><b>ID Vinculados</b></h6>
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th class="gray-labels col-sm-3 col-md-4">ID Surtidor</th>
                            <th class="gray-labels col-sm-6 col-md-5">ID Lector</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
                <div style="max-height: 350px;overflow-y: scroll;">
                    <table class="table table-condensed">
                        <tbody>
                            <tr *ngFor="let pumpRead of pumpReaderList">
                                <td class="col-sm-3 col-md-4">{{pumpRead.pumpName}}</td>
                                <td class="col-sm-6 col-md-5">{{pumpRead.readerName | uppercase}}</td>
                                <td class="text-end">
                                    <button class="btn btn-info me-1" (click)="showUnlinkModal(pumpRead.pumpId)"
                                        data-bs-toggle="modal" data-bs-target="#abmModalShow" data-backdrop="static"><i
                                            class="bi glyphicon-abm-unlink"></i></button>
                                    <button class="btn btn-info" (click)="showEditModal(pumpRead.pumpId)"
                                        data-bs-toggle="modal" data-bs-target="#abmModalShow" data-backdrop="static"><i
                                            class="bi glyphicon-abm-edit"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </section>
    <div id="abmModalShow" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="abmModalShow"
        aria-hidden="true" data-keyboard="false" data-backdrop="static" style="background:rgba(0, 0, 0, 0.88);">
        <div class="modal-dialog" role="document" style="top:30%">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div *ngIf="!isShowEditModal; else elseBlock">
                        <h6 class="text-start">Desvincular surtidor y lector</h6>
                        <div class="row">
                            <div class="col-sm-12">
                                <table class="table text-start">
                                    <thead>
                                        <tr>
                                            <th class="gray-labels">ID Surtidor</th>
                                            <th class="gray-labels">ID Lector</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{elementToUpdate.pumpName}}</td>
                                            <td>{{elementToUpdate.readerName | uppercase}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-outline-secondary btn-sm btn-descartar btn-sm"
                                    data-bs-dismiss="modal">CANCELAR</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-info btn-sm" data-bs-dismiss="modal"
                                    (click)="unlinkPumpReader()">CONFIRMAR</button>
                            </div>
                        </div>
                    </div>
                    <ng-template #elseBlock>
                        <h6 class="text-start">Editar ID's vinculados</h6>
                        <div class="row">
                            <div class="col-sm-6 text-start">
                                <div class="form-group">
                                    <b>ID Surtidor</b>
                                    <input type="text" class="form-control text-capitalize"
                                        value="{{elementToUpdate.pumpName}}" readonly="readonly">
                                </div>
                            </div>
                            <div class="col-sm-6 text-start">
                                <div class="form-group">
                                    <b>ID Lector</b>
                                    <input type="text" class="form-control text-uppercase"
                                        [(ngModel)]="elementToUpdate.readerName">
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-outline-secondary btn-sm btn-descartar btn-sm"
                                    data-bs-dismiss="modal" (click)="cancelUpdate()">CANCELAR</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-info btn-sm" data-bs-dismiss="modal"
                                    (click)="updateRegister()">CONFIRMAR</button>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>