import { Component, Input } from "@angular/core";
import { AlertaPromo } from "src/app/modules/app.generated.module";

@Component({
    selector: 'panel-message-item',
    templateUrl: './panel-message-item.component.html',
    styleUrls: ['./panel-message-item.component.scss']
})
export class PanelMessageItemComponent {

    @Input() mensajes: AlertaPromo[];
}