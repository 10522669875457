import { Component } from "@angular/core";

@Component({
    selector: 'live',
    templateUrl: './live.component.html',
    styleUrls: ['../sass/flayout.scss',
        './live.component.scss',
        '../sass/grid-layout.scss',
        '../sass/ie_grid_fix.scss']
})

export class LiveComponent {
    public isLoaded: boolean = false;
    onLoaded(loaded: boolean): void {
        console.log("live onLoaded", loaded);
        this.isLoaded = loaded;
    }
}