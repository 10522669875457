<div>
    <img [src]="promotion.imageUrl" [width]="promotionLenght <= 1 ? '168' : '128'"
        [height]="promotionLenght <= 1 ? '168' : '128'" />
</div>
<div [ngClass]="promotionLenght <= 1 ? '' : 'promo-content'">
    <div [ngClass]="promotionLenght <= 1 ? 'content-vertical' : 'content'">
        <div class="chip">
            <div [ngClass]="promotionLenght <= 1 ? 'entidad-vertical' : 'entidad'">
                {{promotion.entidad}}</div>
        </div>
        <div [ngClass]="promotionLenght <= 1 ? 'titulo-container-vertical' : 'titulo-container'">
            <div [ngClass]="promotionLenght <= 1 ? 'titulo-promo-vertical' : 'titulo-promo'">
                {{promotion.montoDescuento}} {{promotion.accion}}</div>
            <img src="../../../assets/images/divider.svg" />
        </div>
        <div [ngClass]="promotionLenght <= 1 ? 'promo-descripcion-vertical' : 'promo-descripcion'">
            {{promotion.message}}
        </div>
    </div>
</div>