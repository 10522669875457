import { Injectable } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { EntityResponse } from "src/app/models/entity-response.model";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "./abstract.service";
import { HelperService } from "./helper.service";

@Injectable({
    providedIn: 'root'
})
export class PumpService extends AbstractService {

    public comboToCancel: string;
    private ms_dispatches: string = "/msdispatches/api"

    constructor(protected override http: HttpClient, private helperService: HelperService) {
        super(http)
        this.comboToCancel = "0";
    }

    public getPump(apies: string): Observable<EntityResponse> {
        return super.httpWafDirectGetEntityResponse(`${this.ms_dispatches}/gaspump/pump/${apies}`).pipe(
            map(resp => {
                return <EntityResponse>resp
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }
}