import { Pipe, PipeTransform } from '@angular/core';
/*
 * Add charater to left of value
 * Example
 * input(value:1, charater:F, spacers:3
 * ouput FF1
*/
@Pipe({ name: 'hourFromEpoch' })
export class HourFromEpochPipe implements PipeTransform {
    transform(value: number): string {
        if (value == null) return value;
        var date = new Date(parseInt(value.toString())); // The 0 there is the key, which sets the date to the epoch
        var hours = date.getHours() + 3;
        var minutes = date.getMinutes();
        var seconds = date.getSeconds()
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var m = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + m + ':' + seconds;
        return strTime;
    }
}