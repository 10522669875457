<div *ngIf="!isLoadData; else elseBlock">
    <img src="../../../assets/images/loader-gris.svg" /> Cargando datos...
</div>
<ng-template #elseBlock>
    <section>
        <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <h6><b>Vincular pulsera y vendedor</b></h6>
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="col-md-5 text-start">
                                <label class="gray-labels">Número de Pulsera</label>
                            </td>
                            <td class="col-md-5 text-start">
                                <label class="gray-labels">
                                    Vendedor
                                    <img *ngIf="isSellerLoading" src="../../../assets/images/loader-gris.svg"
                                        width="20" />
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-md-5 border-top-none">
                                <div class="form-group">
                                    <input type="text" class="form-control text-uppercase" placeholder="0QWERTY"
                                        [(ngModel)]="elementToCreate.bracelet_code">
                                </div>
                            </td>
                            <td class="col-md-5 border-top-none">
                                <div class="form-group text-start">
                                    <input [readonly]="isSellerEmpty" [(ngModel)]="sellerSelectedInput"
                                        [typeahead]="sellers" typeaheadOptionField="name"
                                        (typeaheadOnSelect)="onSelectSeller($event)" class="form-control">
                                </div>
                            </td>
                            <td class="text-end border-top-none">
                                <button class="btn btn-info" (click)="addLink()"><i
                                        class="glyphicon glyphicon-abm-link"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h6><b>ID Vinculados</b></h6>
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th class="gray-labels col-sm-3 col-md-4">Número de Pulsera</th>
                            <th class="gray-labels col-sm-6 col-md-5">Vendedor</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
                <div style="max-height: 350px;overflow-y: scroll;" (scroll)="scrollHandler($event)">
                    <table class="table table-condensed">
                        <tbody>
                            <tr *ngFor="let braceletRead of braceletReaderList">
                                <td class="col-sm-3 col-md-4">{{braceletRead.bracelet_code | uppercase}}</td>
                                <td class="col-sm-6 col-md-5">{{braceletRead.seller_dni +"-"+ braceletRead.seller_name}}
                                </td>
                                <td class="text-end">
                                    <button class="btn btn-info me-1" (click)="showUnlinkModal(braceletRead.id)"
                                        data-bs-toggle="modal" data-bs-target="#abmBraceletModalShow"
                                        data-backdrop="static"><i class="glyphicon glyphicon-abm-unlink"></i></button>
                                    <button class="btn btn-info" (click)="showEditModal(braceletRead.id)"
                                        data-bs-toggle="modal" data-bs-target="#abmBraceletModalShow"
                                        data-backdrop="static"><i class="glyphicon glyphicon-abm-edit"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="isLoadDataBracelets">
                        <img src="../../../assets/images/loader-gris.svg" width="50" /> Cargando datos...
                    </div>
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
    </section>
    <div id="abmBraceletModalShow" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="abmBraceletModalShow"
        aria-hidden="true" data-keyboard="false" data-backdrop="static" style="background:rgba(0, 0, 0, 0.88);">
        <div class="modal-dialog" role="document" style="top:30%">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div *ngIf="!isShowEditModal; else elseBlock">
                        <h6 class="text-start">Desvincular pulsera y vendedor</h6>
                        <div class="row">
                            <div class="col-xs-12">
                                <table class="table text-start">
                                    <thead>
                                        <tr>
                                            <th class="gray-labels">Pulsera</th>
                                            <th class="gray-labels">Vendedor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{elementToUpdate.bracelet_code | uppercase}}</td>
                                            <td>{{elementToUpdate.seller_name | uppercase}}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-default" data-bs-dismiss="modal">CANCELAR</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-info" data-bs-dismiss="modal"
                                    (click)="unlinkPumpReader()">CONFIRMAR</button>
                            </div>
                        </div>

                    </div>
                    <ng-template #elseBlock>
                        <h6 class="text-start">Editar vinculación</h6>
                        <div class="row">
                            <div class="col-sm-6 text-start">
                                <div class="form-group">
                                    <b>Pulsera</b>
                                    <input type="text" class="form-control text-capitalize"
                                        value="{{elementToUpdate.bracelet_code}}" readonly="readonly">
                                </div>
                            </div>
                            <div class="col-sm-6 text-start">
                                <div class="form-group">
                                    <b>Vendedor</b>
                                    <input [readonly]="isSellerEmpty" [(ngModel)]="sellerSelectedInput"
                                        [typeahead]="sellers" typeaheadOptionField="name"
                                        (typeaheadOnSelect)="onSelectSellerUpdate($event)" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-default" data-bs-dismiss="modal"
                                    (click)="cancelUpdate()">CANCELAR</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-info" data-bs-dismiss="modal"
                                    (click)="updateRegister()">CONFIRMAR</button>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>