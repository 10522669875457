<div class="col-xs-12 commanda-combo" [@comboOut]="initialState" (@comboOut.done)="resetSlider()">
    <div [ngClass]="{'comanda-combo-container':true}">
        <div class="row">
            <div class="col-xs-12">
                <div class="animation-section"
                    [ngClass]="{'background-pending':data.status == statusCombo.Pending,'background-prepared':data.status ==statusCombo.Prepared, 'background-delivery': data.status ==statusCombo.Delivered, 'background-cancelled': data.status ==statusCombo.Canceled || data.status ==statusCombo.Canceling}"
                    [@comboStateSliderAnimation]="comboStateSliderStaus">
                    <div class="animation-icon__container" [@centerToOutAnimation]="centerToOutAnimationStatus">
                    </div>
                    <svg class="animation-icon" [@fadeInImageAnimation]="fadeInImageAnimationStatus"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <defs>
                            <style>
                                .cls-1 {
                                    fill: #fff;
                                }
                            </style>
                        </defs>
                        <g id="preparado" transform="translate(-738 -142)">
                            <path id="Path_12" data-name="Path 12" class="cls-1"
                                d="M35.913,35.51a1.413,1.413,0,0,0-2,2l6.515,6.507a1.381,1.381,0,0,0,1,.413H41.5a1.576,1.576,0,0,0,1.034-.516L58.837,24.389a1.418,1.418,0,0,0-2.172-1.825L41.325,40.916,35.913,35.51Z"
                                transform="translate(713.825 125.315)" />
                            <path id="Path_13" data-name="Path 13" class="cls-1"
                                d="M37.01,9.481a20,20,0,1,0,6.343,6.335A1.4,1.4,0,0,0,40.974,17.3a17.04,17.04,0,0,1-2.413,21.278,17.175,17.175,0,1,1-3.034-26.718A1.4,1.4,0,1,0,37.01,9.481Z"
                                transform="translate(731.55 135.574)" />
                        </g>
                    </svg>
                </div>
                <div class="info-container col-xs-10"
                    [ngClass]="{'info-container':true,
                     'col-xs-10':data.status == statusCombo.Pending || data.status == statusCombo.Prepared,
                     'col-xs-12':data.status == statusCombo.Delivered || data.status == statusCombo.Canceled,
                     'background-pending-soft':data.status == statusCombo.Pending,
                     'background-prepared-soft':data.status == statusCombo.Prepared || data.status == statusCombo.Delivered}">
                    <div class="order-section col-xs-10 no-padding">
                        <div [ngClass]="{'order-section__input':true,
                             'background-pending-order':data.status == statusCombo.Pending,
                             'background-prepared-order':data.status == statusCombo.Prepared || data.status == statusCombo.Delivered,
                             'background-cancelled-order':data.status == statusCombo.Canceled}">
                            <img src="{{data.platformIcon}}" alt="surtidor" width="40" />
                            <span>{{data.id | slice:-4}}</span>
                        </div>
                        <div class="order-section__client">
                            <div class="order-section__client__div">
                                <label>Cliente</label>
                                <p>{{(data.clientName.length>19)? (data.clientName | titlecase | slice:0:19)+'...':
                                    data.clientName}}</p>
                            </div>
                            <div class="order-section__client__div"
                                *ngIf="data.status == statusCombo.Delivered || data.status == statusCombo.Canceled">
                                <label *ngIf="data.status == statusCombo.Delivered">hora de entrega</label>
                                <label *ngIf="data.status == statusCombo.Canceled">hora de cancelación</label>
                                <p>{{data.updatedStatusDate | hourFromEpoch }}</p>
                            </div>
                        </div>
                        <div class="order-section__combo-data col-xs-12">
                            <table
                                [ngClass]="{'table':true, 'pull-left':true, 'text-start':true ,'color-cancelled':data.status == statusCombo.Canceled}">
                                <thead>
                                    <tr>
                                        <th>cod</th>
                                        <th>cant</th>
                                        <th>producto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let combo of data.combos"
                                        [ngClass]="{'color-cancelled':data.status == statusCombo.Canceled || data.status == statusCombo.Delivered}">
                                        <td *ngIf="data.platform == 'Station'">-</td>
                                        <td *ngIf="data.platform == 'App'">({{combo.id}})</td>
                                        <td class="text-center">{{combo.quantity}}</td>
                                        <td>{{combo.description}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="status-section col-xs-2">
                        <div>
                            <label>estado pedido</label>
                            <p *ngIf="data.status == statusCombo.Pending"
                                [ngClass]="{'color-pending':data.status == statusCombo.Pending}">por preparar</p>
                            <p *ngIf="data.status == statusCombo.Prepared"
                                [ngClass]="{'color-prepared':data.status == statusCombo.Prepared}">por entregar</p>
                            <p *ngIf="data.status == statusCombo.Delivered"
                                [ngClass]="{'color-delivery':data.status == statusCombo.Delivered}">entregado</p>
                            <p *ngIf="data.status == statusCombo.Canceled"
                                [ngClass]="{'color-cancelled':data.status == statusCombo.Canceled}">cancelado</p>
                        </div>
                        <div *ngIf="data.status == statusCombo.Pending" class="status-section__time-elapse">
                            <img src="../../../assets/images/icon-cronometro.svg" alt="time" />
                            <!-- <span>{{timeElapse}}</span> -->
                        </div>
                    </div>
                </div>
                <div *ngIf="data.status == statusCombo.Pending || data.status == statusCombo.Prepared"
                    class="action-section col-xs-2">
                    <button *ngIf="data.status == statusCombo.Pending"
                        [ngClass]="{'btn':true,'btn-status':true,'btn-pending':data.status == statusCombo.Pending}"
                        (click)="changeStatusSlide(data.id,statusCombo.Preparing)">
                        lo preparé
                    </button>
                    <button *ngIf="data.status == statusCombo.Prepared"
                        [ngClass]="{'btn':true,'btn-status':true,'btn-prepared-delivery':data.status == statusCombo.Prepared}"
                        (click)="changeStatusSlide(data.id,statusCombo.Delivered)">
                        lo entregué
                    </button>
                    <button
                        *ngIf="(data.status == statusCombo.Pending || data.status ==statusCombo.Prepared) && showCancelButton"
                        class="btn btn-default" (click)="toCancelCombo(data.id)" data-bs-toggle="modal"
                        data-bs-target="#comandaModalShow" data-backdrop="static">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>