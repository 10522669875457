export class PumpInsert {
    pumpId: number;
    apies: number;
    pumpName: number;
    readerName: string;
}

export class Pump {
    pumpId: number;
    pumpName: string;
}

export class PumpReader {
    pumpId: number;
    pumpName: string;
    readerName: string;
}