import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ICommandListener } from "src/app/interfaces/icommand-listener.interface";
import { ComandaService } from "src/app/services/comanda.service";
import { CommandCodeDictionary } from "src/app/services/logic/command-code-dictionary.service";
import { FocusKeyboardManager } from "src/app/services/logic/focus-key-board-manager";
import { KeyCommandProcessor } from "src/app/services/logic/key-command-processor.service";
import { BehaviorCombo, ComandaCombo, ImagenType, WidthComponent } from "src/app/models/comanda/comanda.model";

@Component({
    selector: 'panel-precompra-combo',
    templateUrl: './panel-precompra-combo.component.html',
    styleUrls: ['./panel-precompra-combo.component.scss'],
    animations: [
        trigger('showComboAnimation', [
            state('showed', style({
                opacity: '1'
            })),
            state('hidden', style({
                opacity: '0'
            })),
            transition('showed => hidden', animate('100ms ease-out')),
            transition('hidden => showed', animate('600ms ease-in'))
        ])
    ]
})

export class PanelPrecompraComboComponent implements OnInit, OnChanges, ICommandListener {

    // ICommandListener props
    isListening: boolean;
    elementCode: string;
    focusIndex: number;
    lastKey: string;
    currentCommand: string[];

    patternCode: RegExp = /^[0-9]{1,3}$/;
    isFocused: boolean = false;

    private keyCommandProcessor: KeyCommandProcessor;
    @Output() addEvent: EventEmitter<number>;
    @Output() substrackEvent: EventEmitter<number>;
    @Output() cancelEvent: EventEmitter<number>;
    @Output() addProduct: EventEmitter<number>;

    @Input() position: number;
    @Input() count: number;
    @Input() combo: ComandaCombo;

    private _comandaService: ComandaService
    public behavior: BehaviorCombo;
    public behaviorConfig: BehaviorCombo[];
    public imagenType = ImagenType;
    public totalAmount: number;

    public simulateQuantity: number = 0;
    public simulatedPrice: number = 0;
    private comboIdentifier: any = null;

    //Animations Variables
    aShowed: boolean;

    constructor(comandaService: ComandaService,
        private dictionary: CommandCodeDictionary,
        private focusManager: FocusKeyboardManager) {
        this.addEvent = new EventEmitter<number>();
        this.substrackEvent = new EventEmitter<number>();
        this.cancelEvent = new EventEmitter<number>();
        this.addProduct = new EventEmitter<number>();
        this._comandaService = comandaService;
        this.behaviorConfig =
            [
                {
                    // Para un solo combo
                    imagenType: ImagenType.vertical,
                    widthComponent: WidthComponent.all
                },
                {
                    // Para dos combos
                    imagenType: ImagenType.horizontal,
                    widthComponent: WidthComponent.all
                },
                {
                    // Para cantidades pares mayor que 2 (4)
                    imagenType: ImagenType.vertical,
                    widthComponent: WidthComponent.middle
                },
            ]
        this.aShowed = false;
    }

    ngOnInit(): void {
        let self = this;
        this.Configure();
        //initialice commandProcesor props
        this.keyCommandProcessor =
            new KeyCommandProcessor(this, this.dictionary,
                this.patternCode,
                this.focusManager);
        this.lastKey = '';
        this.currentCommand = [];
        this.focusManager.addListener(this);
        this.focusIndex = this.focusManager.getFocusArrayCurrentIndex();

        this.comboIdentifier = this.combo.name.split(" ")[1];

        setTimeout(function () {
            self.aShowed = true;
        }, 400);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setSimulatedPrice();
    }

    get stateShowCombo() {
        return this.aShowed ? 'showed' : 'hidden';
    }

    setSimulatedPrice() {
        let scalar = this.simulateQuantity == 0 ? 1 : this.simulateQuantity;
        this.simulatedPrice = this.combo.price * scalar;
    }

    private Configure() {

        if (this.position === undefined || this.count === undefined) this.behavior = new BehaviorCombo();

        if (this.count == 1) {
            console.log("1 combo");
            this.behavior = this.behaviorConfig[0]
        }

        if ((this.count == 2) || (this.count > 2 && this.count % 2 == 1 && this.position == this.count - 1)) {
            console.log("2 combo");
            this.behavior = this.behaviorConfig[1]
        }

        if ((this.count > 2 && this.count % 2 == 0) || (this.count > 2 && this.position != this.count - 1)) {
            console.log("4 combo");
            this.behavior = this.behaviorConfig[2]
        }
    }

    public add() {
        if (this.simulateQuantity < 5) {
            this.simulateQuantity++;
            this.setSimulatedPrice();
            this.addEvent.emit(this.combo.id);
        }
    }

    public substract() {
        if (this.simulateQuantity > 0) {
            this.simulateQuantity--;
            this.setSimulatedPrice();
            this.substrackEvent.emit(this.combo.id);
        }
    }

    public cancel() {
        this.cancelEvent.emit(this.combo.id);
        this.simulateQuantity = 0;
        this.totalAmount = 0;
        this.combo.quantity = 0;
    }

    onEnter(): void {
        this.focusManager.onlyOneListener(this);
        this.focusManager.addFocus(this);
        this.isFocused = true;
    }

    onEscape(lastCommand: string): void {
        this.focusManager.removeLastFocus();
        this.isFocused = false;
    }

    proccessNoValidCommand(noValidCommand: string): void {
        console.log(`comando '${noValidCommand}' no válido  ${this.elementCode}`)
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        this.elementCode = `${this.focusIndex}.${this.comboIdentifier}`;
        let wasFocused = this.isFocused;
        let lastCurrentCommand = this.currentCommand;
        let wasListening = this.isListening;

        if (this.isListening) {
            this.keyCommandProcessor.processKey(event.keyCode, event.key);
        }

        if (wasFocused && wasListening) {

            switch (this.dictionary.getCommandAction(event.keyCode, event.key)) {
                case 'plus':
                    this.add();
                    break;
                case 'minus':
                    this.substract();
                    break;
                case 'enter':
                    if (this.currentCommand.join('').length == 0 && this.simulateQuantity) {
                        this.keyCommandProcessor.actionScapeFunction();
                    }
                    if (lastCurrentCommand.join('').length == 0 && this.simulateQuantity == 0) {
                        this.cancel();
                        this.keyCommandProcessor.actionScapeFunction();
                    }
                    break;
                case 'scape':
                    console.log('test -->', lastCurrentCommand.join('').length, this.simulateQuantity);
                    if (this.currentCommand.join('').length == 0 && this.simulateQuantity) {
                        console.log('test 222 -->', this.currentCommand.join('').length, this.simulateQuantity);
                        this.cancel();
                        this.keyCommandProcessor.actionScapeFunction();
                    }
                    break;
            }
        }
    }
}