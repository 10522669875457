<div class="precompra-combo precompra-combo-count--{{count}} precompra-combo-index--{{position}}"
    [@showComboAnimation]="stateShowCombo">
    <div class="content-combo  content-combo-count--{{count}}
             content-combo-index--{{position}}" [ngClass]="{'content-combo--focus':isFocused}">
        <div class="content-image">
            <img class="content-combo__image" src="{{combo.image}}" alt="{{combo.name}}" />
        </div>
        <div class="content-text">
            <div class="text__header">
                <h4 class="text__header-title">{{combo.name}}</h4>
            </div>
            <div class="text__body">
                <div class="text__title">
                    <h3 class="text__title-text">{{combo.description}}</h3>
                </div>
                <div class="content-combo__price">
                    <h2 class="price">${{this.simulatedPrice}}</h2>
                </div>
            </div>
            <div class="text__footer">
                <div class="action-combo" [ngClass]="{'action-combo--active':simulateQuantity > 0 }">
                    <button class="btn-combo btn-minus">-</button>
                    <span class="separator">{{combo.quantity}}</span>
                    <button class="btn-combo btn-plus">+</button>
                </div>
            </div>
        </div>
    </div>
</div>