<div *ngIf="filtroArea==0" class="panel-background" [attr.data-isFulllView]='isPanelView'>
    <div class="panel-container">
        <panel-message-item *ngIf="cantidadMensajes" [mensajes]="mensajes" [ngClass]="['top-divs']">
        </panel-message-item>
        <div class="main-content">
            <panel-surtidor-container [ngClass]="['side-div']" (aExpanded)="toggleAnimationLeft($event)"
                [@expandedAnimationLeft]="stateExpandedLeft" [position]="leftClass" [surtidor]="surtidor_left"
                [sellerPanelReader]="lector_left" [isLive]="isLive" [isDebug]="isDebug"
                [clienteError]="clienteErrorLeft" [isDisabled_column]="isDisabled_left_column" [mqttError]="mqttError"
                [lector]="lector_left" [dataCliente]="dataClienteLeft">
            </panel-surtidor-container>
            <panel-grid-item-container [ngClass]="['center-div']"  [@expandedAnimationCenter]="stateExpandedCenter"
                [offersQuantity]="cantidadOfertas" [alertsQuantity]="cantidadAlertas"
                [promotionAlertsLastQuantity]="lastAlertasPromos" [promotionAlerts]="promos">
            </panel-grid-item-container>
            <panel-surtidor-container [ngClass]="['side-div']" (aExpanded)="toggleAnimationRight($event)"
                [@expandedAnimationRight]="stateExpandedRight" [position]="rightClass" [surtidor]="surtidor_right"
                [sellerPanelReader]="lector_right" [isLive]="isLive" [isDebug]="isDebug"
                [clienteError]="clienteErrorRight" [isDisabled_column]="isDisabled_right_column" [mqttError]="mqttError"
                [lector]="lector_right" [dataCliente]="dataClienteRight">
            </panel-surtidor-container>
        </div>        
        <panel-alert-item *ngIf="cantidadAlertas > 0" [alertas]="alertas" [ngClass]="['bottom-divs']">
        </panel-alert-item>
    </div>
</div>
<div class="no-soported-navigator" *ngIf="filtroArea==0 && detectIE<12">
    <div class="no-soported-navigator__icon">
        <span class="glyphicon glyphicon-ban-circle"></span>
    </div>
    <div class="no-soported-navigator__message">
        <span>Lo sentimos, no se puede visuzalizar el panel en este navegador</span>
    </div>
</div>
<div class="mensaje" *ngIf="filtroArea>0">
    <div>
        <span class="glyphicon glyphicon-ban-circle"></span>
    </div>
    <div>
        <p>
            El panel <span *ngIf="filtroArea==1">Tienda</span>
            <span *ngIf="filtroArea==2">Boxes</span> a&#250;n
            <strong>no est&#225; disponible</strong>
        </p>
    </div>
</div>