import { Injectable } from '@angular/core';
import { ICommandListener } from 'src/app/interfaces/icommand-listener.interface';

@Injectable()
export class FocusKeyboardManager {
    private commandListeners: ICommandListener[];
    private listenersHistory: ICommandListener[][];
    private focusHistory: ICommandListener[];

    constructor() {
        this.commandListeners = [];
        this.listenersHistory = [];
        this.focusHistory = [];
    }

    getListeners() {
        return this.commandListeners;
    }

    addListener(commandListener: ICommandListener): number {
        commandListener.isListening = true;
        if (this.commandListeners.indexOf(commandListener) == -1) {
            return this.commandListeners.push(commandListener);
        }
        else {
            return 0;
        }
    }

    onlyOneListener(currentListener: ICommandListener) {
        this.listenersHistory.push(this.commandListeners);
        this.commandListeners = this.commandListeners.filter(function (item: ICommandListener) {
            let isTheSame = (item == currentListener)
            if (!isTheSame) {
                item.isListening = false;
            }
            return isTheSame;
        });
    }

    getCurrentFocus() {
        return this.focusHistory[this.focusHistory.length - 1];
    }

    getFocusArrayCurrentIndex() {
        return this.focusHistory.length;
    }

    addFocus(focusToAdd: ICommandListener): string {
        let focusIndex: number = 0;
        if (this.focusHistory.indexOf(focusToAdd) == -1) {
            focusIndex = this.focusHistory.push(focusToAdd);

            if (this.focusHistory.length - 1 >= 0) {
                let lastFocus: ICommandListener = this.focusHistory[this.focusHistory.length - 1];
                lastFocus.isListening = false;
            }
            focusToAdd.isListening = true;
        }

        return `${focusIndex}`;
    }

    setKeyBoardListenersFromHistory(index: number) {
        if (this.listenersHistory[index]) {
            let lastItem: any = this.listenersHistory.pop();
            this.commandListeners = lastItem;
            this.commandListeners.forEach(function (item: ICommandListener) {
                item.isListening = true;
            });
        }
    }

    removeLastFocus() {
        let lastIndex = this.focusHistory.length - 1;

        if (lastIndex >= 0) {
            let lastItem = this.focusHistory.pop();
            if (lastItem) {
                lastItem.isListening = false;
            }
            this.setKeyBoardListenersFromHistory(lastIndex);
        }
    }

    toggleListening(isListening: boolean) {
        return isListening = !isListening;
    }
}