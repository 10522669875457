import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, Subscription, forkJoin } from "rxjs";
import { Lector, Surtidor } from "src/app/modules/app.generated.module";
import { ComandaService } from "src/app/services/comanda.service";
import { KeyCommandProcessor } from "src/app/services/logic/key-command-processor.service";
import { SecurityService } from "src/app/services/security.service";
import { ComandaCombo, ComandaOrderCombo } from "src/app/models/comanda/comanda.model";
import { Status } from "src/app/models/entity-response.model";

@Component({
    selector: 'panel-precompra-container',
    templateUrl: './panel-precompra-container.component.html',
    styleUrls: ['./panel-precompra-container.component.scss'],
})

export class PanelPrecompraContainerComponent implements OnInit, OnDestroy {
    @Output() setComandaOrderEvent: EventEmitter<ComandaOrderCombo>;
    @Input() isWantCancelCompleteOrder: Subject<any>;
    @Input() completeOrder: Subject<KeyCommandProcessor>;
    @Input() lector: Lector;
    @Input() surtidor: Surtidor;

    isWantCancelCompleteOrderSubsCription: Subscription;
    completeOrderSubscription: Subscription;

    private _comandaService: ComandaService;
    public orderCombo: ComandaOrderCombo;
    private comandaCombosOrdered: ComandaCombo[];
    public isLoadData: boolean;
    private apies: string;

    constructor(private router: Router,
        comandaService: ComandaService,
        private securityService: SecurityService) {
        this._comandaService = comandaService;
        this.isLoadData = false;
        this.setComandaOrderEvent = new EventEmitter<ComandaOrderCombo>();
    }

    ngOnInit(): void {
        this.orderCombo = new ComandaOrderCombo();
        let _apies = this.securityService.getApies();
        if (_apies <= 0) this.router.navigate(['/home'])
        this.apies = _apies.toString();

        this.isLoadData = true;

        if (this.isWantCancelCompleteOrder != null) {
            let self = this;
            self.isWantCancelCompleteOrderSubsCription = this.isWantCancelCompleteOrder.subscribe(event => {
                self.cancelAllOrders();
            });
        }

        if (this.completeOrder != null) {
            let self = this;
            self.completeOrderSubscription = this.completeOrder.subscribe(event => {
                self.setOrder(event);
            });
        }
    }

    ngOnDestroy(): void {
        this.destroySubscriptions();
    }

    destroySubscriptions() {
        if (this.isWantCancelCompleteOrderSubsCription != null && this.completeOrderSubscription != null) {
            this.isWantCancelCompleteOrderSubsCription.unsubscribe();
            this.completeOrderSubscription.unsubscribe();
        }
    }

    // Get initial data 
    getData() {
        forkJoin([this._comandaService.getPanelCombos(this.apies.toString())])
            .subscribe({
                next: (result) => {
                    if (result[0].status == Status.success) {
                        this._comandaService.currentDataCombos.subscribe(data => this.orderCombo.combos = data);
                        this.isLoadData = true;
                    }
                },
                error: (err) => console.error(err)
            });
    }

    // get selected combos for buy
    getSelectedCombos(): ComandaCombo[] {
        return this.orderCombo.combos.filter(x => x.quantity > 0);
    }

    // add elementToOrder
    addElement(data: any) {
        var self = this;
        this.orderCombo.combos.forEach(function (e) {
            if (e.id == data) {
                e.quantity += 1;
            }
        })
        this.updateComandaOrder();
    }

    // substrack element
    substrackElement(data: any) {
        var self = this;
        this.orderCombo.combos.forEach(function (e) {
            if (e.id == data && e.quantity > 0) {
                e.quantity -= 1;
            }
        })
        this.updateComandaOrder();
    }

    // cancel seleted data
    cancelElement(data: any) {
        var self = this;
        this.orderCombo.combos.forEach(function (e) {
            if (e.id == data && e.quantity > 0) {
                e.quantity = 0;
            }
        });
        this.updateComandaOrder();
    }

    cancelAllOrders() {
        console.warn("entra a cancelar todas las ordenes");
        let self = this;
        this.orderCombo.combos.forEach(function (item) {
            self.cancelElement(item.id);
        });
        this.updateComandaOrder();
    }

    // Set order data
    setOrder(keyCommandProcessor: KeyCommandProcessor) {
        console.log("entra completar orden");
        let dataOrder: ComandaOrderCombo = new ComandaOrderCombo();
        dataOrder.sellerName = this.lector.embajador;
        dataOrder.shortId = this.lector.idCorto == null ? 0 : parseInt(this.lector.idCorto);
        dataOrder.pump = parseInt(this.surtidor.name);
        dataOrder.combos = this.getSelectedCombos();
        console.warn(this.lector.idCorto)
        console.warn(this.lector.embajador)
        console.warn(this.surtidor.name)

        this._comandaService.insertComboOrder(this.apies.toString(), dataOrder)
            .subscribe({
                next: (result) => {
                    if (result.status == Status.success) {
                        console.log("success completar orden");
                        this.comandaCombosOrdered = JSON.parse(JSON.stringify(this.orderCombo.combos));
                        var t = new ComandaOrderCombo();
                        t.id = (<string>result.data).substr(-4);
                        t.combos = this.orderCombo.combos.filter(x => x.quantity > 0);
                        this.setComandaOrderEvent.emit(t);
                        keyCommandProcessor.actionScapeFunction();
                        this.destroySubscriptions();
                    }
                },
                error: (err) => console.error(err)
            });
    }

    addProduct() {
        this.comandaCombosOrdered = JSON.parse(JSON.stringify(this.orderCombo.combos));
        this.updateComandaOrder();
    }

    private updateComandaOrder() {
        var t = new ComandaOrderCombo();
        t.combos = this.orderCombo.combos.filter(x => x.quantity > 0);

        this.setComandaOrderEvent.emit(t);
    }
}