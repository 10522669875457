import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { AlertaPromo } from "src/app/modules/app.generated.module";

@Pipe({
    name: 'tipoAlertaPromoFilter'
})

@Injectable()
export class TipoAlertaPromoFilter implements PipeTransform {
    transform(alertas: AlertaPromo[], filter: Number): any {
        if (!alertas || !alertas.length) {
            return [];
        }
        if (!filter || filter == 0) {
            return alertas;
        }
        return alertas.filter(alerta => alerta.tipoAlerta == filter);
    }
}