export class Panel {
    id: number;
    concentrator_id: number;
    description: string;
    mac_address: string;
    left_fuel_dispenser: number;
    left_fuel_dispenser_name: string;
    right_fuel_dispenser: number;
    right_fuel_dispenser_name: string;

    constructor() {
        this.id = this.concentrator_id = 0;
        this.description = this.mac_address = this.left_fuel_dispenser_name = this.right_fuel_dispenser_name= "";
        this.left_fuel_dispenser = this.right_fuel_dispenser = 0;
        
    }
}

export class PanelStatus {
    public static Reset: string = "Reset";
    public static UpdatePromotions: string = "UpdatePromotions";
}