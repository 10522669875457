<div *ngIf="!isLoadData; else elseBlock">
    <img src="../../../assets/images/loader-gris.svg" /> Cargando datos...
</div>
<ng-template #elseBlock>
    <section>
        <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <form #concentratorForm="ngForm" (ngSubmit)="concentratorForm.valid && onSubmit()">
                    <h6><b>Configuración de Paneles</b></h6>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="col-md-6 text-start">
                                    <label class="gray-labels">IP Concentrador JPV</label>
                                </td>
                                <td class="col-md-6 text-right" colspan="2">
                                    <button class="no-btn float-end" type="submit"
                                        *ngIf="concentrator.id > 0 && !isEditConcentrator && !_panelService.getEditing()"
                                        (click)="editConcentrator()"><i class="bi bi-pencil-fill"></i></button>
                                    <button *ngIf="concentrator.id > 0 && isEditConcentrator"
                                        (click)="cancelEditConcentrator()" type="button" class="no-btn close float-end"
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-md-6 border-top-none">
                                    <div class="form-group">
                                        <input type="text" name="ip" #ip="ngModel" [(ngModel)]="concentrator.ip_address"
                                            required class="form-control text-uppercase" placeholder="0.0.0.0"
                                            [disabled]="concentrator.id > 0 && !isEditConcentrator" maxlength="45"
                                            pattern="((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))">
                                        <div *ngIf="ip.errors && (ip.dirty || ip.touched || concentratorForm.submitted)"
                                            class="alert alert-danger">
                                            <p *ngIf="ip.errors?.['required']">
                                                Este campo es requerido.
                                            </p>
                                            <p *ngIf="ip.errors?.['pattern']">
                                                Formato de IP incorrecto
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="col-md-4 border-top-none">
                                    <div class="form-group">
                                        <input type="text" name="port" #port="ngModel" [(ngModel)]="concentrator.port"
                                            value="10000" required class="form-control text-uppercase"
                                            placeholder="10000" [disabled]="concentrator.id > 0 && !isEditConcentrator"
                                            maxlength="5" pattern="^\d+$">

                                        <div *ngIf="port.errors && (port.dirty || port.touched || concentratorForm.submitted)"
                                            class="alert alert-danger">
                                            <p *ngIf="port.errors?.['required']">
                                                Campo requerido.
                                            </p>
                                            <p *ngIf="port.errors?.['pattern']">
                                                Formato del puerto incorrecto
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-right border-top-none">
                                    <button *ngIf="concentrator.id == 0 || isEditConcentrator" type="submit"
                                        class="btn btn-info">Guardar</button>
                                </td>
                            </tr>
                            <tr *ngIf="isEditConcentrator">
                                <td colspan="2">
                                    <alert type="warning">
                                        <h5><i class="bi bi-info-circle"></i> Asegurese de ingresar una
                                            IP correcta.</h5>
                                        <p>De lo contrario, los paneles dejarán de mostrar información a los vendedores
                                            de playa</p>
                                    </alert>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <div class="col-md-3"></div>
        </div>
        <abm-panels *ngIf="concentrator.id > 0" [concentrator]="concentrator"></abm-panels>
    </section>
</ng-template>