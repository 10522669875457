import { Injectable } from "@angular/core";
import { Cliente, Gateway, GatewayClientOptions, Lector, Placeholder, Station, Surtidor, TipoArea, Workstation } from "../modules/app.generated.module";
import { Observable, Subject, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "./abstract.service";

export enum Vista {
    Render = 0,
    Edit = 1,
    Preview = 2
}

export class ViewChange {
    public vista: Vista;
    public name: string;
    public viewNames: string[] = ["Administración de EESS", "Edición de Herramientas", "Previsualizar Panel"];
    public callback: () => void;
    public isHidden: boolean = true;
    public hasChanged: boolean = false;
    public excedeLimite: boolean = false;

    setView(vista: Vista, callback: () => void) {
        switch (vista) {
            case Vista.Edit:
                this.isHidden = false;
                break;
            case Vista.Preview:
                this.isHidden = false;
                break;
            case Vista.Render:
                this.isHidden = true;
                break;
            default:
        }
        this.vista = vista;
        this.callback = callback;
        return this.name = this.viewNames[vista];
    }
}

export class StateChange {
    public hasChanged: boolean = false;
    public excedeLimite: boolean = false;
    public filtroArea: TipoArea = 0;
}

@Injectable({
    providedIn: 'root'
})

export class StationService extends AbstractService {

    private subjectStation = new Subject<Station>();
    private subjectCurrentView = new Subject<ViewChange>();
    private subjectButton = new Subject<string>();
    private subjectStateView = new Subject<StateChange>();
    private station: Station;
    private ms_eess: string = "/mseess/api";
    private ms_panels: string = "/mspanels/api";

    constructor(protected override http: HttpClient) {
        super(http);
    }

    setStation(station: Station) {
        this.station = station;
        this.subjectStation.next(station);
    }

    clearStation() {
        this.subjectStation.complete();
    }

    getStation(): Observable<Station> {
        return this.subjectStation.asObservable();
    }

    getCurrentView(): Observable<ViewChange> {
        return this.subjectCurrentView.asObservable();
    }

    setCurentView(view: ViewChange) {
        this.subjectCurrentView.next(view);
    }

    getCurrentState(): Observable<StateChange> {
        return this.subjectStateView.asObservable();
    }

    setCurentState(view: StateChange) {
        this.subjectStateView.next(view);
    }

    getButtonView(): Observable<string> {
        return this.subjectButton.asObservable();
    }

    setButtonView(view: string) {
        this.subjectButton.next(view);
    }

    getSelectedStation(): Station {
        return this.station;
    }

    getWorkstation(ws: string): Observable<Workstation> {
        return super.httpWafDirectGet(`${this.ms_panels}/private/panels/${ws}/mac_address`).pipe(
            map(resp => {
                var workStation = new Workstation();
                workStation.apies = resp.data.concentrator.fuel_station_id;
                workStation.area = TipoArea.Playa;
                workStation.id = resp.data.mac_address;
                workStation.name = resp.data.description;
                workStation.layout = [0, 1];

                const lectores: Lector[] = [];
                lectores.push(<Lector>{
                    code: resp.data.left_fuel_dispenser_name,
                    placeholder: Placeholder.Left,
                    isEnable: true,
                    status: "any",
                    embajador: "Embajador",
                    action: "Ya estas habilitado para operar"
                });
                lectores.push(<Lector>{
                    code: resp.data.right_fuel_dispenser_name,
                    placeholder: Placeholder.Right,
                    isEnable: true,
                    status: "any",
                    embajador: "Embajador",
                    action: "Ya estas habilitado para operar"
                });
                workStation.lectores = lectores;

                const surtidores: Surtidor[] = [];
                surtidores.push(<Surtidor>{
                    code: resp.data.left_fuel_dispenser_name,
                    name: resp.data.left_fuel_dispenser_name,
                    isEnable: true,
                    placeholder: Placeholder.Left,
                    status: "any"
                });
                surtidores.push(<Surtidor>{
                    code: resp.data.right_fuel_dispenser_name,
                    name: resp.data.right_fuel_dispenser_name,
                    isEnable: true,
                    placeholder: Placeholder.Right,
                    status: "any"
                });
                workStation.surtidores = surtidores;

                const clientes: Cliente[] = [];
                clientes.push(<Cliente>{
                    code: resp.data.left_fuel_dispenser_name,
                    placeholder: Placeholder.Left,
                    isEnable: true,
                    status: "any",
                    name: "Cliente",
                    content: "0000"
                });
                clientes.push(<Cliente>{
                    code: resp.data.right_fuel_dispenser_name,
                    placeholder: Placeholder.Right,
                    isEnable: true,
                    status: "any",
                    name: "Cliente",
                    content: "0000"
                });
                workStation.clientes = clientes;
                workStation.gateway = <Gateway> {
                    brokerUrl: "",
                    options: <GatewayClientOptions>{
                        host: resp.data.concentrator.ip_address,
                        hostname: resp.data.concentrator.ip_address,
                        port: resp.data.concentrator.port,
                        protocol: "wss"
                    }
                }

                return workStation;
            })
        );
    }

    getStationByApies(apies: number): Observable<Station> {
        return super.httpWafDirectGet(`${this.ms_eess}/fuel_stations/${apies}`).pipe(
            map(resp => {
                var station = new Station();
                station.apies = resp.data.fuel_station_id;
                station.apiesText = resp.data.name_station;
                station.name = String(resp.data.fuel_station_id).padStart(5, '0');
                return station;
            })
        );
    }
}