import { Injectable } from "@angular/core";

@Injectable()
export class CommandCodeDictionary {

    commands: any = {
        27: 'scape',
        46: 'scape',
        110: 'scape',
        13: 'enter',
        43: 'plus',
        187: 'plus',
        107: 'plus',
        45: 'minus',
        189: 'minus',
        109: 'minus'
    };

    constructor() { };

    getCommandAction(keyCode: number, key: string) {
        let localCode = this.commands[keyCode];
        if (!localCode) {
            return key;
        } else {
            return localCode;
        }
    }
}