import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, map } from "rxjs";
import { ReaderService } from "src/app/services/reader.service";
import { SecurityService } from "src/app/services/security.service";
import { Pump, PumpInsert, PumpReader } from "src/app/models/abm-panels/pump.model";
import { AlertMessage, Status } from "src/app/models/entity-response.model";

@Component({
    selector: 'abm',
    templateUrl: './abm-readers.component.html',
    styleUrls: ['./abm-readers.component.scss'],
})

export class AbmReadersComponent implements OnInit {

    private apies: number;
    private _readerService: ReaderService;
    private pumpList: Pump[];
    public pumpListTemp: Pump[];
    public pumpReaderList: PumpReader[];
    private pumpReaderListTemp: PumpReader[];
    public elementToCreate = new PumpInsert();
    public elementToUpdate = new PumpReader();
    public isLoadData: boolean;
    public isShowEditModal: boolean;
    public alert: AlertMessage;

    constructor(private router: Router, readerService: ReaderService, private securityService: SecurityService) {
        this.apies = 0;
        this.pumpList = [];
        this.pumpReaderList = [];
        this.isLoadData = false;
        this._readerService = readerService;
        this.isShowEditModal = true;
        this.elementToCreate = { pumpId: 1, apies: 1, pumpName: 1, readerName: "" };
        this.elementToUpdate = { pumpId: 1, pumpName: "", readerName: "" };
        this.alert = new AlertMessage();
    }

    ngOnInit(): void {
        var self = this;
        let _apies = this.securityService.getApies();
        if (_apies <= 0) this.router.navigate(['/home'])
        this.apies = _apies;
        this.getData();
    }

    // Get initial data 
    getData() {
        forkJoin([
            this._readerService.getPump(this.apies.toString()).pipe(map(e => e)),
            this._readerService.getPumpReaders(this.apies.toString()).pipe(map(e => e))
        ]).subscribe({
            next: (data) => {
                this.pumpList = <Pump[]>data[0].data;
                this.pumpReaderList = <PumpReader[]>data[1].data;
                this.pumpReaderListTemp = JSON.parse(JSON.stringify(this.pumpReaderList));
                this.filterComboPump();
                this.isLoadData = true;
            },
            error: (err) => console.error(err)
        });
    }

    // Reset properties after add link 
    resetProperties() {
        console.log("reset data;")
        this.elementToCreate.pumpId = 0;
        this.elementToCreate.apies = 0;
        this.elementToCreate.pumpName = 0;
        this.elementToCreate.readerName = "";
        this.elementToUpdate.pumpId = 0;
        this.elementToUpdate.pumpName = "";
        this.elementToUpdate.readerName = "";
        this.pumpListTemp = JSON.parse(JSON.stringify(this.pumpList));;
    }

    // Get this list for update data 
    getPumpReaders() {
        this._readerService.getPumpReaders(this.apies.toString())
            .subscribe({
                next: (result) => {
                    if (result.data) {
                        this.pumpReaderList = <PumpReader[]>result.data;
                        this.pumpReaderListTemp = JSON.parse(JSON.stringify(this.pumpReaderList));
                        this.filterComboPump();
                    }
                },
                error: (err) => console.error(err)
            })
    }

    // Get the options not created
    filterComboPump() {
        console.log("filter data")
        this.pumpListTemp = [];
        var dataChange: Pump[] = this.pumpReaderList.map((x) => { return { pumpId: x.pumpId, pumpName: x.pumpName } })
        var b = this;
        this.pumpList.forEach(function (e) {
            var t = dataChange.filter(x => x.pumpId == e.pumpId);
            if (t.length == 0)
                b.pumpListTemp.push(e)
        })
    }

    /**
     * On select element for pumpSelected and create link
     * @param data
     */
    pumpSelected(data: any) {
        this.elementToCreate.pumpId = +data.target.value;
        this.elementToCreate.pumpName = +data.target.options[data.target.selectedIndex].text;
    }
    // Add or update an element 
    addOrUpdateRegister() {
        var p = { pumpId: this.elementToCreate.pumpId, apies: this.apies, pumpName: this.elementToCreate.pumpName, readerName: this.elementToCreate.readerName }
        this.resetProperties();
        this._readerService.addOrUpdateRegister(p.pumpId, p)
            .subscribe({
                next: (result) => {
                    if (result.data) {
                        this.getPumpReaders();
                        this.alert.set("", "success", "Surtidor y lector se <b>vincularon exitosamente</b>", true, 5000);
                    }
                    else {
                        this.alert.set("", "danger", result.errors.value, true, 5000);
                    }
                },
                error: (err) => {
                    this.alert.set("", "danger", err.errors.value, true, 5000);
                }
            })
    }

    // Add link
    addLink() {

        if (this.elementToCreate.pumpId == 0 || this.elementToCreate.readerName.toUpperCase() == "") {
            return this.alert.set("", "danger", "Ingrese los campos de surtidor y lector de forma correcta.", true, 5000);
        }

        if ((this.pumpReaderList.filter(x => x.readerName.toUpperCase() == this.elementToCreate.readerName.toUpperCase())).length > 0) {
            return this.alert.set("", "danger", "No se puede asignar el mismo lector a más de un surtidor.", true, 5000);
        }

        this.addOrUpdateRegister();
    }

    // Update pump register
    updateRegister() {
        let exp = new RegExp("^[a-zA-Z0-9]+$");
        if (!exp.test(this.elementToUpdate.readerName)) {
            this.cancelUpdate();
            return this.alert.set("", "danger", "La información insertada no es correcta", true, 5000);
        }

        if ((this.pumpReaderList.filter(x => x.readerName.toUpperCase() == this.elementToUpdate.readerName.toUpperCase())).length > 1) {
            this.cancelUpdate();
            return this.alert.set("", "danger", "No se puede asignar el mismo lector a más de un surtidor.", true, 5000);
        }
        this.elementToCreate.pumpId = this.elementToUpdate.pumpId;
        this.elementToCreate.readerName = this.elementToUpdate.readerName;
        this.elementToCreate.pumpName = +this.elementToUpdate.pumpName;

        this.addOrUpdateRegister();
    }

    // Cancel modal action in update
    cancelUpdate() {
        this.pumpReaderList = JSON.parse(JSON.stringify(this.pumpReaderListTemp));
        this.resetProperties();
        this.filterComboPump()
    }

    // Delete the link for pump 
    unlinkPumpReader() {
        this._readerService.unlinkPumpReader(this.elementToUpdate.pumpId)
            .subscribe((result) => {
                if (result.data) {
                    this.resetProperties();
                    this.getPumpReaders();
                    return this.alert.set("", "success", "Surtidor y lector se <b>desvincularon exitosamente", true, 5000);
                }
                else {
                    this.alert.set("", "danger", result.errors.value, true, 5000);
                }
            });
    }

    // Show the modal for action edit
    showEditModal(id: number) {
        this.elementToUpdate = (this.pumpReaderList.filter(x => x.pumpId == id))[0];
        if (this.elementToUpdate != null) {
            this.isShowEditModal = true;
        }
    }

    // Show modal for action unlink
    showUnlinkModal(id: number) {
        this.elementToUpdate = (this.pumpReaderList.filter(x => x.pumpId == id))[0];
        if (this.elementToUpdate != null) {
            this.isShowEditModal = false;
        }
    }
}