import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SecurityService } from "src/app/services/security.service";

@Component({
    selector: 'security',
    templateUrl: './security.component.html',
    styleUrls: ['./security.component.scss'],
})

export class SecurityComponent implements OnInit {

    private _securtyService: SecurityService;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private securityService: SecurityService) {
        this._securtyService = securityService;
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {

            let macAddress: string = params["macAddress"];
            let apiKey: string = params["apiKey"];
            let token: string = params["token"];

            if (macAddress !== undefined && apiKey !== undefined) {
                console.log("redireccion al live")
                this._securtyService.setApiKey(apiKey);
                this.router.navigateByUrl("/live/ws/" + macAddress)
            } else if (token != null) {
                this._securtyService.setToken(token);
                console.log("redireccion al panel")
                this.router.navigateByUrl("/panels")
            } else {
                console.log("redireccion al home")
                this.router.navigateByUrl("/panels");
            }
        });
    }
}