<div *ngIf="!isLoadData; else elseBlock">
    <img src="../../../assets/images/loader-gris.svg" /> Cargando datos...
</div>
<ng-template #elseBlock>
    <section>
        <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
        <div class="row">
            <div class="col-md-12 py-3">
                <h6>
                    <b>Descarga reporte de Ventas para la estación: </b> <label
                        class="d-inline">{{stationsFinder[0].name}}</label>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-2 col-md-4 col-lg-4">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group text-start">
                            <h5>
                                <b>Filtros Guardados</b>
                            </h5>
                        </div>
                    </div>
                    <hr>
                </div>
                <div id="filterChipsContainer" class="chip-group" style="display:inline-flex;">
                    <div *ngFor="let dispatchFilter of dispatchFilters"
                        [ngClass]="{'chip': true, 'short':dispatchFilter.description.length > 33}" tabindex="-1">
                        <span class="pe-auto"
                            (click)="selectDispatchFilter(dispatchFilter.id)">{{dispatchFilter.description}}</span>
                        <button type="button" title="Remove chip" aria-label="Remove chip" data-bs-toggle="modal"
                            data-bs-target="#deleteFilterModalShow" (click)="setFilterIdToRemove(dispatchFilter.id)"><i
                                class="bi bi-x"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-6 col-lg-6 text-center">
                <div class="form-group text-start pb-3">
                    <h5 class="d-inline">
                        <b>Filtros </b>
                    </h5>
                    <label class="d-inline">(La selecci&oacute;n de los siguientes campos definir&aacute;n el reporte a
                        descargar)</label>
                </div>
                <div class="form-group text-start">
                    <label class="fw-bold">Vendedor: <img *ngIf="isloadingSeller"
                            src="../../../assets/images/loader-gris.svg" width="20" /></label>
                    <input [readonly]="isSellerEmpty" [(ngModel)]="sellerSelectedInput" [typeahead]="sellers"
                        typeaheadOptionField="name" (typeaheadOnSelect)="onSelectSeller($event)" class="form-control">
                    <small>Podes descargar el de todos los vendedores dejando el campo vacio</small>
                </div>
                <div class="form-group text-start pt-5 pb-1">
                    <h5>
                        <b>Per&iacute;odo Actual (A analizar)</b>
                    </h5>
                </div>
                <div class="row">
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <label class="fw-bold">Fecha y Hora de inicio</label>
                        <div class="form-group">
                            <div class="input-group" id="dateTimeBeginDate">
                                <input type="text" class="form-control" bsDatepicker #dpBegin="bsDatepicker"
                                    [bsValue]="beginDate"
                                    value="{{ beginDate | date:'dd-MM-yyyy' }} {{ beginDate | date:'shortTime' }}"
                                    [outsideClick]="true" [maxDate]="endDate"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY hh:mm a', containerClass: 'theme-blue'  }"
                                    (onHidden)="showTimeBegin()" (bsValueChange)="setDateBegin($event)">
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="dpBegin.show()"
                                        [attr.aria-expanded]="dpBegin.isOpen"><i
                                            class="bi bi-calendar-event-fill"></i></button>
                                </div>
                            </div>
                            <div [ngClass]="{'timerContainer':true, 'timeEndDate':true}"
                                [ngStyle]="{'display': isHideTimerBegin?'none':'block'}">
                                <timepicker [(ngModel)]="beginDate"></timepicker>
                                <button class="btn btn-info btn-sm" (click)="hideTimeBegin(beginDate)"><i
                                        class="bi bi-check-lg"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <label class="fw-bold">Fecha y Hora de finalizaci&oacute;n</label>
                        <div class="form-group">
                            <div class="input-group" id="dateTimeBeginDate">
                                <input type="text" class="form-control" bsDatepicker #dpEnd="bsDatepicker"
                                    [bsValue]="endDate"
                                    value="{{ endDate | date:'dd-MM-yyyy' }} {{ endDate | date:'shortTime' }}"
                                    [outsideClick]="true" [minDate]="beginDate" [maxDate]="today"
                                    (onHidden)="showTimeEnd()" (bsValueChange)="setDateEnd($event)"
                                    [bsConfig]="{ containerClass:'theme-blue', dateInputFormat: 'DD-MM-YYYY hh:mm a'  }">
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="dpEnd.show()"
                                        [attr.aria-expanded]="dpEnd.isOpen"><i
                                            class="bi bi-calendar-event-fill"></i></button>
                                </div>
                            </div>
                            <div [ngClass]="{'timerContainer':true, 'timeEndDate':true}"
                                [ngStyle]="{'display': isHideTimerEnd?'none':'block'}">
                                <timepicker [(ngModel)]="endDate"></timepicker>
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="hideTimeEnd(endDate)"><i
                                            class="bi bi-check-lg"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group text-start">
                        <small>* La selecci&oacute;n de este rango de fechas es obligatoria. El rango de fechas
                            seleccionada no deber&aacute; superar los 90 d&iacute;as.</small>
                    </div>
                </div>
                <div class="row pb-1 pt-5">
                    <div class="form-group text-start">
                        <h5>
                            <b>Per&iacute;odo Anterior (De referencia)</b>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <label class="fw-bold">Fecha y Hora de inicio de comparaci&oacute;n</label>
                        <div class="form-group">
                            <div class="input-group" id="dateTimeCompareBeginDate">
                                <input type="text" class="form-control" bsDatepicker #dpcBegin="bsDatepicker"
                                    [bsValue]="compareBeginDate"
                                    value="{{ compareBeginDate | date:'dd-MM-yyyy' }} {{ compareBeginDate | date:'shortTime' }}"
                                    [outsideClick]="true" [maxDate]="beginDate"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY hh:mm a', containerClass: 'theme-blue'  }"
                                    (onHidden)="showTimeCompareBegin()" (bsValueChange)="setCompareDateBegin($event)">
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="dpcBegin.show()"
                                        [attr.aria-expanded]="dpcBegin.isOpen"><i
                                            class="bi bi-calendar-event-fill"></i></button>
                                </div>
                            </div>
                            <div [ngClass]="{'timerContainer':true, 'timeEndDate':true}"
                                [ngStyle]="{'display': isHideCompareTimerBegin?'none':'block'}">
                                <timepicker [(ngModel)]="compareBeginDate"></timepicker>
                                <button class="btn btn-info btn-sm" (click)="hideCompareTimeBegin(compareBeginDate)"><i
                                        class="bi bi-check-lg"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <label class="fw-bold">Fecha y Hora de finalizaci&oacute;n de comparaci&oacute;n</label>
                        <div class="form-group">
                            <div class="input-group" id="dateTimeCompareBeginDate">
                                <input type="text" class="form-control" bsDatepicker #dpcEnd="bsDatepicker"
                                    [bsValue]="compareEndDate"
                                    value="{{ compareEndDate | date:'dd-MM-yyyy' }} {{ compareEndDate | date:'shortTime' }}"
                                    [outsideClick]="true" [minDate]="compareBeginDate" [maxDate]="beginDate"
                                    (onHidden)="showTimeCompareEnd()" (bsValueChange)="setCompareDateEnd($event)"
                                    [bsConfig]="{ containerClass:'theme-blue', dateInputFormat: 'DD-MM-YYYY hh:mm a'  }">
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="dpcEnd.show()"
                                        [attr.aria-expanded]="dpcEnd.isOpen"><i
                                            class="bi bi-calendar-event-fill"></i></button>
                                </div>
                            </div>
                            <div [ngClass]="{'timerContainer':true, 'timeEndDate':true}"
                                [ngStyle]="{'display': isHideCompareTimerEnd?'none':'block'}">
                                <timepicker [(ngModel)]="compareEndDate"></timepicker>
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="hideCompareTimeEnd(compareEndDate)"><i
                                            class="bi bi-check-lg"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group text-start">
                        <small>* La selecci&oacute;n de este rango de fechas es opcional. El rango de fechas
                            seleccionada no deber&aacute; superar los 90 d&iacute;as.</small>
                    </div>
                </div>
                <div class="row pt-5 pb-1">
                    <div class="form-group text-start">
                        <h5>
                            <b>Mix y Objetivo</b>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <div class="checkbox checkbox-alert">
                            <label class="fw-bold d-flex align-items-center">Infinia Nafta&nbsp;
                                <span class="cr" (click)="onSelectNafta()">
                                    <i
                                        [ngClass]="{'cr-icon':true,'bi':true, 'bi-check':isNafta, 'combo-disable':!isNafta}"></i>
                                </span>
                            </label>
                        </div>
                        <div *ngIf="isNafta" class="input-group py-2">
                            <input id="naftapercentage" name="NaftaPercentage" #naftapercentage="ngModel"
                                placeholder="Ingrese porcentaje" [(ngModel)]="NaftaPercentage" type="number" step="0.01"
                                onKeyPress="if(this.value.length==6) return false;" min="0" max="100" pInputText
                                [pattern]="ConstantRegEx.PERCENTAGE" class="form-control">
                            <span class="input-group-text">%</span>
                            <div *ngIf="naftapercentage.errors && (naftapercentage.dirty)" class="alert alert-danger">
                                <span *ngIf="name_filter.errors?.['required']">
                                    El valor debe ser mayor que 0 y menor que 100.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <div class="checkbox checkbox-alert">
                            <label class="fw-bold d-flex align-items-center">Infinia Diesel&nbsp;
                                <span class="cr" (click)="onSelectDiesel()">
                                    <i
                                        [ngClass]="{'cr-icon':true,'bi':true, 'bi-check':isDiesel, 'combo-disable':!isDiesel}"></i>
                                </span>
                            </label>
                        </div>
                        <div *ngIf="isDiesel" class="input-group py-2">
                            <input id="dieselpercentage" name="DieselPercentage" #dieselpercentage="ngModel"
                                placeholder="Ingrese porcentaje" [(ngModel)]="DieselPercentage" type="number"
                                step="0.01" onKeyPress="if(this.value.length==6) return false;" min="0" max="100"
                                pInputText [pattern]="ConstantRegEx.PERCENTAGE" class="form-control">
                            <span class="input-group-text">%</span>
                            <div *ngIf="dieselpercentage.errors && (dieselpercentage.dirty)" class="alert alert-danger">
                                <span *ngIf="name_filter.errors?.['required']">
                                    El valor debe ser mayor que 0 y menor que 100.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group text-start">
                        <small>* Es obligatorio selecionar al menos un mix para poder descargar el reporte. Ingresar el
                            objetivo no es obligatorio</small>
                    </div>
                </div>
                <div class="row pt-5 pb-1">
                    <div class="form-group text-start">
                        <h5>
                            <b>Res&uacute;men de Turnos</b>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <div class="checkbox checkbox-alert">
                            <label class="fw-bold align-items-center">Incluir Resumen de Turnos
                                <span class="cr" (click)="onSelectShiftSummary()">
                                    <i
                                        [ngClass]="{'cr-icon':true,'bi':true, 'bi-check':shiftSummary, 'combo-disable':!shiftSummary}"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group text-start">
                        <small>Al incluir el resumen de Turnos cada despacho ser&aacute; clasificado seg&uacute;n la
                            siguiente definici&oacute;n de turnos: Turno Mañana: 6AM-2PM; Turno Tarde 2PM-10PM; Turno
                            Noche: 10PM-6AM. </small>
                    </div>
                </div>
                <div class="row pt-5 pb-1">
                    <div class="form-group text-start">
                        <h5>
                            <b>Informaci&oacute;n App YPF</b>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-xs-12 col-sm-7 text-start">
                        <div class="checkbox checkbox-alert">
                            <label class="fw-bold align-items-center">Incluir detalle del pago realizado con la App
                                <span class="cr" (click)="onSelectIsApp()">
                                    <i
                                        [ngClass]="{'cr-icon':true,'bi':true, 'bi-check':isApp, 'combo-disable':!isApp}"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group text-start">
                        <small>Cuando el pago haya sido realizado con la App se detallar&aacute;: medio de pago, mail
                            del usuario, y si hubieran: descuento aplicado y redenciones </small>
                    </div>
                </div>
                <div class="row">
                    <alert *ngIf="alertFilter.isShow" type="{{alertFilter.type}}"><span
                            [innerHtml]="alertFilter.message"></span></alert>
                </div>
            </div>
            <div class="col-xs-1 col-sm-2 col-md-2 col-lg-2"></div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-2 col-md-3 col-lg-3"></div>
            <div class="col-xs-12 col-sm-8 col-md-6 col-lg-6 text-center">
                <div class="row pt-4 justify-content-center">
                    <div class="col-auto">
                        <button class="btn btn-info" [disabled]="!validateDownloadReport()"
                            (click)="downloadReport()">DESCARGAR REPORTE</button>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-info" data-bs-toggle="modal" data-bs-target="#saveFilterModalShow"
                            [disabled]="!validateSaveDispatchFilter()" (click)="showModalSaveFilter()">GUARDAR
                            FILTRO</button>
                    </div>
                </div>
                <div class="row">
                    <small *ngIf="dispatchFilters?.length == 20">Llego al l&iacute;mite m&aacute;ximo de filtros,
                        elimine uno para crear otro nuevo.</small>
                </div>
            </div>
            <div class="col-xs-1 col-sm-2 col-md-3 col-lg-3"></div>
        </div>
        <div class="row pt-5 pb-1">
        </div>
    </section>
    <div id="saveFilterModalShow" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="saveFilterModalShow"
        aria-hidden="true" data-keyboard="false" data-backdrop="static" style="background:rgba(0, 0, 0, 0.88);">
        <div class="modal-dialog modal-lg" role="document" style="top:30%">
            <div class="modal-content">
                <form #filterForm="ngForm" (ngSubmit)="filterForm.valid && saveFilter(filterForm.value)">
                    <div class="modal-body text-center">
                        <div>
                            <h6 class="text-start pb-4"><b>Ingresa el nombre del nuevo filtro</b></h6>
                            <div class="row">
                                <div class="form-group text-start">
                                    <label class="fw-bold">Nombre filtro:</label>
                                    <br>
                                    <div class="pb-1">
                                        <small>* El nombre es obligatorio y no puede superar los 50
                                            caracteres</small>
                                        <small class="float-end">{{!!nameFilter ? nameFilter.length : 0}}/50</small>
                                    </div>
                                    <input class="form-control" maxlength="50" type="text" name="name_filter"
                                        #name_filter="ngModel" required [(ngModel)]="nameFilter">
                                    <div *ngIf="name_filter.errors && (name_filter.dirty || name_filter.touched || filterForm.submitted)"
                                        class="alert alert-danger">
                                        <span *ngIf="name_filter.errors?.['required']">
                                            Campo requerido.
                                        </span>
                                    </div>
                                    <h6 *ngIf="showErrorAlreadyExists" class="text-danger pt-1"><b>El nombre ya
                                            est&aacute;
                                            siendo usado, por favor eleg&iacute; otro</b></h6>
                                </div>
                            </div>
                            <div class="row pt-3 justify-content-center">
                                <div class="col-auto">
                                    <button class="btn btn-outline-secondary"
                                        data-bs-dismiss="modal">Atr&aacute;s</button>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-info" type="submit"
                                        [disabled]="!validateSaveFilter()">Confirmar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div id="errorDatesModalShow" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="errorDatesModalShow"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" style="background:rgba(0, 0, 0, 0.88);">
        <div class="modal-dialog modal-lg" role="document" style="top:30%">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div>
                        <h6 class="text-start pb-4"><b>Revisa las fechas ingresadas</b></h6>
                        <div class="row">
                            <p class="text-start">
                                Ingresaste un rango de fechas que exceden los 90 d&iacute;as de informaci&oacute;n o la
                                fecha de fin inferior a la fecha de inicio.
                            </p>
                            <p class="text-start">
                                S&oacute;lo est&aacute; permitido descargar la informaci&oacute;n de un per&iacute;odo
                                de
                                90 d&iacute;as c&oacute;mo m&aacute;ximo.
                            </p>
                            <p class="text-start">Por favor, para poder descargar el reporte modific&aacute; en los
                                filtros las fechas
                                ingresadas.</p>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-info" data-bs-dismiss="modal">Entendido</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="errorCompareDatesModalShow" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="errorCompareDatesModalShow"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" style="background:rgba(0, 0, 0, 0.88);">
        <div class="modal-dialog modal-lg" role="document" style="top:30%">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div>
                        <h6 class="text-start pb-4"><b>Revisa las fechas ingresadas</b></h6>
                        <div class="row">
                            <p class="text-start">
                                Las fechas del per&iacute;odo anterior (De referencia) no pueden ser superiores a las fechas del per&iacute;odo actual (A analizar).
                            </p>
                            <p class="text-start">Por favor, para poder descargar el reporte modific&aacute; en los
                                filtros las fechas
                                ingresadas.</p>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-info" data-bs-dismiss="modal">Entendido</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="deleteFilterModalShow" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="deleteFilterModalShow" aria-hidden="true" data-keyboard="false" data-backdrop="static"
        style="background:rgba(0, 0, 0, 0.88);">
        <div class="modal-dialog" role="document" style="top:30%">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div>
                        <h6 class="text-start pb-4"><b>Eliminar el Filtro</b></h6>
                        <div class="row">
                            <div class="form-group text-start">
                                <p class="text-start">Confirma la eliminaci&oacute;n del filtro?</p>
                            </div>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-outline-secondary" data-bs-dismiss="modal">Atr&aacute;s</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-info" (click)="removeFilter()"
                                    data-bs-dismiss="modal">Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>