import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbmPanelsService } from "src/app/services/panel.service";
import { Panel } from "src/app/models/abm-panels/panel.model";
import { Pump } from "src/app/models/abm-panels/pump.model";
import { AlertMessage } from "src/app/models/entity-response.model";

@Component({
    selector: 'abm-panels-item',
    templateUrl: './abm-panels-item.component.html',
    styleUrls: ['./abm-panels-item.component.scss'],
})

export class AbmPanelsItemComponent implements OnInit {

    @Input() index: number;
    @Input() entity: Panel;
    @Input() pumpList: Pump[];
    @Input() pumpListTemp: Pump[];
    @Output() addEvent: EventEmitter<Panel>;

    public alert: AlertMessage;
    public isEditPanel: boolean = false;
    public panel: string;

    constructor(public panelService: AbmPanelsService) {
        this.addEvent = new EventEmitter<Panel>();
    }

    ngOnInit(): void {
        this.panel = JSON.stringify(this.entity);
        let filterPumpsPanel = this.pumpList.filter((x) => {
            return x.pumpId == this.entity.left_fuel_dispenser || x.pumpId == this.entity.right_fuel_dispenser
        })
        var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        this.pumpListTemp = (this.pumpListTemp.concat(filterPumpsPanel)).sort();
        this.alert = new AlertMessage();
    }

    public cancelUpdatePanel() {
        this.panelService.setEditing(false);
        this.isEditPanel = false;
        this.entity = <Panel>JSON.parse(this.panel);
    }

    public editPanel(data: Panel) {
        this.isEditPanel = true;
        this.panelService.setEditing(true);
        this.panelService._panelFocus.id = data.id;
    }

    public setPanelDeleted(id: number) {
        this.panelService._panelFocus.id = id;
    }

    public onSubmitPanel(data: any) {
        console.log(this.entity)

        if (this.entity.left_fuel_dispenser + this.entity.right_fuel_dispenser == 0) {
            return this.alert.set("", "danger", "Debe seleccionar un surtidor como mínimo", true, 6000);
        }

        if (this.entity.left_fuel_dispenser == this.entity.right_fuel_dispenser) {
            return this.alert.set("", "danger", "No debe seleccionar dos surtidores iguales para este panel.", true, 6000);
        }

        this.addEvent.emit(this.entity)
        this.panelService.setEditing(false);
        this.isEditPanel = false;
    }
}