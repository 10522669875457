import { Injectable } from "@angular/core";
import { EntityResponse } from "src/app/models/entity-response.model";
import { Observable, catchError, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { PumpInsert } from "../models/abm-panels/pump.model";
import { AbstractService } from "./abstract.service";
import { HelperService } from "./helper.service";

@Injectable({
    providedIn: 'root'
})
export class ReaderService extends AbstractService {

    public comboToCancel: string;
    private ms_dispatches: string = "/msdispatches/api"

    constructor(protected override http: HttpClient, private helperService: HelperService) {
        super(http)
        this.comboToCancel = "0";
    }

    public getPump(apies: string): Observable<EntityResponse> {
        return super.httpWafDirectGetEntityResponse(`${this.ms_dispatches}/gaspump/pump/${apies}`).pipe(
            map(response => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public getPumpReaders(apies: string): Observable<EntityResponse> {
        return super.httpWafGet(`${this.ms_dispatches}/gaspump`, `apies:${apies}`, 'name:asc', 1, 1000).pipe(
            map(response => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public addOrUpdateRegister(pumpId: number, data: PumpInsert): Observable<EntityResponse> {
        return super.httpWafPut(`${this.ms_dispatches}/gaspump`, data, `${pumpId}`).pipe(
            map((response) => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public unlinkPumpReader(pumpId: number): Observable<EntityResponse> {
        let data = [{ "op": "replace", "path": "/readerName", "value": null }]
        return super.httpWafPatch(`${this.ms_dispatches}/gaspump`, data, `${pumpId}`).pipe(
            map(response => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }
}