import { Component, OnInit } from "@angular/core";
import { CustomMessage } from "src/app/models/custom-message/custom-message.model";
import { IconItem } from "src/app/models/custom-message/icon-item.model";
import { AlertMessage } from "src/app/models/entity-response.model";
import { Station } from "src/app/modules/app.generated.module";
import { AlertaPromoService } from "src/app/services/alerta-promo.service";
import { SecurityService } from "src/app/services/security.service";
import { StationService } from "src/app/services/station.service";
import { ConstantRegEx } from "src/app/utils/constant-values";
declare var $: any;

@Component({
    selector: 'custom-message',
    templateUrl: './custom-message.component.html',
    styleUrls: ['../admin/admin.component.scss', './custom-message.component.scss']
})

export class CustomMessageComponent implements OnInit {

    public isLoadData: boolean;
    private fuel_station_id: number = 0;
    public stationsFinder: Station[] = [];
    public customMessage: CustomMessage;
    public canEditMessage: boolean = false;
    public disableMessageEdition: boolean = false;
    public showEditMessageButton: boolean = false;
    public showConfirmEditionButton: boolean = false;
    public showCreateMessageButton: boolean = false;
    public showPublishMessage: boolean = false;
    public activateMessage: boolean = false;
    public deactivateMessage: boolean = false;
    public showContent: boolean = false;
    public previousMessage: string;
    public previousIcon: string;
    public icons: IconItem[] = [];
    public alert: AlertMessage;
    public toRequired: Boolean;
    public fromRequired: Boolean;
    public isHideTimerFrom: boolean;
    public isHideTimerTo: boolean;
    private _fromDate: Date;
    public fromDate: Date;
    private _toDate: Date;
    public toDate: Date;
    public lastBegindDefault = new Date();
    public today_date: Date;
    public es: any; // language

    readonly ConstantRegEx = ConstantRegEx;

    constructor(private stationService: StationService, private securityService: SecurityService, private alertaPromoService: AlertaPromoService) {
        this.fuel_station_id = this.securityService.getApies();
        this.isLoadData = false;
        this.isHideTimerFrom = true;
        this.isHideTimerTo = true;
        this.fromDate = this._fromDate = this.toDate = this._toDate = this.today_date = new Date();
    }

    ngOnInit(): void {
        this.icons =
            [
                { label: ' ', value: "celebration" },
                { label: ' ', value: "active_notifications" },
                { label: ' ', value: "satisfied" },
                { label: ' ', value: "chat" },
                { label: ' ', value: "grade" }
            ];
        this.customMessage = new CustomMessage();
        this.customMessage.apies = this.fuel_station_id;
        this.alert = new AlertMessage();

        this.stationService.getStationByApies(this.fuel_station_id)
            .subscribe((result) => {
                result.name = `${result.apiesText} - ${result.name}`;
                this.stationsFinder = new Array(result);
                this.isLoadData = true;
                this.alertaPromoService.getCustomMessage(this.fuel_station_id)
                    .subscribe({
                        next: (result) => {
                            if (result.data == null) {
                                this.showCreateMessageButton = true;
                                this.showPublishMessage = false;
                            }
                            else {

                                this.showEditMessageButton = true;
                                this.showCreateMessageButton = false;
                                this.customMessage = (<CustomMessage>result.data);
                                this.fromDate = result.data.from.includes("Z") ? new Date(`${result.data.from}`) : new Date(`${result.data.from}Z`);
                                this.toDate = result.data.to.includes("Z") ? new Date(`${result.data.to}`) : new Date(`${result.data.to}Z`);
                                this.showPublishMessage = true;
                                this.activateMessage = !this.customMessage.active;
                                this.canEditMessage = this.customMessage.active;
                                this.deactivateMessage = this.customMessage.active;
                                this.disableMessageEdition = true;
                            }
                        },
                        error: (errors) => {
                            console.error(errors);
                        }
                    });
            });
    }

    enableEdidMessage() {
        this.previousMessage = this.customMessage.description;
        this.previousIcon = this.customMessage.icon;
        this.canEditMessage = true;
        this.showConfirmEditionButton = true;
        this.disableMessageEdition = false;
        this.showPublishMessage = false;
    }

    onSubmit() {
        $('#creationMessageModalShow').modal('show');
    }

    onEditMessage() {
        $('#editMessageModalShow').modal('show');
    }

    cancelEdition() {
        this.customMessage.description = this.previousMessage;
        this.customMessage.icon = this.previousIcon;
        this.canEditMessage = false;
        this.showConfirmEditionButton = false;
        this.disableMessageEdition = true;
        this.showPublishMessage = true;
    }

    activationMessage() {
        $('#activationMessageModalShow').modal('show');
    }

    createCustomMessage() {
        this.customMessage.source = "JPV"
        this.customMessage.from = this.fromDate;
        this.customMessage.to = this.toDate;
        this.alertaPromoService.postCustomMessage(this.customMessage)
            .subscribe({
                next: (result) => {
                    $('#creationMessageModalShow').modal('hide');
                    this.customMessage = (<CustomMessage>result.data);
                    this.fromDate = result.data.from.includes("Z") ? new Date(`${result.data.from}`) : new Date(`${result.data.from}Z`);
                    this.toDate = result.data.to.includes("Z") ? new Date(`${result.data.to}`) : new Date(`${result.data.to}Z`);
                    this.alert.set("", "success", "¡Mensaje creado con éxito!", true, 5000);
                    this.showPublishMessage = true;
                    this.activateMessage = true;
                    this.showCreateMessageButton = false;
                    this.showEditMessageButton = true;
                    this.disableMessageEdition = true;
                },
                error: (errors) => {
                    $('#creationMessageModalShow').modal('hide');

                    this.alert.set("", "danger", "¡Lo sentimos! Ocurrió un error al crear el mensaje", true, 5000);
                    console.error(errors);
                }
            });
    }

    editCustomMessage() {
        this.customMessage.source = "JPV"
        this.customMessage.from = this.fromDate;
        this.customMessage.to = this.toDate;
        this.alertaPromoService.putCustomMessage(this.customMessage)
            .subscribe({
                next: (result) => {
                    $('#editMessageModalShow').modal('hide');
                    this.customMessage = (<CustomMessage>result.data);
                    this.fromDate = result.data.from.includes("Z") ? new Date(`${result.data.from}`) : new Date(`${result.data.from}Z`);
                    this.toDate = result.data.to.includes("Z") ? new Date(`${result.data.to}`) : new Date(`${result.data.to}Z`);
                    this.alert.set("", "success", "¡Mensaje editado con éxito!", true, 5000);
                    this.canEditMessage = this.customMessage.active;
                    this.showConfirmEditionButton = false;
                    this.showEditMessageButton = true;
                    this.disableMessageEdition = true;
                    this.showPublishMessage = true;
                },
                error: (errors) => {
                    $('#editMessageModalShow').modal('hide');
                    this.alert.set("", "danger", "<b>¡Lo sentimos! Ocurrió un error al actualizar el mensaje.</b><br/> Por favor intentá de nuevo más tarde", true, 5000);
                    console.error(errors);
                }
            });
    }

    okActivationMessage(isActive: boolean) {
        this.customMessage.active = isActive;
        this.alertaPromoService.putCustomMessage(this.customMessage)
            .subscribe({
                next: (result) => {
                    $('#activationMessageModalShow').modal('hide');
                    this.customMessage = (<CustomMessage>result.data);
                    this.activateMessage = !this.customMessage.active;
                    this.deactivateMessage = this.customMessage.active;
                    this.canEditMessage = this.customMessage.active;
                    this.alert.set("", "success", isActive ? "¡Mensaje activado con éxito!" : "¡Mensaje pausado con éxito!", true, 5000);
                    this.showPublishMessage = true;
                },
                error: (errors) => {
                    $('#activationMessageModalShow').modal('hide');
                    this.alert.set("", "danger", "<b>¡Lo sentimos! Ocurrió un error al actualizar el mensaje.</b><br/>Por favor, intentá de nuevo más tarde", true, 5000);
                    console.error(errors);
                }
            });
    }

    showTimeFrom() {
        if (this._fromDate != this.fromDate)
            this.isHideTimerFrom = false;
    }

    hideTimeFrom(date: Date) {
        this._fromDate = date;
        this.isHideTimerFrom = true;
    }

    showTimeTo() {
        if (this._toDate != this.toDate)
            this.isHideTimerTo = false;
    }

    hideTimeTo(date: Date) {
        this._toDate = date;
        this.isHideTimerTo = true;
    }

    setDateFrom(date: Date) {
        this.fromDate = date;
        this.UpdateEndDate(date);
    }

    setDateTo(date: Date) {
        this.toDate = date;
        this.UpdateEndDate(date);
    }

    validateModel() {
        if (this.customMessage.description == undefined) {
            return false;
        }

        if (this.customMessage.icon == undefined) {
            return false;
        }

        if (this.fromDate == undefined) {
            return false;
        }

        if (this.toDate == undefined) {
            return false;
        }

        return true;
    }

    UpdateEndDate(date: any) {
        if (this.fromDate.getDate() < this.today_date.getDate()) {
            this.fromDate = new Date(this.today_date);
        }

        // set Date to 00:00 23-59
        if (this.fromDate.getDate() > this.today_date.getDate() && this.fromDate.getDate() != this.lastBegindDefault.getDate()) {
            this.lastBegindDefault = this.fromDate;
            this.fromDate.setHours(0, 0, 0, 0);
            this.fromDate = new Date(this.fromDate);

            if (this.fromDate >= this.toDate) {
                this.toDate = new Date(this.fromDate);
                this.toDate.setMinutes(this.toDate.getMinutes() + 1439)
            }
        } else {
            this.lastBegindDefault = this.fromDate;
        }

        if (this.toDate == undefined || this.fromDate > this.toDate) {
            this.toDate = new Date(this.fromDate);
            this.toDate.setHours(23, 59, 59);
        }
    }
}