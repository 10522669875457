import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { AbstractResponse } from "../models/abstract-response.model";
import { environment } from "src/environments/environment";
import { PageModel } from "../models/page.model";
import { EntityResponse } from "../models/entity-response.model";

@Injectable({
    providedIn: 'root'
})

export class AbstractService {

    constructor(protected http: HttpClient) {
    }

    public httpPost(url: string = "", entity: any): Observable<EntityResponse> {
        return this.http.post(`${environment.url_ame}${url}`, entity)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            );
    }

    public httpWafPost(url: string = "", entity: any): Observable<EntityResponse> {
        return this.http.post(`${environment.url_waf}${url}`, entity)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            );
    }

    public httpPut(url: string = "", entity: any, entity_id: any): Observable<EntityResponse> {
        return this.http.put(`${environment.url_ame}${url}/${entity_id}`, entity)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    public httpWafPut(url: string = "", entity: any, entity_id: any): Observable<EntityResponse> {
        return this.http.put(`${environment.url_waf}${url}/${entity_id}`, entity)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    public httpPatch(url: string = "", entity: any, entity_id: any): Observable<AbstractResponse> {
        return this.http.patch(`${environment.url_ame}${url}/${entity_id}`, entity)
            .pipe(
                map(resp => {
                    return <AbstractResponse>resp;
                })
            )
    }

    public httpWafPatch(url: string = "", entity: any, entity_id: any): Observable<EntityResponse> {
        return this.http.patch(`${environment.url_waf}${url}/${entity_id}`, entity)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    public httpGetPageModel(url: string = "", search: string = "", sort: string = "", page: number = 1, size: number = 20): Observable<PageModel> {
        return this.http.get(`${environment.url_ame}${this.getUrl(url, search, sort, page, size)}`)
            .pipe(
                map(resp => {
                    return <PageModel>resp;
                })
            )
    }

    public httpGet(url: string = "", search: string = "", sort: string = "", page: number = 1, size: number = 20): Observable<EntityResponse> {
        return this.http.get(`${environment.url_ame}${this.getUrl(url, search, sort, page, size)}`)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    public httpWafGet(url: string = "", search: string = "", sort: string = "", page: number = 1, size: number = 20): Observable<EntityResponse> {
        return this.http.get(`${environment.url_waf}${this.getUrl(url, search, sort, page, size)}`)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    public httpGetBlob(url: string = "", search: string = "", sort: string = ""): Observable<Blob> {
        return this.http.get(`${environment.url_ame}${this.getUrl(url, search, sort, 0, 0)}`, { responseType: 'blob' })
            .pipe(
                map(resp => {
                    return <Blob>resp;
                })
            )
    }

    public httpDirectGetList(url: string): Observable<PageModel> {
        return this.http.get(`${environment.url_ame}${url}`)
            .pipe(
                map(resp => {
                    return <PageModel>resp;
                })
            )
    }

    public httpDirectGet(url: string): Observable<AbstractResponse> {
        return this.http.get(`${environment.url_ame}${url}`)
            .pipe(
                map(resp => {
                    return <PageModel>resp;
                })
            )
    }

    public httpWafDirectGet(url: string): Observable<AbstractResponse> {
        return this.http.get(`${environment.url_waf}${url}`)
            .pipe(
                map(resp => {
                    return <PageModel>resp;
                })
            )
    }

    public httpWafSellerDotationDirectGet(apies: string): Observable<AbstractResponse> {
        return this.http.get(`${environment.url_seller_dotation}${apies}`)
            .pipe(
                map(resp => {
                    return <PageModel>resp;
                })
            )
    }

    public httpDirectGetEntityResponse(url: string): Observable<EntityResponse> {
        return this.http.get(`${environment.url_ame}${url}`)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    public httpWafDirectGetEntityResponse(url: string): Observable<EntityResponse> {
        return this.http.get(`${environment.url_waf}${url}`)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    public httpDelete(url: string) {
        return this.http.delete(`${environment.url_ame}${url}`)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    public httpWafDelete(url: string) {
        return this.http.delete(`${environment.url_waf}${url}`)
            .pipe(
                map(resp => {
                    return <EntityResponse>resp;
                })
            )
    }

    private getUrl(url: string, search: string, sort: string, page: number = 1, size: number = 20): string {
        return `${url}?search=${search}&sort=${sort}&page=${page}&size=${size}`;
    }
}