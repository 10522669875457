import { Component, DoCheck, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, switchMap, timer } from "rxjs";
import { ICommandListener } from "src/app/interfaces/icommand-listener.interface";
import { Cliente, ClienteMetadata, Lector, Surtidor } from "src/app/modules/app.generated.module";
import { ComandaService } from "src/app/services/comanda.service";
import { CommandCodeDictionary } from "src/app/services/logic/command-code-dictionary.service";
import { FocusKeyboardManager } from "src/app/services/logic/focus-key-board-manager";
import { KeyCommandProcessor } from "src/app/services/logic/key-command-processor.service";
import { SecurityService } from "src/app/services/security.service";
import { ComandaOrderCombo } from "src/app/models/comanda/comanda.model";

@Component({
    selector: 'panel-surtidor-container',
    templateUrl: './panel-surtidor-container.component.html',
    styleUrls: ['./panel-surtidor-container.component.scss']
})
export class PanelSurtidorContainerComponent implements ICommandListener, OnInit, DoCheck {
    @Output() aExpanded = new EventEmitter();
    idWorkStation: number;
    idPanelSurtidor: number;
    isFocused: boolean;
    isListening: boolean;
    patternCode: RegExp = /^[0-9]{1,3}$/;
    elementCode: string;
    focusIndex: number;
    lastKey: string;
    currentCommand: string[];
    isWantCancelCompleteOrder: Subject<any> = new Subject();
    completeOrder: Subject<any> = new Subject();

    private keyCommandProcessor: KeyCommandProcessor;

    //Temporaly solition this will be construct in this componen not recibe like an input
    @Input()
    surtidor: Surtidor;
    @Input()
    cliente: Cliente;
    @Input()
    sellerPanelReader: Lector;
    @Input() isLive: boolean;
    @Input() isDebug: boolean;
    @Input() mqttError: boolean;
    @Input() clienteError: boolean;
    @Input() isDisabled_column: boolean;
    @Input() position: string;
    @Input() lector: Lector;
    @Input() dataCliente: ClienteMetadata;

    // Informacion que se manda a persistir en la orden
    public comandaOrder: ComandaOrderCombo;
    public apies: string;
    public isAvailablePanelCombos: boolean = false;
    public canBePressed: boolean = true;

    constructor(private dictionary: CommandCodeDictionary,
        private focusManager: FocusKeyboardManager,
        protected _comandaService: ComandaService,
        protected activatedRoute: ActivatedRoute,
        private securityService: SecurityService,
        private router: Router) {
        this.position = 'izquierda';
        this.comandaOrder = new ComandaOrderCombo;
        this.comandaOrder.combos = [];
    }

    ngOnInit(): void {
        let _apies = this.securityService.getApies();
        if (_apies <= 0) this.router.navigate(['/home'])
        this.apies = _apies.toString();
        this.keyCommandProcessor
            = new KeyCommandProcessor(this, this.dictionary,
                this.patternCode,
                this.focusManager);

        this.elementCode = this.surtidor.name;
        this.lastKey = '';
        this.currentCommand = [];
        this.focusManager.addListener(this);
        this.focusIndex = this.focusManager.getFocusArrayCurrentIndex();
        this.isFocused = false;
    }

    ngDoCheck() {
        if (this.isFocused && this.isListening) {
            if (this.surtidor.status == 'Available' || this.surtidor.status == 'End') {
                this.isWantCancelCompleteOrder.next('scape');
                this.keyCommandProcessor.actionScapeFunction();
            }
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        // Esto debe cambiar, debería procesarce esto al resolver una promesa o un observable
        if (!(this.surtidor.status == 'Authorized' || this.surtidor.status == 'Dispatching'))
            return;

        this.elementCode = `${this.focusIndex}.${this.surtidor.name}`;
        let wasFocused = this.isFocused;
        let lastCurrentCommand = this.currentCommand;
        if (this.isListening) {
            this.keyCommandProcessor.processKey(event.keyCode, event.key);
        }

        console.log('Go valid', wasFocused, this.isListening)
        if (wasFocused && this.isListening) {
            switch (this.dictionary.getCommandAction(event.keyCode, event.key)) {
                case 'enter':
                    if (lastCurrentCommand.join('').length == 0 && this.comandaOrder.combos.length && this.canBePressed) {
                        this.canBePressed = false;
                        setInterval(() => { this.canBePressed = true }, 3000)
                        this.completeOrder.next(this.keyCommandProcessor);
                    }
                    break;
                case 'scape':
                    this.isWantCancelCompleteOrder.next('scape');
                    this.keyCommandProcessor.actionScapeFunction();
                    break;
            }
        }
    }

    onEnter() {
        this.focusManager.onlyOneListener(this);
        this.focusManager.addFocus(this);
        this.isFocused = true;
        this.aExpanded.emit(this.isFocused);
        this.comandaOrder.combos = [];
    }

    onEscape(lastCommand: string) {
        this.focusManager.removeLastFocus();
        this.isFocused = false;
        this.aExpanded.emit(this.isFocused);
    }

    proccessNoValidCommand(noValidCommand: string) {
        console.log(`el comando ' ${noValidCommand} ' no es válido`);
    }

    setComandaOrder(comandaOrder: ComandaOrderCombo) {
        console.warn("Se emitio la orden " + comandaOrder.id)
        this.comandaOrder = comandaOrder;
    }

    getIsAvailablePanelCombos() {
        timer(0, 300000)
            .pipe(
                switchMap(() => this._comandaService.getIsAvailablePanelCombos(this.apies.toString()))
            )
            .subscribe({
                next: (data) => {
                    this.isAvailablePanelCombos = data;
                },
                error: (err) => console.error(err)
            });
    }
}