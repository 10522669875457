<div class="" [attr.data-isFulllView]='isPanelView'>
    <section *ngIf="currentView.vista==0" class="">
        <alert *ngIf="succesSave == true && detectIE()>=12" type="success">Las novedades se publicaron
            <strong>exitosamente.</strong>
        </alert>
        <alert *ngIf="succesSave == true && detectIE()<12" type="success">Lo sentimos, la publicaci&#243;n se
            realiz&#243; correctamente pero no es posible visualizar el panel en este navegador. Pod&#233;s acercarte a
            un Surtidor para verlo publicado</alert>
        <div *ngIf="helperService.showErrorMessage" class="alert alert-danger">
            Es probable que la promoci&oacute;n &oacute; alerta seleccionada est&eacute; inactiva a nivel global. Favor
            de contactar con el admin de ABM Ofertas comerciales. En caso de recurrencia reportar.
        </div>
        <div class="row" *ngIf="!isLive">
            <div class="col-md-12">
                <h6>
                    <b>Previsualizaci&oacute;n</b>
                    <button (click)="editarPanel()"
                        class="btn btn-info text-uppercase float-end btn-sm margin-bottom-10">EDITAR</button>
                </h6>
            </div>
        </div>
        <main [ngClass]="{'zoom-panel-view':!isLive}">
            <div *ngIf="!isLoadData; else elseBlock" style="margin-top:3em">
                <img src="../../../assets/images/loader-gris.svg" /> Cargando datos...
            </div>
            <ng-template #elseBlock>
                <panel-grid [filtroArea]="filtroArea" [detectIE]="detectIE()" [isPanelView]="isPanelView"
                    [surtidor_left]="surtidor_left" [dataClienteLeft]="dataClienteLeft"
                    [clienteErrorLeft]="clienteErrorLeft" [isDisabled_left_column]="isDisabled_left_column"
                    [lector_left]="lector_left" [surtidor_right]="surtidor_right" [dataClienteRight]="dataClienteRight"
                    [clienteErrorRight]="clienteErrorRight" [isDisabled_right_column]="isDisabled_right_column"
                    [lector_right]="lector_right" [isLive]="isLive" [mqttError]="mqttError"
                    [lastMqttMessage]="lastMqttMessage" [lastAlertasPromos]="lastAlertasPromos"
                    [lastClienteLeft]="lastClienteLeft" [lastClienteRight]="lastClienteRight"
                    [alertasPromosError]="alertasPromosError" [promos]="promos_original_filter"
                    [alertas]="alertas_original_filter" [mensajes]="mensajes_original_filter" [cantidadOfertas]="cantidadOfertas"
                    [cantidadAlertas]="cantidadAlertas" [cantidadMensajes]="cantidadMensajes">
                </panel-grid>
            </ng-template>
        </main>
    </section>
    <section *ngIf="currentView.vista==1 && !isLive" class="row admin">
        <alert *ngIf="excedeLimite" type="warning"><strong>No debe exceder el l&#237;mite de informaci&#243;n a mostrar
                en &quot;Playa&quot;.</strong> Dej&#225; visible la informaci&#243;n m&#225;s urgente.</alert>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <b>Edici&oacute;n</b>
                    <button (click)="previsualizar()" [disabled]="!hasChanged || excedeLimite ? 'disabled' : ''"
                        class="btn btn-info btn-sm text-uppercase float-end margin-bottom-10 margin-left-10">Previsualizar</button>
                    <button (click)="onCancelar()"
                        class="btn btn-outline-secondary btn-sm btn-descartar text-uppercase float-end margin-bottom-10 ">Descartar</button>
                </h6>
            </div>
        </div>
        <hr />
        <header class="panel-view-header">
            <ul class="nav nav-pills">
                <li role="presentation" class="nav-item"><a (click)="filtroTipoAlerta=2" href="javascript:void(0)"
                        [class]="filtroTipoAlerta == 2 ? 'nav-link text-decoration-none active' : 'nav-link text-decoration-none'">Promociones</a>
                </li>
                <li role="presentation" class="nav-item"><a (click)="filtroTipoAlerta=1" href="javascript:void(0)"
                        [class]="filtroTipoAlerta == 1 ? 'nav-link text-decoration-none active' : 'nav-link text-decoration-none'">Alertas</a>
                </li>
            </ul>
        </header>
        <main class="panel-view-main">
            <div class="card-deck" id="accordion" role="tablist" aria-multiselectable="true">
                <div *ngFor="let alerta of alertas | tipoAlertaPromoFilter:filtroTipoAlerta;"
                    class="card c-alert bg-light mb-1">
                    <div class="card-body c-body p-2">
                        <div class="row" id="heading-{{alerta.id}}">
                            <div class="col-auto align-self-center">
                                <div class="checkbox checkbox-alert float-end">
                                    <label>
                                        <span class="cr" (click)="actualizarActivos(alerta)">
                                            <i
                                                [ngClass]="{'cr-icon':true,'bi':true, 'bi-check':alerta.isActive, 'combo-disable':!alerta.isActive}"></i>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-8">
                                <h6 class="panel-title pt-2 panel-item-title panel-item-header-grid-text">
                                    <a data-toggle="collapse" data-parent="#accordion" class="alerta-title"
                                        [title]="alerta.message" href="javascript:void(0)" aria-expanded="false"
                                        [attr.aria-controls]="'collapse-' + alerta.id">
                                        <span
                                            [ngClass]="['panel-item-summary-accion', 'panel-item-tipo-' + alerta.tipoAlerta]"><b>{{alerta.accion}}</b></span>
                                        <span
                                            [ngClass]="['panel-item-summary-tipoPromocion', 'panel-item-tipo-' + alerta.tipoAlerta ]">{{alerta.tipoPromocion}}</span>
                                        <span
                                            [ngClass]="['panel-item-summary-montoDescuento', 'panel-item-tipo-' + alerta.tipoAlerta ]">{{alerta.montoDescuento}}</span>
                                        <span style="display:none"
                                            [ngClass]="['panel-item-summary-tipoDescuento', 'panel-item-tipo-' + alerta.tipoAlerta ]">
                                            <span *ngIf="alerta.tipoDescuento=='Dinero'">$</span>
                                            <span *ngIf="alerta.tipoDescuento=='Porcentaje'">%</span>
                                            <span *ngIf="alerta.tipoDescuento=='Kilometros'">Km</span>
                                        </span>
                                        <span
                                            [ngClass]="['panel-item-summary-entidad', 'panel-item-tipo-' + alerta.tipoAlerta ]">{{alerta.entidad}}</span>
                                        <span
                                            [ngClass]="['panel-item-summary-message', 'panel-item-tipo-' + alerta.tipoAlerta ]">{{alerta.message}}</span>
                                        <span
                                            [ngClass]="['panel-item-summary-descripcionCorta', 'panel-item-tipo-' + alerta.tipoAlerta ]">
                                            {{alerta.descripcionCorta}}</span>
                                    </a>
                                </h6>
                            </div>
                            <div class="col-3">
                                <div class="icon-list p-1 panel-item-header-grid-chevron float-end">
                                    <div class="icon-item hidden-xs">
                                        <img [ngClass]="{'icon-preview':1, 'isHidden':!alerta.isForBeach}"
                                            src="../../../assets/images/surtidor-01.svg" />
                                    </div>
                                    <div class="icon-item hidden-xs">
                                        <img [ngClass]="{'icon-preview':1, 'isHidden':!alerta.isForStore}"
                                            src="../../../assets/images/store-01.svg" />
                                    </div>
                                    <div class="icon-item hidden-xs">
                                        <img [ngClass]="{'icon-preview':1, 'isHidden':!alerta.isForBox}"
                                            src="../../../assets/images/boxes-01.svg" />
                                    </div>
                                    <div class="icon-item hidden-xs" style="display:none">
                                        <p class="priority_circle" [attr.data-priority]="alerta.priority"></p>
                                    </div>
                                    <!-- Se deja de mostrar funcionalidad de prioridades -->
                                    <div class="icon-item" style="display:none">
                                        <a id="expander-{{alerta.id}}" class="expander collapsed" data-toggle="collapse"
                                            data-parent="#accordion" href="#collapse-{{alerta.id}}"
                                            aria-expanded="false" [attr.aria-controls]="'collapse-' + alerta.id">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="collapse-{{alerta.id}}" class="panel-collapse collapse" role="tabpanel"
                        [attr.aria-labelledby]="'heading-' + alerta.id">
                        <div class="panel-body sm-fix-pd">
                            <div class="form-group col-xs-12 col-sm-12 col-md-6 sm-fix-pd"
                                *ngIf="alerta.tipoAlerta == 1">
                                <label>&#193;rea</label>
                                <p>Seleccion&#225; todos los lugares donde se va a mostrar</p>
                                <div class="btn-group btn-group" role="group">
                                    <div class="btn-group" role="group">
                                        <button type="button"
                                            [ngClass]="{'btn':true, 'btn-default btn-sm':true, 'active':alerta.isForBeach, 'btn-area':true }"
                                            (click)="toogleArea(alerta,0)">
                                            <div class="layer-opt"></div>
                                            <img class="icon-preview hidden-xs hidden-sm"
                                                src="../../../assets/images/surtidor-01.svg" />
                                            <span class="btn-txt2">Playa</span>
                                            <span [ngClass]="{'glyphicon':1,'glyphicon-ok':alerta.isForBeach}"></span>
                                        </button>
                                    </div>
                                    <div class="btn-group" role="group">
                                        <button disabled="disabled" type="button"
                                            [ngClass]="{'btn':true, 'btn-default btn-sm':true, 'active':alerta.isForStore, 'btn-area':true}"
                                            (click)="toogleArea(alerta,1)">
                                            <div class="layer-opt"></div>
                                            <img class="icon-preview hidden-xs hidden-sm"
                                                src="../../../assets/images/store-01.svg" />
                                            <span class="btn-txt2">Tienda</span>
                                            <span [ngClass]="{'glyphicon':1,'glyphicon-ok':alerta.isForStore}"></span>
                                        </button>
                                    </div>
                                    <div class="btn-group" role="group">
                                        <button disabled="disabled" type="button"
                                            [ngClass]="{'btn':true, 'btn-default btn-sm':true, 'active':alerta.isForBox, 'btn-area':true}"
                                            (click)="toogleArea(alerta,2)">
                                            <div class="layer-opt"></div>
                                            <img class="icon-preview hidden-xs hidden-sm"
                                                src="../../../assets/images/boxes-01.svg" />
                                            <span class="btn-txt2">Boxes</span>
                                            <span [ngClass]="{'glyphicon':1,'glyphicon-ok':alerta.isForBox}"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xs-12 col-sm-12 col-md-6 sm-fix-pd">
                                <label>Prioridad</label>
                                <p>Asignale una jerarqu&#237;a</p>
                                <div class="btn-group" role="group">
                                    <div class="btn-group" role="group">
                                        <button type="button" data-priority="1"
                                            [class]="alerta.priority == 1? 'btn btn-default btn-sm active btn-a':'btn btn-default btn-a'"
                                            (click)="actualizarPrioridad(alerta, 1)">
                                            <div class="layer"></div>
                                            <span class="btn-txt">Alta</span>
                                        </button>
                                    </div>
                                    <div class="btn-group" role="group">
                                        <button type="button" data-priority="2"
                                            [class]="alerta.priority == 2? 'btn btn-default btn-sm active btn-b':'btn btn-default btn-b'"
                                            (click)="actualizarPrioridad(alerta, 2)">
                                            <div class="layer"></div>
                                            <span class="btn-txt">Media</span>
                                        </button>
                                    </div>
                                    <div class="btn-group" role="group">
                                        <button type="button" data-priority="3"
                                            [class]="alerta.priority == 3? 'btn btn-default btn-sm active btn-c':'btn btn-default btn-c'"
                                            (click)="actualizarPrioridad(alerta, 3)">
                                            <div class="layer"></div>
                                            <span class="btn-txt">Baja</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </section>
    <section *ngIf="currentView.vista==2 && !isLive" class="preview">
        <alert *ngIf="filtroArea == 0 && hasChanged == true && excedeLimite == false" type="info">No olvides que
            ten&#233;s novedades sin <strong>publicar</strong> </alert>
        <div class="row">
            <div class="col-md-12 ">
                <h6>
                    <b>Edici&oacute;n</b>
                    <button (click)="onPublicar()"
                        class="btn btn-success btn-sm text-uppercase float-end margin-bottom-10 margin-left-10">Publicar</button>
                    <button (click)="editarPanel()"
                        class="btn btn-outline-secondary btn-sm btn-descartar btn-sm text-uppercase float-end margin-bottom-10">Descartar</button>
                </h6>
            </div>
        </div>
        <main [ngClass]="{'zoom-panel-view':!isLive}">
            <panel-grid [filtroArea]="filtroArea" [detectIE]="detectIE()" [isPanelView]="isPanelView"
                [surtidor_left]="surtidor_left" [dataClienteLeft]="dataClienteLeft"
                [clienteErrorLeft]="clienteErrorLeft" [isDisabled_left_column]="isDisabled_left_column"
                [lector_left]="lector_left" [surtidor_right]="surtidor_right" [dataClienteRight]="dataClienteRight"
                [clienteErrorRight]="clienteErrorRight" [isDisabled_right_column]="isDisabled_right_column"
                [lector_right]="lector_right" [isLive]="isLive" [mqttError]="mqttError"
                [lastMqttMessage]="lastMqttMessage" [lastAlertasPromos]="lastAlertasPromos"
                [lastClienteLeft]="lastClienteLeft" [lastClienteRight]="lastClienteRight"
                [alertasPromosError]="alertasPromosError" [promos]="promos_filter" [alertas]="alertas_filter"
                [mensajes]="mensajes_filter" [cantidadOfertas]="cantidadOfertas" [cantidadAlertas]="cantidadAlertas"
                [cantidadMensajes]="cantidadMensajes">
            </panel-grid>
        </main>
    </section>
</div>
<div *ngIf="verModal==true && !isLive" class="modal fade in show" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h6 *ngIf="excedeLimite && hasChanged" class="modal-title text-uppercase">&#161;Cuidado! Excediste el
                    l&#237;mite de novedades</h6>
                <h6 *ngIf="!excedeLimite && hasChanged" class="modal-title text-uppercase">&#161;Cuidado!</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="verModal = false"></button>
            </div>
            <div *ngIf="!excedeLimite && hasChanged" class="modal-body">
                Vas a salir sin publicar las novedades en el panel<br />
                &#191;Qu&#233;res publicarlas ahora?
            </div>
            <div *ngIf="excedeLimite && hasChanged" class="modal-body">
                No es posible publicar los cambios en el panel
                &#191;Queres continuar editando?
            </div>
            <div class="modal-footer d-none d-sm-block">
                <button type="button" class="btn btn-danger btn-sm btn-editar ms-auto text-uppercase"
                    (click)="descartar()" data-bs-dismiss="modal">Descartar cambios</button>
                <button type="button" *ngIf="!excedeLimite && hasChanged"
                    class="btn btn-success btn-sm btn-editar text-uppercase float-end" (click)="onPublicar()"
                    data-bs-dismiss="modal">Publicar panel</button>
                <button type="button" *ngIf="excedeLimite && hasChanged"
                    class="btn btn-success btn-sm btn-editar text-uppercase" aria-label="Close"
                    (click)="verModal = false" data-bs-dismiss="modal">Editar Panel</button>
            </div>
            <div class="modal-footer d-block d-sm-none">
                <button type="button" class="btn btn-danger btn-sm btn-editar ms-auto text-uppercase"
                    (click)="descartar()" data-bs-dismiss="modal">Descartar cambios</button>
                <button type="button" *ngIf="!excedeLimite && hasChanged"
                    class="btn btn-success btn-sm btn-editar text-uppercase float-end" (click)="onPublicar()"
                    data-bs-dismiss="modal">Publicar panel</button>
                <button type="button" *ngIf="excedeLimite && hasChanged"
                    class="btn btn-success btn-sm btn-editar text-uppercase" aria-label="Close"
                    (click)="verModal = false" data-bs-dismiss="modal">Editar Panel</button>
            </div>
        </div>
    </div>
</div>
<div class="alerta-bloq" *ngIf="showBloqueante && !isLive">
    <img src="../../../assets/images/alerta_bloqueante.png" />
</div>