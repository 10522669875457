import { Injectable } from "@angular/core";
import { AlertaPromo, EditAlertPromoDto, PrioridadAlertaPromo, TipoAlertaPromo, TipoArea } from "../modules/app.generated.module";
import { HelperService } from "./helper.service";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "./abstract.service";
import { Observable, catchError, forkJoin, map, of, share, switchMap, } from "rxjs";
import { EntityResponse } from "../models/entity-response.model";
import { CustomMessage } from "../models/custom-message/custom-message.model";

@Injectable({
    providedIn: 'root'
})

export class AlertaPromoService extends AbstractService {

    private ms_promotions: string = "/mspromotions/api";
    private ms_promotions_private: string = "/mspromotions/api/private";
    private securityType: string = <string>sessionStorage.getItem('_stype');
    private alertaPrefijo: string = "A_";
    private promocionPrefijo: string = "P_";
    private mensajePrefijo: string = "M_";

    constructor(protected override http: HttpClient, private helperService: HelperService) {
        super(http);
    }

    getAllAlertaPromo(apies: number): Observable<AlertaPromo[]> {
        const dayOfWeek = new Date().toLocaleString(
            'default', { weekday: 'long' }
        ).toUpperCase();
        const scheduleDate = `${new Date().getHours()}${new Date().getMinutes()}`
        const getMessages = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/fuel_station/${apies}/messages?size=1000`);
        const getArea = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/area_stations?size=1000&search=name:${TipoArea[TipoArea.Playa]}`);
        const getDay = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/days?size=1000&search=code:${dayOfWeek}`);
        const getSchedule = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/schedules?size=1000&search=from<${scheduleDate};to>${scheduleDate}`);
        const getPromos = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/fuel_station/${apies}/promotions?size=1000`);
        const getCustomMessages = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/custom_messages/panel/${apies}`);
        const getActionTypes = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/action_types`);
        const getComercialConditionTypes = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/commercial_condition_types`);

        return forkJoin([getMessages, getArea, getDay, getSchedule, getPromos, getCustomMessages, getActionTypes, getComercialConditionTypes]).pipe(
            switchMap(([responseMessages, responseArea, responseDay, responseSchedule, responsePromos, responseCustomMessages, responseActionTypes, responseComercialConditionTypes]) => {

                const alertasPromos: AlertaPromo[] = [];

                let messages = responseMessages.data?.map((x: any) => {
                    return {
                        isActive: x.is_active,
                        message: x.description,
                        imageUrl: x.icon,
                        id: `${this.alertaPrefijo}${x.id}`,
                        isForBeach: true,
                        _Id: x.id,
                        priority: PrioridadAlertaPromo.Alta,
                        tipoAlerta: TipoAlertaPromo.Alerta
                    }
                });

                alertasPromos.push(...messages);

                let Now = new Date();

                let filteredCustomMessages = responseCustomMessages.data?.filter((cm: any) =>
                    this.parseTimeUTC(cm.from) <= Now
                    && this.parseTimeUTC(cm.to) >= Now
                    && cm.active
                );

                if (filteredCustomMessages.length > 0) {
                    for (const cm of filteredCustomMessages) {
                        const alertaPromo = {
                            isActive: cm.active,
                            message: cm.description,
                            imageUrl: cm.icon,
                            id: `${this.mensajePrefijo}${cm.id}`,
                            _Id: cm.id,
                            priority: PrioridadAlertaPromo.Alta,
                            isForBeach: true,
                            tipoAlerta: TipoAlertaPromo.Mensaje
                        };

                        alertasPromos.push(<AlertaPromo>alertaPromo);
                    }
                }

                let area = 0;
                let day = 0;

                area = responseArea.data[0]?.id;
                day = responseDay.data[0]?.id;

                let schedules = responseSchedule.data.map((x: any) => {
                    return x.schedule_code
                });

                let filteredPromotions = responsePromos.data?.filter((promo: any) => promo.area_stations.includes(area)
                    && promo.days.includes(day)
                    && new Date(promo.from) <= Now
                    && new Date(promo.to) >= Now
                    && schedules.includes(promo.schedule_code));

                if (filteredPromotions.length > 0) {
                    for (const item of filteredPromotions) {

                        let actionType = responseActionTypes.data?.filter((at: any) =>
                            at.id == item.id_action_type
                        )[0];

                        let comercialConditionType = responseComercialConditionTypes.data?.filter((cct: any) =>
                            cct.id == item.id_commercial_condition_type
                        )[0];

                        const alertaPromo = {
                            isActive: item.is_active,
                            message: item.description,
                            imageUrl: item.image_url == undefined || item.image_url == "-1" ? "" : item.image_url,
                            id: `${this.promocionPrefijo}${item.id}`,
                            _Id: item.id,
                            priority: PrioridadAlertaPromo.Alta,
                            isForBeach: true,
                            tipoAlerta: TipoAlertaPromo.Promo,
                            descripcionCorta: item.short_description,
                            entidad: item.company_owner_promotion.name,
                            accion: actionType?.description,
                            montoDescuento: comercialConditionType?.description,
                            tipoPromocion: item.id_promotion_type
                        };

                        alertasPromos.push(<AlertaPromo>alertaPromo);
                    }
                }

                return new Observable<AlertaPromo[]>((observer) => {
                    observer.next(alertasPromos);
                    observer.complete();
                });
            })
        );
    }

    getAlertaPromoFiltrados(apies: number, area: number): Observable<AlertaPromo[]> {
        const dayOfWeek = new Date().toLocaleString(
            'default', { weekday: 'long' }
        ).toUpperCase();
        const scheduleDate = `${new Date().getHours()}${new Date().getMinutes()}`
        const getMessages = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/fuel_station/${apies}/messages?size=1000`);
        const getArea = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/area_stations?size=1000&search=name:${TipoArea[TipoArea.Playa]}`);
        const getDay = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/days?size=1000&search=code:${dayOfWeek}`);
        const getSchedule = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/schedules?size=1000&search=from<${scheduleDate};to>${scheduleDate}`);
        const getPromos = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/fuel_station/${apies}/promotions?size=1000`);
        const getCustomMessages = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/custom_messages/panel/${apies}`);
        const getActionTypes = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/action_types`);
        const getComercialConditionTypes = super.httpWafDirectGet(`${this.securityType == '2' ? this.ms_promotions_private : this.ms_promotions}/commercial_condition_types`);

        return forkJoin([getMessages, getArea, getDay, getSchedule, getPromos, getCustomMessages, getActionTypes, getComercialConditionTypes]).pipe(
            switchMap(([responseMessages, responseArea, responseDay, responseSchedule, responsePromos, responseCustomMessages, responseActionTypes, responseComercialConditionTypes]) => {
                const alertasPromos: AlertaPromo[] = [];

                let messages = responseMessages.data?.map((x: any) => {
                    return {
                        isActive: x.is_active,
                        message: x.description,
                        imageUrl: x.icon,
                        id: `${this.alertaPrefijo}${x.id}`,
                        isForBeach: true,
                        _Id: x.id,
                        priority: PrioridadAlertaPromo.Alta,
                        tipoAlerta: TipoAlertaPromo.Alerta
                    }
                });

                alertasPromos.push(...messages);

                let Now = new Date();

                let filteredCustomMessages = responseCustomMessages.data?.filter((cm: any) =>
                    this.parseTimeUTC(cm.from) <= Now
                    && this.parseTimeUTC(cm.to) >= Now
                    && cm.active
                );

                if (filteredCustomMessages.length > 0) {
                    for (const cm of filteredCustomMessages) {
                        const alertaPromo = {
                            isActive: cm.active,
                            message: cm.description,
                            imageUrl: cm.icon,
                            id: `${this.mensajePrefijo}${cm.id}`,
                            _Id: cm.id,
                            priority: PrioridadAlertaPromo.Alta,
                            isForBeach: true,
                            tipoAlerta: TipoAlertaPromo.Mensaje
                        };

                        alertasPromos.push(<AlertaPromo>alertaPromo);
                    }
                }

                let day = 0;
                area = responseArea.data[0]?.id;
                day = responseDay.data[0]?.id;

                let schedules = responseSchedule.data.map((x: any) => {
                    return x.schedule_code
                });

                let filteredPromotions = responsePromos.data?.filter((promo: any) => promo.area_stations.includes(area)
                    && promo.days.includes(day)
                    && new Date(promo.from) <= Now
                    && new Date(promo.to) >= Now
                    && schedules.includes(promo.schedule_code));

                if (filteredPromotions.length > 0) {
                    for (const item of filteredPromotions) {

                        let actionType = responseActionTypes.data?.filter((at: any) =>
                            at.id == item.id_action_type
                        )[0];

                        let comercialConditionType = responseComercialConditionTypes.data?.filter((cct: any) =>
                            cct.id == item.id_commercial_condition_type
                        )[0];

                        const alertaPromo = {
                            isActive: item.is_active,
                            message: item.description,
                            imageUrl: item.image_url == undefined || item.image_url == "-1" ? "" : item.image_url,
                            id: `${this.promocionPrefijo}${item.id}`,
                            _Id: item.id,
                            priority: PrioridadAlertaPromo.Alta,
                            isForBeach: true,
                            tipoAlerta: TipoAlertaPromo.Promo,
                            descripcionCorta: item.short_description,
                            entidad: item.company_owner_promotion.name,
                            accion: actionType?.description,
                            montoDescuento: comercialConditionType?.description,
                            tipoPromocion: item.id_promotion_type
                        };

                        alertasPromos.push(<AlertaPromo>alertaPromo);
                    }
                }

                console.log("alertasPromos", alertasPromos);
                return new Observable<AlertaPromo[]>((observer) => {
                    observer.next(alertasPromos);
                    observer.complete();
                });
            })
        );
    }

    editarAlertaPromo(data: EditAlertPromoDto): Observable<boolean> {

        let filteredMessages = data.items.filter((message: any) => message.tipoAlerta == TipoAlertaPromo.Alerta);
        let filteredPromos = data.items.filter((message: any) => message.tipoAlerta == TipoAlertaPromo.Promo);
        const messageObservables: Observable<any>[] = [];

        if (filteredMessages.length > 0) {

            let messages = filteredMessages.map((x: any) => { return { id: x._Id, is_active: x.isActive } });
            messages.forEach((message) => {
                messageObservables.push(super.httpWafPatch(`${this.ms_promotions}/fuel_station`, message, `${data.apies}/messages`));
            });
        }

        if (filteredPromos.length > 0) {
            let promos = filteredPromos.map((x: any) => { return { id: x._Id, is_active: x.isActive } });
            promos.forEach((promo) => {
                messageObservables.push(super.httpWafPatch(`${this.ms_promotions}/fuel_station`, promo, `${data.apies}/promotions`));
            });
        }

        if (messageObservables.length > 0) {
            return forkJoin(messageObservables).pipe(map((results: any) => {
                return true;
            }));
        }
        else {
            return of(false);
        }
    }

    getMockAlertaPromo(type: number): Promise<AlertaPromo[]> {
        var result: AlertaPromo[] = [];

        return new Promise<AlertaPromo[]>((resolve, reject) => {
            resolve(result);
        });
    }

    showErrorResponse(error: any) {
        this.helperService.errorMessage = error;
        this.helperService.showErrorMessage = true;
        console.warn(error)
        setTimeout(() => {
            this.helperService.errorMessage = "";
            this.helperService.showErrorMessage = false;
        }, 9000)
    }

    public getCustomMessage(apies: number): Observable<EntityResponse> {
        return super.httpWafDirectGetEntityResponse(`${this.ms_promotions}/custom_messages/${apies}/JPV`).pipe(
            map(resp => {
                return <EntityResponse>resp
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public postCustomMessage(data: CustomMessage): Observable<EntityResponse> {
        return super.httpWafPost(`${this.ms_promotions}/custom_messages`, data).pipe(
            map((response) => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public putCustomMessage(data: CustomMessage): Observable<EntityResponse> {
        return super.httpWafPut(`${this.ms_promotions}/custom_messages`, data, data.id).pipe(
            map((response) => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public parseTimeUTC(t: any) {
        return t.includes("Z") ? new Date(`${t}`) : new Date(`${t}Z`);
    }
}

