import { Injectable } from "@angular/core";
import { Concentrator } from "src/app/models/abm-panels/concentrator.model";
import { Panel } from "src/app/models/abm-panels/panel.model";
import { HelperService } from "./helper.service";
import { Observable, catchError, map } from "rxjs";
import { EntityResponse } from "src/app/models/entity-response.model";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "./abstract.service";

@Injectable({
    providedIn: 'root'
})
export class AbmPanelsService extends AbstractService {

    public _concentrator: Concentrator = new Concentrator();
    public _panels: Panel[] = [];

    // este panel estara siendo seteado desde la 
    // lista de elementos ya que se pasa a conectar por el servicio
    public _panelFocus: Panel;
    public _isEditing: boolean = false;
    private ms_panels: string = "/mspanels/api";

    constructor(protected override http: HttpClient, private helperService: HelperService) {
        super(http)
        this._panelFocus = new Panel();
    }

    public setEditing(value: boolean): void {
        this._isEditing = value;
    }

    public getEditing(): boolean {
        return this._isEditing;
    }

    public getConcentrator(apies: number, page: number, size: number): Observable<EntityResponse> {
        return super.httpWafGet(`${this.ms_panels}/concentrators`, `fuel_station_id:${apies}`, 'id:desc', page, size).pipe(
            map(response => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    public setConcentrator(id: number, data: Concentrator): Observable<EntityResponse> {
        if (id > 0 && data.id == id) {
            return super.httpWafPut(`${this.ms_panels}/concentrators`, data, id).pipe(
                map((response) => {
                    return <EntityResponse>response;
                }),
                catchError(e => {
                    return this.helperService.handleError(e)
                })
            );
        } else {
            return super.httpWafPost(`${this.ms_panels}/concentrators`, data).pipe(
                map((response) => {
                    return <EntityResponse>response;
                }),
                catchError(e => {
                    return this.helperService.handleError(e)
                })
            );
        }
    }

    getPanels(concentrator_id: number, page: number, size: number): Observable<EntityResponse> {
        return super.httpWafGet(`${this.ms_panels}/panels`, `concentrator_id:${concentrator_id}`, 'id:asc', page, size).pipe(
            map(response => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    setPanel(data: Panel, id: number): Observable<EntityResponse> {
        if (id > 0 && data.id == id) {
            return super.httpWafPut(`${this.ms_panels}/panels`, data, id).pipe(
                map((response) => {
                    return <EntityResponse>response;
                }),
                catchError(e => {
                    return this.helperService.handleError(e)
                })
            );
        } else {
            return super.httpWafPost(`${this.ms_panels}/panels`, data).pipe(
                map((response) => {
                    return <EntityResponse>response.data;
                }),
                catchError(e => {
                    return this.helperService.handleError(e)
                })
            );
        }
    }

    deletePanel(): Observable<boolean> {
        return super.httpWafDelete(`${this.ms_panels}/panels/${this._panelFocus.id}`).pipe(
            map((response) => {
                console.log(response)
                this._panelFocus = new Panel();
                return true;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }
}