export
	class AlertaEditable implements IArea {
	public id: string;
	public _Id: number;
	public isForBeach: boolean;
	public isForStore: boolean;
	public isForBox: boolean;
	public isActive: boolean;
	public priority: PrioridadAlertaPromo;
	public tipoAlerta: TipoAlertaPromo;
}
export
	class AlertaPromo extends AlertaEditable implements IArea {
	public message: string;
	public imageUrl: string;
	public descripcionCorta: string;
	public accion: string;
	public montoDescuento: string;
	public tipoPromocion: string;
	public tipoDescuento: string;
	public entidad: string;
}
export
	class ClienteMetadata {
	public nombreSocio: string;
	public kms: string;
	public fechaCreacion: any;
	public isExpired: boolean;
	public id: number;
	public code: string;
}
export
	interface IArea {
	isForBeach: boolean;
	isForStore: boolean;
	isForBox: boolean;
}
export
	enum PrioridadAlertaPromo {
	Alta = 1,
	Media = 2,
	Baja = 3,
}
export
	class Station {
	public apies: number;
	public apiesText: string;
	public name: string;
	public sortOrder: number;
}
export
	enum TipoAlertaPromo {
	Alerta = 1,
	Promo = 2,
	Mensaje = 3,
}
export
	enum TipoArea {
	Playa = 0,
	Tienda = 1,
	Box = 2,
}
export
	enum Placeholder {
	Left = 0,
	Right = 1,
}
export
	class Surtidor implements IPathCode, IEstado {
	public id: string;
	public name: string;
	public placeholder: Placeholder;
	public isEnable: boolean;
	public code: string;
	public status: string;
}
export
	class Lector implements IPathCode, IEstado, IEmbajador {
	public id: string;
	public placeholder: Placeholder;
	public isEnable: boolean;
	public code: string;
	public status: string;
	public embajador: string;
	public action: string;
	public idCorto: string;
}
export
	class Cliente implements IPathCode, IEstado {
	public id: string;
	public placeholder: Placeholder;
	public isEnable: boolean;
	public code: string;
	public status: string;
	public name: string;
	public content: string;
	public id_Corto: string;
}
export
	class GatewayClientOptions {
	public port: number;
	public host: string;
	public hostname: string;
	public path: string;
	public protocol: string;
	public username: string;
	public password: string;
	public keepalive: number;
}
export
	class Gateway {
	public brokerUrl: string;
	public options: GatewayClientOptions;
}
export
	interface IPathCode {
	code: string;
}
export
	interface IEstado extends IPathCode {
	status: string;
}
export
	interface IEmbajador extends IPathCode {
	embajador: string;
	idCorto: string;
}
export
	class Workstation {
	public apies: number;
	public name: string;
	public id: string;
	public area: TipoArea;
	public layout: Placeholder[];
	public surtidores: Surtidor[];
	public lectores: Lector[];
	public clientes: Cliente[];
	public gateway: Gateway;
}
export
	class EditAlertPromoDto {
	public apies: number;
	public items: AlertaEditable[];
}
