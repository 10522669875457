import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AbmPanelsService } from "src/app/services/panel.service";
import { SecurityService } from "src/app/services/security.service";
import { Concentrator } from "src/app/models/abm-panels/concentrator.model";
import { AlertMessage } from "src/app/models/entity-response.model";

@Component({
    selector: 'abm-panel-concentrator',
    templateUrl: './abm-panel-concentrator.component.html',
    styleUrls: ['./abm-panel-concentrator.component.scss'],
})

export class AbmPanelConcentratorComponent implements OnInit {

    public isLoadData: boolean;
    private fuel_station_id: number;
    public alert: AlertMessage;

    public concentrator: Concentrator = new Concentrator();
    public backup_concentrator: Concentrator = new Concentrator();
    public isEditConcentrator: boolean = false;
    public isEditing: boolean = false;
    public _panelService: AbmPanelsService;

    @ViewChild("concentratorForm")
    concentratorForm: NgForm;

    constructor(private router: Router,
        private securityService: SecurityService,
        private panelService: AbmPanelsService
    ) {
        this.fuel_station_id = 0;
        this.alert = new AlertMessage();
        this.isLoadData = false;
        this._panelService = this.panelService;
    }

    ngOnInit(): void {

        this.fuel_station_id = this.securityService.getApies();
        if (this.fuel_station_id <= 0) this.router.navigate(['/home']);
        this.getData();
    }

    // Get initial data 
    getData() {
        this.panelService.getConcentrator(this.fuel_station_id, 1, 1)
            .subscribe({
                next: (result) => {
                    if (result.data.length > 0) {
                        this.concentrator = <Concentrator>result.data[0];
                        this.backup_concentrator = JSON.parse(JSON.stringify(result.data[0]));
                    }
                    this.isLoadData = true;
                },
                error: (err) => console.error(err)
            });
    }

    public onSubmit() {
        this.concentrator.fuel_station_id = this.securityService.getApies();
        var t = this.panelService.setConcentrator(this.concentrator.id, this.concentrator)
            .subscribe((result) => {
                console.log(result);
                this.concentrator = result.data;
                this.backup_concentrator = JSON.parse(JSON.stringify(result.data));
                this.cancelEditConcentrator();
            })
    }

    public editConcentrator() {
        this.isEditConcentrator = true;
        console.log(this.panelService._isEditing)
        this.panelService.setEditing(true);
        console.log(this.panelService._isEditing)
    }

    public cancelEditConcentrator() {
        this.isEditConcentrator = false;
        this.panelService.setEditing(false);
        this.concentrator = JSON.parse(JSON.stringify(this.backup_concentrator));
        console.log(this.backup_concentrator)
        console.log(!this.isEditConcentrator)
        console.log(!this.panelService.getEditing())
    }
}