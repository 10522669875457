<div *ngIf="!isLoadData; else elseBlock">
    <img src="../../../assets/images/loader-gris.svg" /> Cargando datos...
</div>
<ng-template #elseBlock>
    <div id="live-view" *ngIf="isLiveView">
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <b>Previsualizaci&oacute;n</b>
                    <button (click)="editView()" class="float-end margin-bottom-10 btn btn-sm btn-info">
                        EDITAR
                    </button>
                </h6>
            </div>
        </div>
        <section class="scroll-preview">
            <div class="row zoom-panel-view p-0 m-0">
                <div class="static-panel row p-0 m-0">
                    <div class="col-3 surtidor p-0">
                        <div class="surtidor-left title">
                            <h1 class="float-start">A</h1>
                            <img src="../../../assets/images/icono-surtidor-inhabilitado.png" alt="Alternate Text"
                                class="float-end" />
                        </div>
                        <div class="surtidor-left__content">
                        </div>
                    </div>
                    <div class="col-9 surtidor p-0">
                        <div class="surtidor-right title">
                            <img src="../../../assets/images/icono-surtidor-disponible-despacho.png"
                                alt="Alternate Text" class="float-start" />
                            <h1 class="float-end">B</h1>
                        </div>
                        <div class="surtidor-right__content">
                            <panel-precompra-container></panel-precompra-container>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div id="edit-view" *ngIf="isEditView">
        <section>
            <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
            <div col-md-12>
                <div class="row">
                    <div class="col-md-12">
                        <h6>
                            <b>Edici&oacute;n</b>
                            <button (click)="previsualizerView()"
                                class="btn btn-info btn-sm text-uppercase float-end margin-bottom-10 margin-left-10">Previsualizar</button>
                            <button
                                class="btn btn-outline-secondary btn-sm btn-descartar text-uppercase float-end margin-bottom-10"
                                data-bs-toggle="modal" data-bs-target="#preventaModalShowUnsave"
                                data-backdrop="static">Descartar</button>
                        </h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <div class="switch-content">
                            <h6>
                                <label class="switch">
                                    <input type="checkbox" [checked]="dataConfig.active" name="statusTimeZone"
                                        value="{{dataConfig.active}}" (click)="changeStatusTimeZone(dataConfig.active)">
                                    <span class="slider round"></span>
                                </label>
                                El sistema de Pre Compra Full se encuentra
                                <b *ngIf="dataConfig.active">activado</b>
                                <b *ngIf="!dataConfig.active">desactivado</b>
                            </h6>
                            <span
                                [ngClass]="{'display-block':true,'configureTimeZone':true, 'cursor-default':!dataConfig.active}"
                                data-bs-toggle="modal"
                                [attr.data-bs-target]="dataConfig.active ? '#preventaModalShow' : null"
                                data-bs-backdrop="static"> <i class="bi bi-gear-fill"></i> Configuraci&oacute;n de
                                franja horaria</span>
                        </div>
                    </div>
                    <div class="col-md-12 datepicker-content">
                        <div id="preventaModalShow" class="modal fade" tabindex="-1" role="dialog"
                            aria-labelledby="preventaModalShow" aria-hidden="true"
                            style="background:rgba(0, 0, 0, 0.88);">
                            <div class="modal-dialog modal-xl" role="document" style="top:30%">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <div class="row">
                                            <div class="col-12">
                                                <alert *ngIf="!isValidTime" type="danger" class="col-md-12">Verifique
                                                    que el
                                                    formato de la hora sea correcto.</alert>
                                            </div>
                                            <div class="col-12">
                                                <h6>Franja horaria</h6>
                                            </div>
                                            <div class="col-12">
                                                <p>Selecciona los días y honorarios en que la funcionalidad de Pre Compra Full
                                                    debe estar activa normalmente.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-6 days">
                                                <p><b>Días de la semana</b></p>
                                                <span
                                                    [ngClass]="{ 'btn':true, 'btn-info':  day.Active==true, 'btn-white':  day.Active == false }"
                                                    *ngFor="let day of dataConfig.days"
                                                    (click)="UpdateDataConfigDay(day)">{{GetDay(day.Day)}}</span>
                                            </div>
                                            <div class="col-6">
                                                <div class="d-inline-block col-6">
                                                    <p><b>Inicio</b></p>
                                                    <timepicker [(ngModel)]="beginDate"></timepicker>
                                                </div>
                                                <div class="d-inline-block col-6">
                                                    <p><b>Finalizaci&oacute;n</b></p>
                                                    <timepicker [(ngModel)]="endDate"></timepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer center">
                                        <button class="btn btn-default" data-bs-dismiss="modal"
                                            (click)="resetData()">CANCELAR</button>
                                        <button class="btn btn-info"
                                            [attr.data-bs-dismiss]="!isValidTime ? null : 'modal'"
                                            (click)="setDatePickerConfiguration()">GUARDAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-hover table-bordered scoll-container">
                                <thead></thead>
                                <tbody>
                                    <tr *ngFor="let promo of dataPromo">
                                        <td>
                                            <div class="checkbox">
                                                <label [ngClass]="{'cursor-default':!dataConfig.active}">
                                                    <span class="cr" (click)="setPromoDataByClick(promo)"><i
                                                            [ngClass]="{'cr-icon':true,'bi':true, 'bi-check':promo.active, 'combo-disable':!dataConfig.active}"></i></span>
                                                    {{promo.description}}
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div id="previsualizer-view" *ngIf="isPrevisualizerView">
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <b>Previsualizaci&oacute;n</b>
                    <button class="float-end margin-bottom-10 margin-left-10 btn btn-sm btn-success"
                        (click)="publishView()">PUBLICAR PANEL</button>
                    <button
                        class="btn btn-outline-secondary btn-sm btn-descartar text-uppercase float-end margin-bottom-10"
                        (click)="editView()">Descartar</button>

                </h6>
            </div>
        </div>
        <div class="row zoom-panel-view p-0 m-0">
            <div class="static-panel row p-0 m-0">
                <div class="col-3 surtidor p-0 surtidor-left__content">
                    <div class="surtidor-left title">
                        <h1 class="float-start">A</h1>
                        <img src="../../../assets/images/icono-surtidor-inhabilitado.png" alt="Alternate Text"
                            class="float-end" />
                    </div>
                    <div class="not-content">
                    </div>
                </div>
                <div class="col-9 surtidor p-0">
                    <div class="surtidor-right title">
                        <img src="../../../assets/images/icono-surtidor-disponible-despacho.png" alt="Alternate Text"
                            class="float-start" />
                        <h1 class="float-end">B</h1>
                    </div>
                    <div class="precompra-container__combos surtidor-right__content">
                        <div class="col-md-12" [class]="'precompra-container'">
                            <panel-precompra-combo *ngFor="let item of dataPromoPrevisualizer; let i = index"
                                [class]="'precompra-container__combo'" [position]="i"
                                [count]="dataPromoPrevisualizer.length" [combo]="item">
                            </panel-precompra-combo>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="preventaModalShowUnsave" class="modal fade in" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title text-uppercase">&#161;Cuidado!</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center">
                    Vas a salir sin publicar las novedades en el panel<br />
                    &#191;Qu&#233;res publicarlas ahora?
                </div>
                <div class="modal-footer d-sm-block">
                    <button type="button" class="btn btn-danger btn-sm btn-editar float-start text-uppercase"
                        (click)="unsaveChanges()" data-bs-dismiss="modal">Descartar cambios</button>
                    <button type="button" class="btn btn-success btn-sm btn-editar float-end text-uppercase"
                        (click)="publishView()" data-bs-dismiss="modal">Publicar panel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>