import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AlertaPromo, TipoAlertaPromo } from "src/app/modules/app.generated.module";

@Component({
    selector: "panel-grid-item-container",
    templateUrl: "./panels-grid-item-container.component.html",
    styleUrls: ["./panels-grid-item-container.component.scss"]
})
export class PanelGridItemContainerComponent implements OnInit, OnChanges {
    // Temporaly input properties
    @Input() offersQuantity: number;
    @Input() alertsQuantity: number;
    @Input() promotionAlertsLastQuantity: number;
    @Input() promotionAlerts: AlertaPromo[];
    public alertasPromosError = false;
    public lastAlertasPromos: number = Date.now();

    alerts: AlertaPromo[];
    promotions: AlertaPromo[];

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filterAlertsAndPromotionsData();
    }

    filterAlertsAndPromotionsData() {
        this.alerts = this.promotionAlerts.filter((item, index, array) => {
            return item.tipoAlerta == TipoAlertaPromo.Alerta;
        });

        this.promotions = this.promotionAlerts.filter((item, index, array) => {
            return item.tipoAlerta == TipoAlertaPromo.Promo;
        });
    }
}