import { Component, Input } from '@angular/core';

@Component({
    selector: 'panel-promotion-item',
    templateUrl: './panel-promotion-item.component.html',
    styleUrls: ['./panel-promotion-item.component.scss']
})
export class PanelPromotionItemComponent {
    @Input() promotion: any;
    @Input() promotionLenght: number;
    @Input() alertsLenght: number;
    @Input() promotionIndex: number;
}