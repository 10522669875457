import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AbmBraceletService } from "src/app/services/bracelet.service";
import { HelperService } from "src/app/services/helper.service";
import { SecurityService } from "src/app/services/security.service";
import { StationBossService } from "src/app/services/station-boss.service";
import { Bracelet } from "src/app/models/abm-bracelet/bracelet.model";
import { Seller } from "src/app/models/abm-bracelet/seller.model";
import { AlertMessage, EntityResponse } from "src/app/models/entity-response.model";

@Component({
    selector: 'abm-pulseras',
    templateUrl: './abm-bracelets.component.html',
    styleUrls: ['./abm-bracelets.component.scss'],
})
export class AbmBraceletsComponent implements OnInit {
    private next_page_number: number;
    private has_next_page: boolean;
    private pumpList: Bracelet[];
    private pumpListTemp: Bracelet[] = [];
    private pumpReaderListTemp: Bracelet[] = [];
    public elementToCreate = new Bracelet();
    public elementToUpdate = new Bracelet();
    public isLoadData: boolean;
    public isShowEditModal: boolean;
    public isLoadDataBracelets: boolean = false;
    // Using 
    public alert: AlertMessage;
    private fuel_station_id: number;
    private page: number = 1;
    private size = 100;
    public sellerSelectedInput: string = "";
    public sellers: Seller[];
    public isSellerEmpty: boolean;
    public isSellerLoading: boolean;
    public braceletReaderList: Bracelet[];

    constructor(private abmBraceletsService: AbmBraceletService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private securityService: SecurityService,
        private stationBossService: StationBossService,
        private helper: HelperService) {
        this.fuel_station_id = 0;
        this.pumpList = [];
        this.braceletReaderList = [];
        this.isLoadData = false;
        this.isShowEditModal = true;
        this.alert = new AlertMessage();
        // Using
        this.isSellerEmpty = true;
        this.isSellerLoading = true;
        this.sellers = [];
    }

    ngOnInit(): void {
        var self = this;
        let _apies = this.securityService.getApies();
        if (_apies <= 0) this.router.navigate(['/home'])
        this.fuel_station_id = _apies;

        this.getData();
    }

    // Get initial data 
    getData() {
        var str = "" + this.fuel_station_id
        var pad = "00000"
        var ans = pad.substring(0, pad.length - str.length) + str

        console.warn(ans);

        this.stationBossService.getAllSellers(ans).subscribe(res => {
            this.isSellerLoading = false;
            this.sellers = res.map(x => { x.name = `${x.id} - ${x.name}`; return x; });
            if (this.sellers.length > 0) this.isSellerEmpty = false;
            else this.isSellerEmpty = true;
        }, err => console.error(err))

        this.abmBraceletsService.getBracelet(this.fuel_station_id.toString(), this.page, this.size).subscribe(
            res => {
                this.has_next_page = res.has_next_page;
                this.next_page_number = res.next_page_number;
                this.braceletReaderList = <Bracelet[]>res.data;
                this.pumpReaderListTemp = JSON.parse(JSON.stringify(this.braceletReaderList));
                this.isLoadData = true;
            }, err => console.error(err))
    }

    // Add link
    addLink() {
        if (this.elementToCreate.seller_short_id == "" || this.elementToCreate.seller_dni == "" || this.elementToCreate.bracelet_code == "") {
            return this.alert.set("", "danger", "Los datos insertador o encontrados en el vendedor no son correctos", true, 6000);
        }

        let d = this.braceletReaderList.filter(x => { return x.bracelet_code.toUpperCase() == this.elementToCreate.bracelet_code.toUpperCase() || x.seller_dni.toUpperCase() == this.elementToCreate.seller_dni.toUpperCase() })
        console.log(d)
        if (d.length > 0)
            return this.alert.set("", "danger", "Alguno de los datos ya estan insertados, favor de validar", true, 6000);

        this.addRegister();
    }

    addRegister() {
        var p = new Bracelet();
        p.bracelet_code = this.elementToCreate.bracelet_code;
        p.seller_dni = this.elementToCreate.seller_dni;
        p.seller_short_id = this.elementToCreate.seller_short_id;
        p.seller_name = this.elementToCreate.seller_name.split(' - ')[1];
        p.fuel_station_id = this.fuel_station_id;

        this.resetProperties();
        this.abmBraceletsService.addRegister(p)
            .subscribe((result: any) => {

                let data = (<EntityResponse>result).data;

                if (data.errors == null) {
                    this.next_page_number = 1;
                    this.braceletReaderList = [];
                    this.getPumpReaders();
                    this.alert.set("", "success", this.helper.getMessage(2100), true, 6000);
                } else {
                    this.alert.set(data.errors[0].error_slag, "danger", this.helper.getMessage(data.errors[0].error_code), true, 6000);
                }
            });
    }

    // Update pump register
    updateRegister() {

        if (this.elementToUpdate.seller_short_id == "" || this.elementToUpdate.seller_dni == "" || this.elementToUpdate.bracelet_code == "") {
            this.cancelUpdate();
            return this.alert.set("", "danger", this.helper.getMessage(2000), true, 5000);
        }

        let d = this.braceletReaderList.filter(x => x.seller_short_id == this.elementToUpdate.seller_short_id)

        if (d.length > 1) {
            this.cancelUpdate();
            return this.alert.set("", "danger", this.helper.getMessage(2200), true, 5000);
        }

        this.updateData();
    }

    // Add or update an element 
    updateData() {
        var p = new Bracelet();
        p.id = this.elementToUpdate.id;
        p.bracelet_code = this.elementToUpdate.bracelet_code;
        p.seller_dni = this.elementToUpdate.seller_dni;
        p.seller_short_id = this.elementToUpdate.seller_short_id;
        p.seller_name = this.elementToUpdate.seller_name.split(' - ')[1]
        p.fuel_station_id = this.fuel_station_id;

        console.log(p)
        this.resetProperties();

        this.abmBraceletsService.updateRegister(p.id, p)
            .subscribe(
                result => {
                    let data = (<EntityResponse>result).data;

                    if (data.errors == null) {

                        this.alert.set("", "success", this.helper.getMessage(2000), true, 5000);
                    } else {
                        this.alert.set(data.errors[0].error_slag, "danger", this.helper.getMessage(data.errors[0].error_code), true, 6000);
                    }

                    this.next_page_number = 1;
                    this.braceletReaderList = [];
                    this.getPumpReaders();

                });
    }

    // Reset properties after add link 
    resetProperties() {
        console.log("reset data;")
        this.elementToCreate.bracelet_code = "";
        this.elementToCreate.seller_dni = "";
        this.elementToCreate.seller_name = "";
        this.elementToUpdate.id = 0;
        this.elementToUpdate.bracelet_code = "";
        this.elementToUpdate.seller_dni = "";
        this.elementToUpdate.seller_name = "";

        this.pumpListTemp = JSON.parse(JSON.stringify(this.pumpList));

        this.sellerSelectedInput = "";
    }

    // Get this list for update data 
    getPumpReaders() {
        this.isLoadDataBracelets = true;
        this.abmBraceletsService.getBracelet(this.fuel_station_id.toString(), this.next_page_number, this.size)
            .subscribe({
                next: (result) => {
                    this.isLoadDataBracelets = false;
                    this.has_next_page = result.has_next_page;
                    this.next_page_number = result.next_page_number;
                    this.braceletReaderList = this.braceletReaderList.concat(<Bracelet[]>result.data);
                    this.pumpReaderListTemp = JSON.parse(JSON.stringify(this.braceletReaderList));
                },
                error: err => console.error(err)
            })
    }

    pumpSelected(data: any) {
        this.elementToCreate.id = data.target.value;
    }

    scrollHandler(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && this.has_next_page) {
            this.has_next_page = false;
            this.getPumpReaders();

            console.log("End");
        }
    }

    // Cancel modal action in update
    cancelUpdate() {
        this.braceletReaderList = JSON.parse(JSON.stringify(this.pumpReaderListTemp));
        this.resetProperties();
    }

    // Delete the link for pump 
    unlinkPumpReader() {

        this.abmBraceletsService.unlink(this.elementToUpdate.id)
            .subscribe((result) => {
                this.resetProperties();
                this.next_page_number = 1;
                this.braceletReaderList = [];
                this.getPumpReaders();
                return this.alert.set("", "success", this.helper.getMessage(2100), true, 5000);
            });
    }

    // Show the modal for action edit
    showEditModal(id: number) {
        this.elementToUpdate = (this.braceletReaderList.filter(x => x.id == id))[0];
        this.elementToUpdate.seller_dni = "";
        if (this.elementToUpdate != null) {
            this.isShowEditModal = true;
        }
    }

    // Show modal for action unlink
    showUnlinkModal(id: number) {

        this.elementToUpdate = (this.braceletReaderList.filter(x => x.id == id))[0];
        if (this.elementToUpdate != null) {
            this.isShowEditModal = false;
        }
        console.log(this.isShowEditModal)
    }

    // Event when seller is selected
    onSelectSeller(data: any) {
        this.elementToCreate.seller_dni = data.item.id;
        this.elementToCreate.seller_name = data.item.name;
        this.elementToCreate.seller_short_id = data.item.short_id
    }

    // Event when seller is selected for update
    onSelectSellerUpdate(data: any) {
        this.elementToUpdate.seller_dni = data.item.id;
        this.elementToUpdate.seller_name = data.item.name;
        this.elementToUpdate.seller_short_id = data.item.short_id
    }
}