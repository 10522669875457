export class Concentrator {
    id: number;
    fuel_station_id: number;
    ip_address: string;
    port: number;

    constructor() {
        this.id = 0;
        this.fuel_station_id = 0;
        this.ip_address = "";
        this.port = 10000;
    }
}