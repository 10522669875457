<header class="grid-header">
    <selected-station></selected-station>
</header>
<main class="grid-main">
    <div>
        <ul class="nav nav-tabs">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#tab-1" href="#"
                    (click)="onChangeCurrentViewName()">
                    <div class="header-tab-content">
                        <i class="glyphicon glyphicon-playa pull-left"></i>
                        <span class="hidden-xs">Panel Playa</span>
                    </div>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-2" href="#">
                    <div class="header-tab-content">
                        <i class="glyphicon glyphicon-mensaje pull-left"></i>
                        <span class="hidden-xs">Mensajes</span>
                    </div>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-3" href="#">
                    <div class="header-tab-content">
                        <i class="glyphicon glyphicon-full pull-left"></i>
                        <span class="hidden-xs">Pre Compra Full</span>
                    </div>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-4" href="#">
                    <div class="header-tab-content">
                        <i class="glyphicon glyphicon-report pull-left"></i>
                        <span class="hidden-xs">Reporte de Ventas V1</span>
                    </div>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-5" href="#">
                    <div class="header-tab-content">
                        <i class="glyphicon glyphicon-report pull-left"></i>
                        <span class="hidden-xs">Reporte de Ventas V2</span>
                    </div>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-6" href="#">
                    <div class="header-tab-content">
                        <i class="glyphicon glyphicon-abm pull-left"></i>
                        <span class="hidden-xs">ABM Lectores</span>

                    </div>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-7" href="#">
                    <div class="header-tab-content">
                        <i class="glyphicon glyphicon-abm-pulseras pull-left"></i>
                        <span class="hidden-xs">ABM Pulseras</span>
                    </div>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-8" href="#">
                    <div class="header-tab-content">
                        <i class="glyphicon glyphicon-abm-panels pull-left"></i>
                        <span class="hidden-xs">ABM Paneles</span>
                    </div>
                </a>
            </li>
        </ul>
        <div class="tab-content col-md-12 px-4">
            <div class="tab-pane active" role="tabpanel" id="tab-1">
                <alerta-promo></alerta-promo>
            </div>
            <div class="tab-pane" role="tabpanel" id="tab-2">
                <custom-message></custom-message>
            </div>
            <div class="tab-pane" role="tabpanel" id="tab-3">
                <preventa></preventa>
            </div>
            <div class="tab-pane" role="tabpanel" id="tab-4">
                <stationBoss></stationBoss>
            </div>
            <div class="tab-pane" role="tabpanel" id="tab-5">
                <sales-report></sales-report>
            </div>
            <div class="tab-pane" role="tabpanel" id="tab-6">
                <abm></abm>
            </div>
            <div class="tab-pane" role="tabpanel" id="tab-7">
                <abm-pulseras></abm-pulseras>
            </div>
            <div class="tab-pane" role="tabpanel" id="tab-8">
                <abm-panel-concentrator></abm-panel-concentrator>
            </div>
        </div>
    </div>
    <alert style="position: fixed;bottom: 10%;left: 2%;" *ngIf="alertMessage.isShow" type="{{alertMessage.type}}"><span
            [innerHtml]="alertMessage.message"></span></alert>
</main>