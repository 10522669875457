import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { AlertaPromo, ClienteMetadata, Lector, Surtidor, TipoArea } from "src/app/modules/app.generated.module";

@Component({
    selector: 'panel-grid',
    templateUrl: './panel-grid.component.html',
    styleUrls: ['./panel-grid.component.scss',
        './alerta-promo.component.scss',
        '../sass/grid-layout.scss',
        '../sass/ie_grid_fix.scss'],
    animations: [
        trigger('expandedAnimationLeft', [
            state('expanded', style({
                width: '75%'
            })),
            transition('expanded => normal', animate('600ms ease-out')),
            transition('normal => expanded', animate('1000ms ease-in'))
        ]),
        trigger('expandedAnimationRight', [
            state('expanded', style({
                width: '75%'
            })),
            transition('expanded => normal', animate('600ms ease-out')),
            transition('normal => expanded', animate('1000ms ease-in'))
        ]),
        trigger('expandedAnimationCenter', [
            state('expanded', style({
                opacity: 0.5
            })),
            state('normal', style({
                opacity: 1
            })),
            transition('expanded => normal', animate('600ms ease-out')),
            transition('normal => expanded', animate('1000ms ease-in'))
        ])
    ]
})
export class PanelGridComponent implements OnInit {

    showTopAndBottomDivs: boolean = true;

    toggleTopAndBottomDivs() {
        this.showTopAndBottomDivs = !this.showTopAndBottomDivs;
    }

    aExpandedRight: boolean = false;
    aExpandedLeft: boolean = false;
    aExpandedCenter: boolean = false;

    get stateExpandedRight() {
        return this.aExpandedRight ? 'expanded' : 'normal';
    }

    get stateExpandedLeft() {
        return this.aExpandedLeft ? 'expanded' : 'normal';
    }

    get stateExpandedCenter() {
        return this.aExpandedCenter ? 'expanded' : 'normal';
    }

    toggleAnimationRight(isExpanded: boolean) {
        this.aExpandedRight = isExpanded;
        this.aExpandedCenter = isExpanded;
    }

    toggleAnimationLeft(isExpanded: boolean) {
        this.aExpandedLeft = isExpanded;
        this.aExpandedCenter = isExpanded;
    }
    rightClass: string = 'right';
    leftClass: string = 'left';
    @Input() filtroArea: TipoArea;
    @Input() detectIE: number;
    @Input() isPanelView: boolean;
    @Input() surtidor_left: Surtidor;
    @Input() dataClienteLeft: ClienteMetadata;
    @Input() clienteErrorLeft: boolean;
    @Input() isDisabled_left_column: boolean;
    @Input() lector_left: Lector;
    @Input() surtidor_right: Surtidor;
    @Input() dataClienteRight: ClienteMetadata;
    @Input() clienteErrorRight: boolean;
    @Input() isDisabled_right_column: boolean;
    @Input() lector_right: Lector;
    @Input() isLive: boolean;
    @Input() mqttError: boolean;
    @Input() lastMqttMessage: number;
    @Input() lastAlertasPromos: number;
    @Input() lastClienteLeft: number;
    @Input() lastClienteRight: number;
    @Input() alertasPromosError: boolean;
    @Input() promos: AlertaPromo[];
    @Input() mensajes: AlertaPromo[];
    @Input() alertas: AlertaPromo[];
    @Input() isDebug: boolean;
    @Input() cantidadOfertas: number;
    @Input() cantidadAlertas: number;
    @Input() cantidadMensajes: number;

    ngOnInit() {

    }
}