<div *ngIf="!isLoadData; else elseBlock">
    <img src="../../../assets/images/loader-gris.svg" /> Cargando datos...
</div>
<ng-template #elseBlock>
    <section>
        <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <b>Descarga reporte de Ventas para la estaci&oacute;n: </b> <label class="d-inline">{{stationsFinder[0].name}}</label>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-2 col-md-3 col-lg-3"></div>
            <div class="col-xs-12 col-sm-8 col-md-6 col-lg-6 text-center">
                <div class="form-group text-start">
                    <label class="fw-bold">Vendedor: <img *ngIf="isloadingSeller" src="../../../assets/images/loader-gris.svg"
                            width="20" /></label>
                    <input [readonly]="isSellerEmpty" [(ngModel)]="sellerSelectedInput" [typeahead]="sellers"
                        typeaheadOptionField="name" (typeaheadOnSelect)="onSelectSeller($event)" class="form-control">
                    <small>Podes descargar el de todos los vendedores dejando el campo vacio</small>
                </div>
                <div class="row">
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <label class="fw-bold">Fecha y Hora de inicio</label>
                        <div class="form-group">
                            <div class="input-group" id="dateTimeBeginDate">
                                <input type="text" class="form-control" bsDatepicker #dpBegin="bsDatepicker"
                                    [bsValue]="beginDate"
                                    value="{{ beginDate | date:'dd-MM-yyyy' }} {{ beginDate | date:'shortTime' }}"
                                    [outsideClick]="true"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY hh:mm a', containerClass: 'theme-blue'  }"
                                    (onHidden)="showTimeBegin()" (bsValueChange)="setDateBegin($event)">
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="dpBegin.show()"
                                        [attr.aria-expanded]="dpBegin.isOpen"><i
                                            class="bi bi-calendar-event-fill"></i></button>
                                </div>
                            </div>
                            <div [ngClass]="{'timerContainer':true, 'timeEndDate':true}"
                                [ngStyle]="{'display': isHideTimerBegin?'none':'block'}">
                                <timepicker [(ngModel)]="beginDate"></timepicker>
                                <button class="btn btn-info btn-sm" (click)="hideTimeBegin(beginDate)"><i
                                        class="bi bi-check-lg"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 text-start">
                        <label class="fw-bold">Fecha y Hora de finalizaci&oacute;n</label>
                        <div class="form-group">
                            <div class="input-group" id="dateTimeBeginDate">
                                <input type="text" class="form-control" bsDatepicker #dpEnd="bsDatepicker"
                                    [bsValue]="endDate"
                                    value="{{ endDate | date:'dd-MM-yyyy' }} {{ endDate | date:'shortTime' }}"
                                    [outsideClick]="true" [minDate]="beginDate" (onHidden)="showTimeEnd()"
                                    (bsValueChange)="setDateEnd($event)"
                                    [bsConfig]="{ containerClass:'theme-blue', dateInputFormat: 'DD-MM-YYYY hh:mm a'  }">
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="dpEnd.show()"
                                        [attr.aria-expanded]="dpEnd.isOpen"><i
                                            class="bi bi-calendar-event-fill"></i></button>
                                </div>
                            </div>
                            <div [ngClass]="{'timerContainer':true, 'timeEndDate':true}"
                                [ngStyle]="{'display': isHideTimerEnd?'none':'block'}">
                                <timepicker [(ngModel)]="endDate"></timepicker>
                                <div class="input-group-btn">
                                    <button class="btn btn-info" (click)="hideTimeEnd(endDate)"><i
                                            class="bi bi-check-lg"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-4 justify-content-center">
                    <div class="col-auto">
                        <button class="btn btn-info" (click)="downloadReport()">DESCARGAR REPORTE</button>
                    </div>
                </div>
            </div>
            <div class="col-xs-1 col-sm-2 col-md-3 col-lg-3"></div>
        </div>
    </section>
</ng-template>