import { Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http"
import { JwtHelperService } from '@auth0/angular-jwt';
import { YpfToken, SecurityType } from "src/app/models/security.model";
import { EntityResponse } from "src/app/models/entity-response.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AbstractService } from "./abstract.service";

@Injectable({
    providedIn: 'root'
})
export class SecurityService extends AbstractService implements OnInit {

    private jwtHelper: JwtHelperService = new JwtHelperService();
    private ypfToken: YpfToken;
    private apies: number;
    private ms_users: string = "/msusers/api"

    constructor(protected override http: HttpClient) {
        super(http)
        this.ypfToken = new YpfToken();
        this.apies = 0;
    }

    ngOnInit() { }

    refresh(): Promise<EntityResponse> {
        return Promise.resolve<EntityResponse>(new EntityResponse());
    }

    public setApies(fuel_station_id: number) {
        this.apies = fuel_station_id;
    }

    public getApies(): number {
        if (this.apies > 0) {
            return this.apies;
        } else {

            var token = sessionStorage.getItem('ypfToken')
            if (token == null) return 0;

            this.ypfToken = this.jwtHelper.decodeToken(token) || new YpfToken();
            console.log(this.ypfToken)
            this.apies = parseInt(this.ypfToken.fuel_station_id);
            return this.apies
        }
    }

    public setToken(token: string) {
        this.ypfToken = this.jwtHelper.decodeToken(token) || new YpfToken();
        console.log(this.ypfToken.fuel_station_id);
        sessionStorage.setItem('ypfToken', token)
        sessionStorage.setItem('_stype', SecurityType.jtw.toString())
    }

    public setApiKey(apiKey: string) {
        sessionStorage.setItem('ypfToken', apiKey)
        sessionStorage.setItem('_stype', SecurityType.apiKey.toString())
    }

    public getUrlToRedirect() {
        return 'panels/apies/' + this.ypfToken.fuel_station_id;
    }

    public updateToken(): Observable<boolean> {

        console.warn("refreshing token")
        let body: string = '';
        let tokenEndPointUrl = this.ms_users + "/sessions/refresh-token";

        let securityType: string = <string>sessionStorage.getItem('_stype');

        return super.httpWafPost(tokenEndPointUrl, body)
            .pipe(
                map((response) => {
                    if ((<EntityResponse>response).data !== 'undefined') {
                        sessionStorage.setItem("ypfToken", (<EntityResponse>response).data.token);
                        sessionStorage.setItem("_stype", securityType);
                        return true;
                    }
                    else {
                        return false;
                    }
                }));
    }
}