<div
  [ngClass]="{'surtidor-cliente': cliente.nombreSocio, 'surtidor' : !cliente.nombreSocio, 'surtidor-no-disponible' : (surtidor.status == 'Error' && !mqttError) }"
  class="surtidor-{{surtidor.status}}">
  <div class="surtidor-numero-container">
    <div [ngClass]="['surtidor-titulo','surtidor-titulo-' + surtidor.status]">SURTIDOR</div>
    <div [ngClass]="['surtidor-numero','surtidor-numero-' + surtidor.status]">{{surtidor.name}}</div>
  </div>
  <div *ngIf="surtidor.status == 'Available' || surtidor.status == 'any'">
    <img src="../../../assets/images/icon-standby.svg" />
  </div>
  <div *ngIf="surtidor.status == 'Authorized'">
    <img src="../../../assets/images/icon-habilitado.svg" />
  </div>
  <div *ngIf="surtidor.status == 'Dispatching'">
    <svg class="animated-svg" width="128" height="128" viewBox="0 0 128 128" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="icon - operando loader">
        <g id="Loader">
          <circle id="Ellipse_7" cx="63.917" cy="63.917" r="59.417" stroke="#253754" stroke-width="8" />
          <path id="Ellipse_8"
            d="M118.878 86.495C121.842 79.2775 123.357 71.5467 123.334 63.744C123.311 55.9413 121.752 48.2195 118.745 41.0194C115.738 33.8193 111.342 27.282 105.809 21.7808C100.275 16.2795 93.7126 11.922 86.4951 8.95703"
            stroke="white" stroke-width="8" stroke-linecap="round" />
          <g id="Group 4">
            <path id="Vector"
              d="M65.3861 35.914L64.6079 34.8906L63.8098 35.914C63.8098 35.914 47.1689 59.0275 47.1689 73.9924C47.1689 79.6887 49.6232 83.9175 52.6361 86.9684C55.8884 90.251 60.2382 91.9116 64.5879 91.9309C68.9577 91.9116 73.3074 90.251 76.5598 86.9684C79.5727 83.9175 82.0269 79.6887 82.0269 73.9924C82.0269 59.0275 65.3861 35.914 65.3861 35.914Z"
              fill="#253754" />
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div *ngIf="surtidor.status == 'End'" [ngClass]="{'success-cliente': cliente.nombreSocio}">
    <img src="../../../assets/images/icon-success.svg" />
  </div>
  <div *ngIf="mqttError">
    <img src="../../../assets/images/icon-nowifi.svg" />
  </div>
  <div *ngIf="surtidor.status == 'Error' && !mqttError">
    <img src="../../../assets/images/icon-error.svg" />
  </div>
  <div *ngIf="cliente.nombreSocio" class="cliente-container">
    <div class="icono-imagen">
      <img width="110px" height="90px" src="../../../assets/images/icon-app.svg" />
    </div>
    <div class="content-right">
      <div class="socio-container">
        <div class="socio">
          <div class="nombre-socio">
            {{cliente.nombreSocio}}</div>
        </div>
      </div>
      <div class="puntos-kms-container">
        <div class="puntos-container"><span class="span-mis">Mis</span><span class="span-puntos">
            Puntos</span></div>
        <div class="kms-container">
          {{cliente.kms}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="mqttError" class="container-error">
    <div class="titulo-error">Panel sin <br />información</div>
    <div class="descripcion-error"><span>No es
        posible mostrar los <br />datos en este momento, <br />pero </span><span style="font-weight: 700;">aún
        podés operar.</span></div>
  </div>
  <div *ngIf="surtidor.status == 'Error' && !mqttError" class="container-error">
    <div class="titulo-error">Surtidor no <br />disponible</div>
    <div class="descripcion-error"><span style="font-weight: 700;">Esperá unos minutos</span><br /><span>Si el problema
        persiste <br />levantá una incidencia.</span></div>
  </div>
</div>