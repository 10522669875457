export class ResponseContainer {
    public data: any;
    public total: number;
    public page: number;
    public size: number;
    public total_pages: number;
    public has_previous_page: boolean;
    public has_next_page: boolean;
    public next_page_number: number;
    public previous_page_number: number;
    public search: any;
    public time_response: number;
    public errors: Errorss[];
}

/** Errors from server */
export class Errorss {
    public error_code: number;
    public error_slag: string;
    public error_description: string;
}

/** Entity structure for response from server */
export class EntityResponse {
    public meta: Meta;
    public data: any;
    public token: string;
    public status: Status;
    public errors: Errors;
    public has_next_page: boolean;
    public next_page_number: number;

    constructor() {
        this.meta = new Meta();
        this.token = "n/a";
        this.status = Status.success;
        this.errors = new Errors();
        this.has_next_page = false;
        this.next_page_number = 1;
    }
}

/** Meta data */
export class Meta {
    requestTime: Date;
    apiVersion: string;
    results: number;

    constructor() {
        this.apiVersion = "n/a";
        this.requestTime = new Date();
        this.results = 0;
    }
}

/** Errors from server */
export class Errors {
    code: number;
    key: string;
    value: string;
    error_code: number;
    error_slag: string;
    error_description: string;

    constructor() {
        this.code = 0;
        this.key = "n/a";
        this.value = "n/a";
    }
}

/** Status response from server */
export enum Status {
    success = "Success",
    error = "Error"
}

/** Alert for web interface */
export class AlertMessage{
    name: string;
    isShow: boolean;
    type: string;
    message: string;
    private myTimeOut: any;


    constructor() {
        this.name = "";
        this.isShow = false;
        this.type = "info";
        this.message = "Info data";
    }

    public reset() {
        clearTimeout(this.myTimeOut);
        this.name = "";
        this.type = "info";
        this.message = "message";
        this.isShow = false;
    }

    public set(name: string, type: string, message: string, isShow: boolean, visibleTime:number) {
        this.reset();
        this.name = name;
        this.type = type;
        this.message = message;
        this.isShow = isShow;
        this.myTimeOut = setTimeout(() => { this.reset() }, visibleTime);
    }
}