import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient } from "@angular/common/http";
import { DispatchFilter } from "../models/sales-report/dispatch-filter.model";
import { Observable, catchError, map } from "rxjs";
import { EntityResponse } from "../models/entity-response.model";
import { HelperService } from "./helper.service";
import { SalesReportRequest } from "../models/abm-bracelet/seller.model";
import { environment } from "src/environments/environment";
import * as saveAs from "file-saver";

@Injectable({
    providedIn: 'root'
})
export class SalesService extends AbstractService {

    private ms_dispatches: string = "/msdispatches/api"

    constructor(protected override http: HttpClient, private helperService: HelperService) {
        super(http)
    }

    getDispatchFilters(apies: number): Observable<DispatchFilter[]> {
        return super.httpWafGet(`${this.ms_dispatches}/dispatch_filters`, `apies:${apies}`, 'id:desc', 1, 1000).pipe(
            map((resp: any) => {
                return <DispatchFilter[]>resp.data;
            })
        );
    }

    deleteDispatchFilter(id: number): Observable<EntityResponse> {
        return super.httpWafDelete(`${this.ms_dispatches}/dispatch_filters/${id}`).pipe(
            map((response) => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                return this.helperService.handleError(e)
            })
        );
    }

    getDispatchFilterById(id: number): Observable<DispatchFilter> {
        return super.httpWafGet(`${this.ms_dispatches}/dispatch_filters/${id}`).pipe(
            map(resp => {
                var dispatchFilter = new DispatchFilter();

                dispatchFilter.id = resp.data.id;
                dispatchFilter.apies = resp.data.apies;
                dispatchFilter.dateFromEpoch = resp.data.dateFromEpoch;
                dispatchFilter.dateToEpoch = resp.data.dateToEpoch;
                dispatchFilter.dateFromCompare = resp.data.dateFromCompare;
                dispatchFilter.dateToCompare = resp.data.dateToCompare;
                dispatchFilter.description = resp.data.description;
                dispatchFilter.dni = resp.data.dni;
                dispatchFilter.naftaTarget = resp.data.naftaTarget;
                dispatchFilter.dieselTarget = resp.data.dieselTarget;
                dispatchFilter.isNafta = resp.data.isNafta;
                dispatchFilter.isDiesel = resp.data.isDiesel;
                dispatchFilter.shiftSummary = resp.data.shiftSummary;
                dispatchFilter.isApp = resp.data.isApp;

                return dispatchFilter;
            })
        );
    }

    addDispatchFilter(data: DispatchFilter): Observable<EntityResponse> {
        return super.httpWafPost(`${this.ms_dispatches}/dispatch_filters`, data).pipe(
            map((response) => {
                return <EntityResponse>response;
            }),
            catchError(e => {
                console.log("es", e.error.data.errors);
                return this.helperService.handleError(e)
            })
        );
    }

    downloadReport(data: SalesReportRequest) {

        let url = `${environment.url_waf}${this.ms_dispatches}/dispatch/exportbossreportv2?Apies=${data.Apies}&Dni=${(data.Dni != "" ? data.Dni : "")}&DateFromEpoch=${data.DateFromEpoch}&DateToEpoch=${data.DateToEpoch}${(data.DateFromCompareEpoch != undefined ? "&DateFromCompare="+data.DateFromCompareEpoch : "")}${(data.DateToCompareEpoch != undefined ? "&DateToCompare="+data.DateToCompareEpoch : "")}&IsNafta=${data.IsNafta}&IsDiesel=${data.IsDiesel}&NaftaTarget=${(data.NaftaTarget != undefined ? data.NaftaTarget : 0)}&DieselTarget=${(data.DieselTarget != undefined ? data.DieselTarget : 0)}&ShiftSummary=${data.ShiftSummary}&IsApp=${data.IsApp}`;

        return this.http.get(url, { responseType: 'blob' })
            .subscribe((blob) => {
                saveAs(blob, 'ReporteJefe.xlsx');
            });
    }
}