import { Injectable } from "@angular/core";
import { BehaviorSubject, throwError } from "rxjs";
import { AlertMessage } from "src/app/models/entity-response.model";

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    public showErrorMessage: boolean = false;
    public errorMessage: string = "";
    private alertMessage = new BehaviorSubject<AlertMessage>(new AlertMessage());
    public currentMessage = this.alertMessage.asObservable();

    constructor() {
        this.showErrorMessage = false;
        this.errorMessage = "";
        console.warn(this.alertMessage)
    }

    public getMessage(value: number): string {
        switch (value) {
            case 2000:
                return "Formulario incorrecto, favor de validar los datos insertados";
            case 2100:
                return "Pulsera y vendedor se <b>vincularon exitosamente</b>";
            case 2200:
                return "Registros duplicados, ya existe una relacion pulsera vendedor insertada, favor de verificar la información";
            case 2400:
                return "Ya existe un panel registrado con esta direccion MAC";
            default:
                return "";
        }
    }

    public changeMessage(message: AlertMessage) {
        
    }

    public handleError(error: any) {
        console.log(error)
        let error_code = error.data?.errors[0]?.error_code;
        let error_description = error.data?.errors[0]?.error_description;
        let alert = new AlertMessage();
        var message = this.getMessage(error_code);
        if(message != "")
        {
            alert.set(error_code, "danger", message, true, 10000);
            this.alertMessage.next(alert)
        }

        return throwError(() => error);
    }
}