import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Station } from "src/app/modules/app.generated.module";
import { HelperService } from "src/app/services/helper.service";
import { SecurityService } from "src/app/services/security.service";
import { StationService } from "src/app/services/station.service";
import { AlertMessage } from "src/app/models/entity-response.model";
import { AlertaPromoComponent } from "../alerta-promos/alerta-promo.component";

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {

    @ViewChild(AlertaPromoComponent) alertaPromoComponent: AlertaPromoComponent;

    public currentViewName: string = "";
    private apies: number = 0;
    public selectedStation: Station = new Station();
    public alertMessage: AlertMessage;

    constructor(private stationService: StationService,
        private securityService: SecurityService,
        private router: Router,
        private helperService: HelperService) {
    }

    onChangeCurrentViewName() {
        this.alertaPromoComponent.isLoadData = false;
        this.alertaPromoComponent.ngOnInit();
    }

    ngOnInit(): void {
        this.helperService.currentMessage.subscribe(message => this.alertMessage = message)
        var self = this
        this.apies = this.securityService.getApies();
        console.warn("no se escuentra la apies, se redirecciona al home")
        console.warn(this.apies)
        if (this.apies > 0) {
            this.stationService.getStationByApies(this.apies).subscribe((result) => {
                if (result) {
                    self.selectedStation = result;
                    this.stationService.setStation(result);
                }
            })
        } else {
            this.router.navigateByUrl("/home")
        }
    }
}