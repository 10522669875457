import { Component, OnInit } from "@angular/core";
import { Station } from "src/app/modules/app.generated.module";
import { SecurityService } from "src/app/services/security.service";
import { StationBossService } from "src/app/services/station-boss.service";
import { StationService } from "src/app/services/station.service";
import { ReportStationBossRequest, Seller } from "src/app/models/abm-bracelet/seller.model";
import { AlertMessage } from "src/app/models/entity-response.model";
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'stationBoss',
    templateUrl: './station-boss.component.html',
    styleUrls: ['../admin/admin.component.scss', './station-boss.component.scss']
})

export class StationBossComponent implements OnInit {

    // modelo para descarga de reporte
    public requestData: ReportStationBossRequest;
    public isLoadData: boolean;
    public alert: AlertMessage;
    private fuel_station_id: number = 0;
    public stationSelectedInput: number = 0;
    public stationsFinder: Station[] = [];
    public isStationEmpty: boolean;
    public sellerSelectedInput: string;
    public sellers: Seller[];
    public isSellerEmpty: boolean;
    public isloadingSeller: boolean;
    public isPdfFormat: boolean;
    // Timer config
    private _beginDate: Date;
    public beginDate: Date;
    private _endDate: Date;
    public endDate: Date;
    public isHideTimerBegin: boolean;
    public isHideTimerEnd: boolean;

    constructor(private stationService: StationService,
        private stationBossService: StationBossService,
        private securityService: SecurityService) {
        this.fuel_station_id = this.securityService.getApies();
        this.isPdfFormat = false;
        this.sellerSelectedInput = "";
        this.requestData = {
            Format: "XLS",
            Dni: "",
            FechaDesdeEpoch: this.getEpochDate(new Date()),
            FechaHastaEpoch: this.getEpochDate(new Date()),
            Apies: ""
        };
        this.isLoadData = false;
        // Station and Seller input search
        this.isStationEmpty = true;
        this.isSellerEmpty = true;
        this.isloadingSeller = false;
        this.sellers = [];
        // DateTime data
        this.beginDate = this._beginDate = this.endDate = this._endDate = new Date();
        this.isHideTimerBegin = true;
        this.isHideTimerEnd = true;
        // Alert
        this.alert = new AlertMessage();
    }

    ngOnInit(): void {
        this.stationService.getStationByApies(this.fuel_station_id)
            .subscribe((result) => {
                this.isLoadData = true;
                var apiesText = result.name;
                result.name = `${result.apiesText} - ${result.name}`;
                this.stationsFinder = new Array(result);
                this.isSellerEmpty = true;
                this.isloadingSeller = true;
                this.stationBossService.getAllSellers(apiesText)
                    .subscribe({
                        next: (result) => {
                            console.log("Sellers", result);
                            this.sellers = result.map(x => { x.name = `${x.id} - ${x.name}`; return x; });
                            if (this.sellers.length > 0) this.isSellerEmpty = false;
                            else this.isSellerEmpty = true;
                            this.isloadingSeller = false;
                        },
                        error: (errors) => {
                            console.error(errors);
                            this.isSellerEmpty = true;
                            this.isloadingSeller = false;
                        }
                    });
            });
        this.isStationEmpty = false;
    }

    /** Setting datetime begin */
    showTimeBegin() {
        if (this._beginDate != this.beginDate)
            this.isHideTimerBegin = false;
    }

    setDateBegin(date: Date) {
        this.beginDate = date;
        this.requestData.FechaDesdeEpoch = this.getEpochDate(date);
    }

    hideTimeBegin(date: Date) {
        this._beginDate = date;
        this.isHideTimerBegin = true;
    }

    /** Setting datetime end */
    showTimeEnd() {
        if (this._endDate != this.endDate)
            this.isHideTimerEnd = false;
    }

    setDateEnd(date: Date) {
        this.endDate = date;
        this.requestData.FechaHastaEpoch = this.getEpochDate(date);
    }

    hideTimeEnd(date: Date) {
        this._endDate = date;
        this.isHideTimerEnd = true;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        console.log('Selected value: ', e.value);
    }

    // Event when seller is selected
    onSelectSeller(data: any) {
        this.requestData.Dni = data.item.id;
    }

    // Setting format file
    setFormatPdf() {
        this.isPdfFormat = !this.isPdfFormat;
        if (this.isPdfFormat) this.requestData.Format = "PDF";
        else this.requestData.Format = "XLS";
    }

    // Download report
    downloadReport() {
        var seller = this.sellers.filter(x => x.name == this.sellerSelectedInput);
        if (seller.length <= 0 && this.requestData.Dni == "") this.requestData.Dni = "";
        var station = this.stationsFinder.filter(x => x.apies == this.fuel_station_id);
        this.requestData.Apies = this.fuel_station_id.toString();
        console.log(station)
        console.log(this.stationsFinder)
        if (station.length == 0) {
            return this.alert.set("", "warning", "Asegurese de que los campos tengan los datos correctos", true, 5000);
        }
        if (isNaN(parseInt(this.requestData.FechaDesdeEpoch)) || isNaN(parseInt(this.requestData.FechaHastaEpoch))) {
            return this.alert.set("", "danger", "El formato de la fecha es incorrecto. Favor de verificar.", true, 5000);
        }
        if (parseInt(this.requestData.FechaDesdeEpoch) > parseInt(this.requestData.FechaHastaEpoch)) {
            return this.alert.set("", "danger", "La fecha de inicio no puede ser mayor que la fecha fin. Favor de verificar.", true, 5000);
        }
        this.stationBossService.downloadReport(this.requestData);
        return this.alert.set("", "info", "Descargando archivo ...", true, 5000);
    }

    // Get epoch data
    getEpochDate(date: Date): string {
        return (date.getTime()).toString().substr(0, 10);
    }
}