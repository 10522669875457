import { Component, Input } from "@angular/core";
import { AlertaPromo } from "src/app/modules/app.generated.module";

@Component({
    selector: 'panel-alert-item',
    templateUrl: './panel-alert-item.component.html',
    styleUrls: ['./panel-alert-item.component.scss']
})
export class PanelAlertItemComponent {

    @Input() alertas: AlertaPromo[];
}