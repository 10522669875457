<section>
    <div class="row" *ngIf="concentrator.id > 0">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <abm-panels-item *ngFor="let p of panels; let i = index" [index]="i+1" (addEvent)="onSubmitPanel($event)"
                [entity]="p" [pumpListTemp]="pumpListTemp" [pumpList]="pumpList"></abm-panels-item>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row" *ngIf="concentrator.id > 0">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <section *ngIf="addingPanel" style="padding-bottom:20px">
                <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
                <form #panelForm="ngForm" (ngSubmit)="panelForm.valid && onSubmitPanel(panelForm.value)">
                    <section class="panel p-4 border panel-default panel-body" style="background: #f3f3f3;">
                        <div class="row">
                            <div class="col-md-12">
                                <h4>Panel</h4>
                                <input type="text" name="description" #panel_description="ngModel"
                                    [(ngModel)]="panel.description" required maxlength="50" class="form-control">
                                <div *ngIf="panel_description.errors && (panel_description.dirty || panel_description.touched || panelForm.submitted)"
                                    class="alert alert-danger">
                                    <p *ngIf="panel_description.errors?.['required']">
                                        Campo requerido.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-4">
                                <h5 class="weight-700 pt-3">Dirrección MAC</h5>
                                <input type="text" name="mac_address" #mac_address="ngModel"
                                    [(ngModel)]="panel.mac_address" required class="form-control"
                                    placeholder="AA-BB-CC-00-11-22" pattern="^([0-9A-Fa-f]{2}[-]){5}([0-9A-Fa-f]{2})$"
                                    maxlength="17">
                                <div *ngIf="mac_address.errors && (mac_address.dirty || mac_address.touched || panelForm.submitted)"
                                    class="alert alert-danger">
                                    <p *ngIf="mac_address.errors?.['required']">
                                        Campo requerido.
                                    </p>
                                    <p *ngIf="mac_address.errors?.['pattern']">
                                        Formato de MAC incorrecto
                                    </p>
                                </div>
                            </div>
                            <div class="col-xs-6 col-md-4">
                                <h5 class="weight-700 pt-3">Surtidor A(Izq)</h5>
                                <select name="left_fuel_dispenser" #left_fuel_dispenser="ngModel" class="form-control"
                                    [(ngModel)]="panel.left_fuel_dispenser" required>
                                    <option *ngFor="let pump of pumpListTemp" [ngValue]="pump.pumpId">{{pump.pumpName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-6 col-md-4">
                                <h5 class="weight-700 pt-3">Surtidor B(Der)</h5>
                                <select name="right_fuel_dispenser" #right_fuel_dispenser="ngModel" class="form-control"
                                    [(ngModel)]="panel.right_fuel_dispenser" required>
                                    <option name="right_fuel_dispenser" *ngFor="let pump of pumpListTemp"
                                        [ngValue]="pump.pumpId">{{pump.pumpName}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button type="submit" class="btn btn-info panels-footer-buttom">Guardar</button>
                                <span class="btn btn-default panels-footer-buttom cancel"
                                    (click)="cancelAddPanel()">Cancelar</span>
                            </div>
                        </div>
                    </section>
                </form>
            </section>
            <span *ngIf="!this.panelService.getEditing()" class="float-end cursor-pointer" (click)="addPanel()"
                style="color:#305d9e"> <b> <i class="bi bi-plus-circle-fill"></i> Agregar panel</b> </span>
        </div>
        <div class="col-md-3"></div>
    </div>
</section>
<div id="abmPanelsModalShow" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="abmPanelsModalShow"
    aria-hidden="true" data-keyboard="false" data-backdrop="static" style="background:rgba(0, 0, 0, 0.88);">
    <div class="modal-dialog modal-md" role="document" style="top:30%">
        <div class="modal-content">
            <div class="modal-body text-center">
                <h4 class="text-start">Eliminar panel</h4>
                <div class="row">
                    <div class="col-md-12">
                        <p><b>IMPORTANTE:</b> La eliminación de este registro puede traer problemas en el funcionamiento
                            de los paneles de la estacion.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-default" data-bs-dismiss="modal">CANCELAR</button>
                        <button class="btn btn-info" data-bs-dismiss="modal" (click)="deletePanel()">CONFIRMAR</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>