import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    AlertaPromo,
    AlertaEditable,
    Station,
    Workstation,
    Surtidor,
    Lector,
    Cliente,
    TipoArea,
    IPathCode,
    IEstado,
    IEmbajador,
    PrioridadAlertaPromo,
    ClienteMetadata,
    EditAlertPromoDto,
} from '../../modules/app.generated.module';
import { Subscription } from 'rxjs';
import { StateChange, StationService, ViewChange, Vista } from 'src/app/services/station.service';
import { AlertaPromoService } from 'src/app/services/alerta-promo.service';
import { ActivatedRoute } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { HelperService } from 'src/app/services/helper.service';
import { SecurityService } from 'src/app/services/security.service';
import { IClientOptions, MqttClient, connect } from 'precompiled-mqtt';
import { AlertaPromoFilter, TipoAlerta } from 'src/app/models/alerta-promos/alerta-promo.model';
import { Title } from '@angular/platform-browser';
import { Buffer } from 'buffer';
import { stat } from 'fs';

@Component({
    selector: 'alerta-promo',
    templateUrl: './alerta-promo.component.html',
    styleUrls: ['../admin/admin.component.scss', './alerta-promo.component.scss', '../sass/grid-layout.scss', '../sass/ie_grid_fix.scss'],
})

export class AlertaPromoComponent implements OnInit, OnDestroy {
    private subscriptionStation: Subscription;
    public isPanelView: boolean = true;
    public alertas: AlertaPromo[];
    public alertas_modificadas: AlertaEditable[];
    public promos_filter: AlertaPromo[];
    public mensajes_filter: AlertaPromo[];
    public alertas_filter: AlertaPromo[];
    public deleteCliente = false;
    public isLoadData = false;
    public _hasChanged: boolean = false;
    public _excedeLimite: boolean = false;
    public _filtroArea: TipoArea = 0;

    public get hasChanged(): boolean {
        return this._hasChanged;
    }

    public set hasChanged(val: boolean) {
        this._hasChanged = val;
        this.updateButtonStatus();
    }

    public get filtroArea(): TipoArea {
        return this._filtroArea;
    }

    public set filtroArea(val: TipoArea) {
        this._filtroArea = val;
        this.updateButtonStatus();
    }

    public get excedeLimite(): boolean {
        return this._excedeLimite;
    }

    public set excedeLimite(val: boolean) {
        this._excedeLimite = val;
        this.updateButtonStatus();
    }

    updateButtonStatus() {
        if (this.stationService) {
            var state = new StateChange();
            state.excedeLimite = this._excedeLimite;
            state.hasChanged = this._hasChanged;
            state.filtroArea = this._filtroArea;
            this.stationService.setCurentState(state);
        }
    }

    public area: number = -1;
    public has_left_column: boolean = true;
    public has_right_column: boolean = true;
    public isDisabled_left_column: boolean = false;
    public isDisabled_right_column: boolean = false;
    public alertas_original: AlertaPromo[];
    public promos_original_filter: AlertaPromo[];
    public alertas_original_filter: AlertaPromo[];
    public mensajes_original_filter: AlertaPromo[];
    public limiteOfertas: number = 4;
    public limiteAlertas: number = 2;
    public limiteActivos: number = 6;
    public cantidadActivos: number = 0;
    public cantidadOfertas: number = 0;
    public cantidadAlertas: number = 0;
    public cantidadMensajes: number = 0;
    public station: Station;
    public workstation: Workstation;
    public succesSave: boolean = false;
    public showBloqueante: boolean = false;
    public alertasPromosError = false;
    public mqttError = false;
    public clienteErrorLeft = false;
    public clienteErrorRight = false;
    public lastMqttMessage: number = Date.now();
    public lastAlertasPromos: number = Date.now();
    public lastClienteLeft: number = Date.now();
    public lastClienteRight: number = Date.now();

    public dataClienteLeft: ClienteMetadata = {
        fechaCreacion: Date.now(),
        kms: "",
        nombreSocio: "",
        isExpired: true,
        id: 0,
        code: ""
    };

    public dataClienteRight: ClienteMetadata = {
        fechaCreacion: Date.now(),
        kms: "",
        nombreSocio: "",
        isExpired: true,
        id: 0,
        code: ""
    };

    @Input() renderType: number = -1;

    public surtidor_left: Surtidor = {
        name: "A",
        isEnable: true,
        placeholder: 0,
        status: "any"
    } as Surtidor;

    public surtidor_right: Surtidor = {
        name: "B",
        isEnable: true,
        placeholder: 1,
        status: "any"
    } as Surtidor;

    public lector_left: Lector = {
        embajador: "",
        placeholder: 0,
        action: "",
        isEnable: true,
        status: "any",
        idCorto: "0"

    } as Lector;

    public lector_right: Lector = {
        embajador: "",
        placeholder: 1,
        action: "",
        isEnable: true,
        status: "any",
        idCorto: "0"
    } as Lector;

    public cliente_left: Cliente;
    public cliente_right: Cliente;
    public currentView: ViewChange;
    public filtroTipoAlerta: TipoAlerta = 2;
    public verModal: boolean = false;
    private baseUrl: string;
    private mqttClient: MqttClient;
    public isLive: boolean = false;
    @Output() onLoaded = new EventEmitter<boolean>();

    constructor(private stationService: StationService,
        private alertaPromoService: AlertaPromoService,
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private clienteService: ClienteService,
        public helperService: HelperService,
        private securityService: SecurityService
    ) {
        var self = this;
        this.currentView = new ViewChange();
        this.currentView.setView(Vista.Render, () => {

        });
    }

    ngOnDestroy(): void {
        this.subscriptionStation.unsubscribe();
        this.buttonView.unsubscribe();
    }

    private buttonView: Subscription;

    ngOnInit(): void {

        this.alertas_original = [];

        this.buttonView = this.stationService.getButtonView().subscribe(acc => {
            if (acc == "editarPanel") {
                this.editarPanel();
            }
            if (acc == "previsualizar") {
                this.previsualizar();
            }
            if (acc == "onCancelar") {
                this.onCancelar();
            }
            if (acc == "onPublicar") {
                this.onPublicar();
            }
        });

        this.subscriptionStation = this.stationService.getStation()
            .subscribe(station => { this.onGetStation(station) });

        var st = this.stationService.getSelectedStation();

        if (st) {
            this.onGetStation(st);
        }

        this.activatedRoute.params.subscribe(params => {
            this.iniciar(null);
            var ws = params["ws"];
            var brokerUrl: string = "";

            this.activatedRoute.queryParams.subscribe(q => brokerUrl = q["brokerUrl"] || "");

            if (ws) {
                this.isLive = true;
                this.stationService.getWorkstation(ws).subscribe(_ws => {
                    if (_ws) {
                        this.onGetWorktation(_ws, brokerUrl);
                        // guardo el apies para que sea usada en otors componentes desde el securityService
                        this.securityService.setApies(_ws.apies);
                    };
                });
            }
        });
    }

    onGetStation(station: Station): void {
        this.station = station;
        if (station) {
            this.alertaPromoService.getAllAlertaPromo(this.station.apies).subscribe({
                next: (result) => {
                    console.log("alertas promos", result);
                    this.isLoadData = true;
                    this.iniciar(result);
                    this.onLoaded.emit(true);
                    if (this.area > 0) {
                        this.applyTipoAreaPromoFilter(this.filtroArea = this.area);
                    }
                    this.lastAlertasPromos = Date.now();
                    this.alertasPromosError = false;

                }, error: (error) => {
                    this.alertasPromosError = true;
                }
            });
        }
    }

    procesarMessage(topic: string, status: string, apies: number, listener: IPathCode, target: IEstado[], placeholder: number) {
        let time = 300 * 1000;
        let time2 = 120 * 1000;

        var lastTimer: any;

        target.forEach(t => {

            if (t.status == "Dispatching" && status == "Available") {
                t.status = "End";

                setTimeout(() => {
                    if (t.status == "End") {
                        t.status = "Available";
                        this.clean_metadata_client(topic, placeholder);
                    }
                }, time);

                if (!lastTimer) {
                    lastTimer = setTimeout(() => {
                        lastTimer == null;
                        console.log("Serviclub Clear Timeout");
                    }, time2);
                }

            } else {
                t.status = status;
            }

            if (status == "Authorized") {
                if (lastTimer) {
                    clearTimeout(lastTimer);
                    lastTimer = null;
                }
            }

        });
    }

    private lastTopic: string;

    private Topics: Map<string, (topic: string, payload: Buffer) => void> = new Map<string, (topic: string, payload: Buffer) => void>();
    subscribeTopic(topic: string, callback: (topic: string, payload: Buffer) => void) {

        this.Topics.set(topic, callback);
        console.log(topic);
        if (!this.mqttClient) {
            return;
        }

        this.mqttClient.subscribe(topic).on("message", (_topic, payload) => {
            var val = payload.toString('utf-8');

            if (this.lastTopic == _topic + ":" + val) {
                return;
            }

            this.callTopic(_topic, payload);
            this.lastTopic = _topic + ":" + val;
        });
    }

    callTopic(topic: string, payload: Buffer): void {
        var callback = this.Topics.get(topic);
        if (!callback) {
            return;
        }

        callback(topic, payload);
    }

    subscribeEstadoTopic(apies: number, listener: IPathCode, target: IEstado[], placeholder: number): void {
        var topic = ['estado', 'surtidor', listener.code, 'apies', apies].join('/');
        this.subscribeTopic(topic, (_topic, payload) => {

            if (payload && payload.length > 0 && topic == _topic) {
                var status = payload.toString('utf-8');
                console.log(_topic + ":" + status);
                if (status != "End") {
                    this.clean_metadata_client(topic, placeholder);
                }
                this.procesarMessage(topic, status, apies, listener, target, placeholder);
            }
        });
    }

    subscribeEmbajadorTopic(apies: number, listener: IPathCode, target: IEmbajador): void {
        var topic = ['embajador', 'surtidor', listener.code, 'apies', apies].join('/');
        this.subscribeTopic(topic, (_topic, payload) => {
            if (payload && payload.length > 0 && _topic == topic) {

                target.embajador = payload.toString('utf-8');

                console.log(_topic + ":" + target.embajador);
            }
        });
    }

    subscribeClienteTopic(apies: number, listener: IPathCode, placeholder: number, target: IEstado[]): void {
        var topic = ['cliente', 'surtidor', listener.code, 'apies', apies].join('/');
        var topicEstado = ['estado', 'surtidor', listener.code, 'apies', apies].join('/');
        this.subscribeTopic(topic, (_topic, payload) => {
            target.forEach(t => {
                if (payload && payload.length > 0 && _topic == topic && t.status == "End") {

                    console.log(_topic + ":" + payload.toString('utf-8'));
                    var metaCliente: ClienteMetadata = placeholder == 0 ? this.dataClienteLeft : this.dataClienteRight;

                    var cliente = JSON.parse(payload.toString('utf-8'));
                    metaCliente.kms = cliente.kms;
                    metaCliente.nombreSocio = this.truncateName(cliente.nombreSocio);

                    this.clienteTopics.set(topicEstado, metaCliente);
                }
            });
        });
    }

    truncateName(name: string) {
        if (name.length > 20) {
            return name.slice(0, 17) + '...';
        } else {
            return name;
        }
    }

    subscribe_id_cortoTopic(apies: number, cliente: Cliente, lector: Lector): void {
        var topic = ['id_corto', 'surtidor', cliente.code, 'apies', apies].join('/');

        this.subscribeTopic(topic, (_topic, payload) => {
            if (payload && payload.length > 0 && _topic == topic) {

                cliente.id_Corto = payload.toString('utf-8');

                lector.idCorto = cliente.id_Corto

                console.log(_topic + ":" + cliente.id_Corto);
            }
        });
    }

    clean_metadata_client(topic: string, placeholder: number) {
        var metaCliente: ClienteMetadata = placeholder == 0 ? this.dataClienteLeft : this.dataClienteRight;

        metaCliente.kms = "";
        metaCliente.nombreSocio = "";

        this.clienteTopics.set(topic, metaCliente);
    }

    private clienteTopics: Map<string, ClienteMetadata> = new Map<string, ClienteMetadata>();

    deleteClienteMetadataById(id: number, code: string) {
        if (this.deleteCliente) {
            var clienteError = this.cliente_left.code == code ?
                this.clienteErrorLeft : this.clienteErrorRight;

            this.clienteService.deleteClienteMetadata(id).subscribe({
                next: isOk => {
                    console.log(isOk);
                },
                error: err => {
                    clienteError = true;
                }
            })
        }
    }

    callClienteMetadata(cliente: Cliente, ws: Workstation, placeholder: number, surtidor: Surtidor) {
        var metaCliente: ClienteMetadata = placeholder == 0 ? this.dataClienteLeft : this.dataClienteRight;
        var lastDate = placeholder == 0 ? this.lastClienteLeft : this.lastClienteRight;
        var clienteError = placeholder == 0 ? this.clienteErrorLeft : this.clienteErrorRight;
        var interval = 60 * 1000; // 60 seconds
        var timeWin = 10 * 60 * 1000;
        var topic = ['estado', 'surtidor', surtidor.code, 'apies', ws.apies].join('/');
        if (!metaCliente.code || metaCliente.code == "") {
            metaCliente.code = cliente.code;
        }

        setInterval(() => {
            console.log(surtidor)

            if (!cliente.id_Corto || (surtidor.status != "Authorized" && surtidor.status != "Dispatching")) {
                return;
            }

            this.clienteService.getClienteMetadata(ws.apies.toString(), cliente.id_Corto).subscribe({
                next: data => {
                    clienteError = false;

                    if (!data) {
                        if (metaCliente && !metaCliente.isExpired) {
                            metaCliente.isExpired = true;
                        }
                        return;
                    }

                    var expireTime = new Date(lastDate + timeWin);

                    if (metaCliente && !metaCliente.isExpired && metaCliente.id == data.id && Date.now() >= expireTime.valueOf()) {
                        console.log("Checkpoint")

                    } else {
                        if (metaCliente && metaCliente.id != data.id) {
                            metaCliente.fechaCreacion = data.fechaCreacion;
                            metaCliente.id = data.id;
                            metaCliente.isExpired = false;
                            metaCliente.kms = data.kms;
                            metaCliente.nombreSocio = data.nombreSocio;
                            lastDate = Date.now();
                            this.clienteTopics.set(topic, metaCliente);
                        }
                    }
                },
                error: err => {
                    clienteError = true;
                }
            })
        }, interval)
    }

    setSurtidorError() {
        if (this.surtidor_left) {
            this.surtidor_left.status = "Error";
        }

        if (this.surtidor_right) {
            this.surtidor_right.status = "Error";
        }
    }

    onGetWorktation(_ws: Workstation, brokerUrl: string = ""): void {

        this.workstation = _ws;
        this.station = {
            apies: _ws.apies
        } as Station;
        this.area = _ws.area;
        this.isPanelView = false;
        this.has_left_column = _ws.layout.indexOf(0) != -1;
        this.has_right_column = _ws.layout.indexOf(1) != -1;

        if (_ws.gateway) {
            try {
                if (brokerUrl && brokerUrl != "") {
                    this.mqttClient = connect(brokerUrl);
                } else {
                    _ws.gateway.options.keepalive = 30;
                    this.mqttClient = connect(_ws.gateway.brokerUrl, _ws.gateway.options as IClientOptions);
                }

                this.mqttClient.on("error", (e: any) => {
                    console.error("Error with mqtt " + new Date());
                    console.error(e);

                    this.mqttError = true;
                    this.setSurtidorError();
                });

                this.mqttClient.on("connect", () => {
                    console.log("Mqtt is connected " + new Date())

                    this.surtidor_left.status = "Available";
                    this.surtidor_right.status = "Available";
                    this.mqttError = false;
                });

                this.mqttClient.on("message", () => {
                    this.mqttError = false;
                    this.lastMqttMessage = Date.now();
                })

                this.mqttClient.on("offline", () => {
                    console.error("Mqtt is offline " + new Date())
                });

                this.mqttClient.on("reconnect", () => {
                    console.log("Mqtt try to reconnect " + new Date())
                    this.mqttError = true;
                    this.setSurtidorError();
                });
            } catch (e) {
                this.mqttError = true;
                this.setSurtidorError();

            }
        }

        this.title.setTitle(_ws.name);

        var _lls: IEstado[] = [];
        var _rrs: IEstado[] = [];

        var _sl = _ws.surtidores.find(x => x.placeholder == 0);
        if (_sl && this.has_left_column && _sl.isEnable == true) {
            this.surtidor_left = _sl;
            _lls.push(this.surtidor_left);
            this.subscribeEstadoTopic(_ws.apies, this.surtidor_left, _lls, 0);
        } else {
            this.isDisabled_left_column = true;
        }

        var _sr = _ws.surtidores.find(x => x.placeholder == 1);
        if (_sr && this.has_right_column && _sr.isEnable == true) {
            this.surtidor_right = _sr;
            _rrs.push(this.surtidor_right);
            this.subscribeEstadoTopic(_ws.apies, this.surtidor_right, _rrs, 1);
        } else {
            this.isDisabled_right_column = true;
        }

        var _ll = _ws.lectores.find(x => x.placeholder == 0);
        if (_ll && this.has_left_column && _ll.isEnable == true && !this.isDisabled_left_column) {
            this.lector_left = _ll;
            _lls.push(this.lector_left);
            this.subscribeEmbajadorTopic(_ws.apies, this.lector_left, this.lector_left);
        }

        var _lr = _ws.lectores.find(x => x.placeholder == 1);
        if (_lr && this.has_right_column && _lr.isEnable && !this.isDisabled_right_column) {
            this.lector_right = _lr;
            _rrs.push(this.lector_right);
            this.subscribeEmbajadorTopic(_ws.apies, this.lector_right, this.lector_right);
        }

        var _cl = _ws.clientes.find(x => x.placeholder == 0);
        if (_cl && this.has_left_column && _cl.isEnable == true && !this.isDisabled_left_column) {
            this.cliente_left = _cl;
            this.subscribe_id_cortoTopic(_ws.apies, _cl, this.lector_left);
            this.subscribeClienteTopic(_ws.apies, _cl, 0, _lls);
        }

        var _cr = _ws.clientes.find(x => x.placeholder == 1);
        if (_cr && this.has_right_column && _cr.isEnable == true && !this.isDisabled_right_column) {
            this.cliente_right = _cr;
            this.subscribe_id_cortoTopic(_ws.apies, _cr, this.lector_right);
            this.subscribeClienteTopic(_ws.apies, _cr, 1, _rrs);
        }

        var getFilteredPromo = () => {
            this.alertaPromoService.getAlertaPromoFiltrados(this.station.apies, _ws.area).subscribe({
                next: result => {
                    this.isLoadData = true;
                    this.iniciar(result);
                    this.onLoaded.emit(true);
                    console.log("this.onLoaded", this.onLoaded)
                    this.applyTipoAreaPromoFilter(this.filtroArea = this.area);
                    console.log({ apies: this.station.apies, area: _ws.area, ws: _ws.id, date: new Date() });
                    this.lastAlertasPromos = Date.now();
                    this.alertasPromosError = false;
                },
                error: err => {
                    console.error("alertaPromoService.getAlertaPromoFiltrados", err);
                    this.alertasPromosError = true;
                }
            });
        }

        var ms = 300000; // 15 seconds
        getFilteredPromo();
        setInterval(getFilteredPromo, ms);
    }

    iniciar(source: AlertaPromo[] | null) {
        if (!source) {
            source = [];
        }

        this.alertas = source;
        this.promos_filter = [];
        this.alertas_filter = [];
        this.mensajes_filter = [];
        this.alertas_original = JSON.parse(JSON.stringify(this.alertas));
        this.promos_original_filter = [];
        this.alertas_original_filter = [];
        this.mensajes_original_filter = [];
        this.alertas_modificadas = [];
        this.filtroArea = TipoArea.Playa;
        this.filtroTipoAlerta = TipoAlerta.Ofertas;
        this.excedeLimite = false;
        this.hasChanged = false;
        this.verModal = false;
        this.cantidadActivos = 0;
        this.cantidadOfertas = 0;
        this.cantidadAlertas = 0;
        this.cantidadMensajes = 0;
        this.actualizarActivos(null);
        this.applyTipoAreaPromoFilter(this.filtroArea);
        this.changeView(Vista.Render);

    }

    onCancelar() {
        if (this.hasChanged) {

            this.verModal = true;
        } else {
            this.descartar();
        }
    }

    onPublicar() {
        console.warn(this.station);
        console.warn(this.station.apies);
        let data = new EditAlertPromoDto();
        data.apies = this.station.apies;
        data.items = this.alertas_modificadas;

        this.alertaPromoService.editarAlertaPromo(data).subscribe(
            {
                next: (r) => {
                    if (r) {
                        this.iniciar(this.alertas);
                        this.onGetStation(this.station);
                        this.succesSave = true;
                        setTimeout(() => { this.succesSave = false }, 5000);
                    }
                },
                error: (err) => {
                    this.descartar();
                    this.showErrorResponse(err);
                }
            }
        );
    }

    showErrorResponse(error: any) {
        this.helperService.errorMessage = error;
        this.helperService.showErrorMessage = true;
        console.warn(error)
        setTimeout(() => {
            this.helperService.errorMessage = "";
            this.helperService.showErrorMessage = false;
        }, 30000)
    }

    descartar() {
        this.iniciar(this.alertas_original);
    }

    volver() {
        this.changeView(Vista.Edit);
    }

    actualizarActivos(alerta: AlertaPromo | null) {

        if (alerta) {
            console.log(alerta.tipoAlerta)
            console.log(this.cantidadAlertas)
            console.log(this.cantidadOfertas)
            switch (alerta.tipoAlerta) {
                case 1:
                    if (this.cantidadAlertas > this.limiteAlertas - 1 && !alerta.isActive) {
                        this.excedeLimite = true;
                        setTimeout(() => { this.excedeLimite = false }, 5000);
                        return;
                    }
                    break;
                case 2:
                    if (this.cantidadOfertas > this.limiteOfertas - 1 && !alerta.isActive) {
                        this.excedeLimite = true;
                        setTimeout(() => { this.excedeLimite = false }, 5000);
                        return;
                    }
                    break
                default:
                    return;

            }

            alerta.isActive = !alerta.isActive;
            this.updateChange(alerta);
        }

        if (this.alertas && this.alertas.length) {
            var cantidadActivos = 0;
            var cantidadOfertas = 0;
            var cantidadAlertas = 0;

            this.alertas
                .filter(x => x.isActive)
                .forEach(a => {
                    cantidadActivos++;
                    if (a.tipoAlerta == 1)
                        cantidadAlertas++;

                    if (a.tipoAlerta == 2)
                        cantidadOfertas++;
                });

            this.cantidadActivos = cantidadActivos;
            this.cantidadOfertas = cantidadOfertas;
            this.cantidadAlertas = cantidadAlertas;
        }
    }

    actualizarPrioridad(alerta: AlertaPromo, prioridad: PrioridadAlertaPromo) {
        alerta.priority = prioridad;
        this.updateChange(alerta);
    }

    toogleArea(alerta: AlertaPromo, area: TipoArea) {

        switch (area) {
            case TipoArea.Playa:
                alerta.isForBeach = !alerta.isForBeach;
                break;
            case TipoArea.Box:
                alerta.isForBox = !alerta.isForBox;
                break;
            case TipoArea.Tienda:
                alerta.isForStore = !alerta.isForStore;
                break;
        }

        this.updateChange(alerta);
    }

    public static mapAlertaPromoFilter(x: AlertaPromo, alertaFilas: number, promoFilas: number, promoCount: number, colSpan: number, rowSpan: number): AlertaPromoFilter {
        var colStyle = "";
        var rowStyle = "";
        var style = "cell-col-span-" + colSpan + " cell-row-span-" + rowSpan;
        var clases = [
            'cel-tipo-alerta-' + x.tipoAlerta.toString(),
            'cel-priority-' + x.priority.toString(),
            'cel-cols-' + colSpan,
            'cel-rows-' + rowSpan,
            'cel-alertas-filas-' + alertaFilas,
            'cel-promo-filas-' + promoFilas,
            'cel-promo-count-' + promoCount,
            'cel-tipo-descuento-' + x.tipoDescuento,
            'cel-tipo-promocion-' + x.tipoPromocion,
            x.imageUrl && x.imageUrl != "" && x.imageUrl != "-1" ? 'has-image' : 'wo-image'
        ].join(' ');

        return ({
            alertasFilas: alertaFilas,
            promoCounts: promoCount,
            promoFilas: promoFilas,
            colSpan: colSpan,
            rowSpan: rowSpan,
            colStyle: colStyle,
            rowStyle: rowStyle,

            style: style,

            id: x.id,
            imageUrl: x.imageUrl,
            isActive: x.isActive,
            isForBeach: x.isForBeach,
            isForBox: x.isForBox,
            isForStore: x.isForStore,
            message: x.message,
            priority: x.priority,
            tipoAlerta: x.tipoAlerta,
            accion: x.accion,
            descripcionCorta: x.descripcionCorta,
            montoDescuento: x.montoDescuento,
            tipoDescuento: x.tipoDescuento,
            tipoPromocion: x.tipoPromocion,
            clases: clases,
            entidad: x.entidad

        } as AlertaPromoFilter);
    }

    public static isEven(num: number): boolean {
        return num % 2 === 0;
    }

    public static orderAlertas(items: AlertaPromo[]): AlertaPromoFilter[] {
        var result: AlertaPromoFilter[] = [];

        var alertas = items
            .filter(x => x.tipoAlerta == 1)
            .sort(x => (1 - x.priority))
            .splice(0, 6);

        var alertaFilas = alertas.length;

        var _alertas = alertas
            .map(x => this.mapAlertaPromoFilter(x, alertaFilas, 0, 0, 4, 0));

        return result.concat(_alertas);
    }

    public static orderMensajes(items: AlertaPromo[]): AlertaPromoFilter[] {
        var result: AlertaPromoFilter[] = [];

        var mensajes = items.filter(x => x.tipoAlerta == 3)
            .sort(x => (1 - x.priority))
            .splice(0, 6);

        var mensajeFilas = mensajes.length;

        var _mensajes = mensajes
            .map(x => this.mapAlertaPromoFilter(x, mensajeFilas, 0, 0, 4, 0));

        return result.concat(_mensajes);
    }

    public static orderPromos(items: AlertaPromo[]): AlertaPromoFilter[] {
        var result: AlertaPromoFilter[] = [];

        var promoFilas = 6;
        var promoCount = 0;

        var promos = items
            .filter(x => x.tipoAlerta == 2)
            .sort(x => (1 - x.priority))
            .splice(0, promoFilas * 2);

        promoCount = promos.length;

        var _promos: AlertaPromoFilter[] = [];
        if (promoFilas % promoCount == 0) {
            var colSpan = 4;
            var rowSpan = promoFilas / promoCount;
            var updateLast = false;

            if (promoCount != 3) {

                if (!this.isEven(promoCount) && promoCount == promoFilas) {
                    colSpan = 2;
                    rowSpan = Math.floor(promoFilas / Math.ceil((promoCount + 1) / 2));
                    updateLast = true;
                }
            }

            _promos = promos
                .map(x => this.mapAlertaPromoFilter(x, 0, promoFilas, promoCount,
                    colSpan,
                    rowSpan
                ));

            if (updateLast) {
                _promos[_promos.length - 1].colSpan = 4;
                _promos[_promos.length - 1].rowSpan = rowSpan = Math.floor(promoFilas / Math.ceil(promoCount / 2));

            }

        } else {
            var colSpan = 4;
            var rowSpan = Math.floor(promoFilas / promoCount);

            _promos = promos
                .map(x => this.mapAlertaPromoFilter(x, 0, promoFilas, promoCount,
                    colSpan,
                    rowSpan
                ));
        }

        if (!this.isEven(promoCount) && _promos.length > 0) {
            var _p = _promos[_promos.length - 1];
            _promos[_promos.length - 1] = this.mapAlertaPromoFilter(_p, 0, promoFilas, promoCount,
                4,
                _p.rowSpan
            );
        }

        var rows = promoFilas;

        _promos.forEach(p => {

            if (p.colSpan == 2) {
                rows -= (p.rowSpan / 2);
            } else if (p.colSpan == 4) {
                rows -= p.rowSpan;
            }

        });

        if (rows > 0) {
            _promos.forEach(p => {
                if (rows == 0) return;
                p.rowSpan++;

                if (p.colSpan == 2) {
                    rows -= 1 / 2;
                }

                if (p.colSpan == 4) {
                    rows -= 1;
                }

            });
        }

        _promos = _promos
            .map(x => this.mapAlertaPromoFilter(x, 0, promoFilas, promoCount, x.colSpan, x.rowSpan));

        return result.concat(_promos);
    }

    applyTipoAreaPromoFilter(filter: TipoArea) {
        if (!this.alertas_original || !this.alertas_original.length) {
            this.promos_original_filter = [];
            this.alertas_original_filter = [];
            this.mensajes_original_filter = [];
        }

        var _items = this.alertas_original.filter(alerta => {
            if (!alerta.isActive) {
                return false;
            }

            if (filter == TipoArea.Playa) {
                return alerta.isForBeach;
            }
            if (filter == TipoArea.Tienda) {
                return alerta.isForStore;
            }

            if (filter == TipoArea.Box) {
                return alerta.isForBox;
            }
            return false;
        });

        this.promos_original_filter = AlertaPromoComponent.orderPromos(_items);
        this.alertas_original_filter = AlertaPromoComponent.orderAlertas(_items);
        this.mensajes_original_filter = AlertaPromoComponent.orderMensajes(_items);

        var ofertas = this.promos_original_filter.filter(x => x.tipoAlerta == 2);
        var alertas = this.alertas_original_filter.filter(x => x.tipoAlerta == 1);
        var mensajes = this.mensajes_original_filter.filter(x => x.tipoAlerta == 3);

        if (ofertas) {
            this.cantidadOfertas = ofertas.length;
        }

        if (alertas) {
            this.cantidadAlertas = alertas.length;
        }

        if (mensajes) {
            this.cantidadMensajes = mensajes.length;
        }
    }

    applyTipoAreaPromoFilterPreview(filter: TipoArea) {
        if (!this.alertas || !this.alertas.length) {
            this.alertas_filter = [];
            this.promos_filter = [];
            this.mensajes_filter = [];
        }

        var _items = this.alertas.filter(alerta => {
            if (!alerta.isActive) {
                return false;
            }

            if (filter == TipoArea.Playa) {
                return alerta.isForBeach;
            }
            if (filter == TipoArea.Tienda) {
                return alerta.isForStore;
            }

            if (filter == TipoArea.Box) {
                return alerta.isForBox;
            }
            return false;
        });

        this.promos_filter = AlertaPromoComponent.orderPromos(_items);
        this.alertas_filter = AlertaPromoComponent.orderAlertas(_items);
        this.mensajes_filter = AlertaPromoComponent.orderMensajes(_items);
    }

    puedePrevisualizar() {
        return !(this.excedeLimite);
    }

    previsualizar() {
        if (!this.puedePrevisualizar()) {
            return;
        }

        this.filtroArea = TipoArea.Playa;
        this.filtroTipoAlerta = TipoAlerta.Ofertas;
        this.alertas_filter = [];
        this.promos_filter = [];
        this.mensajes_filter = [];
        this.applyTipoAreaPromoFilterPreview(this.filtroArea);


        this.changeView(Vista.Preview);
    }

    editarPanel() {
        this.alertas_modificadas = [];
        this.filtroTipoAlerta = TipoAlerta.Ofertas;
        this.hasChanged = false;
        this.succesSave = false;
        this.changeView(Vista.Edit);
    }

    changeView(vista: Vista) {
        if (!this.currentView) {
            this.currentView = new ViewChange();
        }

        this.currentView.setView(vista, () => {
            if (vista == Vista.Edit) {
                this.onCancelar();
            }
            if (vista == Vista.Preview) {
                this.volver();
            }
        });


        this.stationService.setCurentView(this.currentView);
    }

    trigerClickExpander(alertaId: string, e: MouseEvent) {

        var srcElement = e.target as Element;
        if (srcElement) {
            if (srcElement.className == "checkmark" || srcElement.className == "isActive-input") {
                return;
            }
        }

        var expander = document.getElementById("expander-" + alertaId);
        if (!expander) { return };
        expander.click();
    }

    updateChange(alerta: AlertaPromo) {
        var alertaModificada: AlertaEditable | undefined;
        alertaModificada = this.alertas_modificadas.find(a => a.id == alerta.id);

        if (!alertaModificada) {
            this.alertas_modificadas.push(alerta as AlertaEditable);
        } else {
            var alerta_original = this.alertas_original.find(a => a.id == alerta.id);
            if (!alerta_original) {
                this.hasChanged = this.alertas_modificadas.length > 0;
                return console.log("Error alerta no encontrada");
            }

            if (alerta_original.isActive == alerta.isActive
                && alerta_original.priority == alerta.priority
                && alerta_original.isForBeach == alerta.isForBeach
                && alerta_original.isForBox == alerta.isForBox
                && alerta_original.isForStore == alerta.isForStore) {

                var index = this.alertas_modificadas.findIndex(a => a.id == alerta.id);
                this.alertas_modificadas.splice(index, 1);
            } else {
                alertaModificada.isActive = alerta.isActive;
                alertaModificada.priority = alerta.priority;

                alertaModificada.isForBeach = alerta.isForBeach;
                alertaModificada.isForBox = alerta.isForBox;
                alertaModificada.isForStore = alerta.isForStore;
            }
        }

        this.hasChanged = this.alertas_modificadas.length > 0;
    }

    detectIE(): number {
        var ua = window.navigator.userAgent;
        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return 22;
    }

    preventDefault(event: Event) {
        event.preventDefault();
    }
}