<div *ngIf="!isLoadData; else elseBlock">
    <img src="../../../assets/images/loader-gris.svg" /> Cargando datos...
</div>
<ng-template #elseBlock>
    <section>
        <alert *ngIf="alert.isShow" type="{{alert.type}}"><span [innerHtml]="alert.message"></span></alert>
        <div class="row pb-3">
            <div class="col-md-12">
                <h6>
                    <b>Mensaje para mostrar en el Panel</b>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
            <div class="col-xs-12 col-sm-10 col-md-8 col-lg-7 text-center">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            Definici&oacute;n del Mensaje
                        </div>
                        <div class="card-body">
                            <div class="row pb-3">
                                <div class="col-12">
                                    <div class="form-group text-start">
                                        <label class="fw-bold">APIES: &nbsp;</label>
                                        <label class="d-inline">{{stationsFinder[0].name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-1">
                                <div class="form-group text-start col-8">
                                    <label class="fw-bold">Mensaje: </label>
                                    <input id="input" required name="description" pInputText
                                        [disabled]="disableMessageEdition" [pattern]="ConstantRegEx.EMPTYVALUE"
                                        maxlength="60" #description="ngModel" type="text" pInputText
                                        [(ngModel)]="customMessage.description" class="col-12 form-control mensaje">
                                    <small class="d-flex justify-content-end">{{!!customMessage.description ?
                                        customMessage.description.length :
                                        0}}/60</small>
                                    <div *ngIf="description.errors && (description.dirty)" class="alert alert-danger">
                                        <p>
                                            Este campo es requerido.
                                        </p>
                                    </div>
                                    <input type="button" *ngIf="showEditMessageButton" (click)="enableEdidMessage()"
                                        [disabled]="canEditMessage" class="d-flex float-end btn btn-info btn-sm"
                                        value="Editar Mensaje" />
                                </div>
                                <div class="form-group text-start col-3">
                                    <label class="fw-bold">&Iacute;cono: </label>
                                    <ng-select id="icon" name="icon" [(ngModel)]="customMessage.icon" #icon="ngModel"
                                        placeholder="Seleccione un ícono" required [disabled]="disableMessageEdition">
                                        <ng-option *ngFor="let icon of icons" value="{{icon.value}}">
                                            <span [ngStyle]='{"background-color":"#0161BC","padding":"0.4em"}'>
                                                <img [src]="'../../../assets/images/'+icon.value+'.svg'">
                                            </span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="icon.errors && (icon.dirty)" class="alert alert-danger">
                                        <p>
                                            Este campo es requerido.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-5">
                                <div class="form-group col-xs-12 col-sm-4 text-start">
                                    <label class="fw-bold">Fecha y Hora de inicio</label>
                                    <div class="form-group">
                                        <div class="input-group" id="dateTimeBeginDate">
                                            <input type="text" class="form-control" bsDatepicker #dpBegin="bsDatepicker"
                                                [bsValue]="fromDate"
                                                value="{{ fromDate | date:'dd-MM-yyyy' }} {{ fromDate | date:'shortTime' }}"
                                                [outsideClick]="true" [minDate]="today_date"
                                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY hh:mm a', containerClass: 'theme-blue'  }"
                                                (onHidden)="showTimeFrom()" (bsValueChange)="setDateFrom($event)"
                                                required [disabled]="disableMessageEdition">
                                            <div class="input-group-btn">
                                                <button class="btn btn-info" [disabled]="disableMessageEdition" (click)="dpBegin.show()"
                                                    [attr.aria-expanded]="dpBegin.isOpen"><i
                                                        class="bi bi-calendar-event-fill"></i></button>
                                            </div>
                                        </div>
                                        <div [ngClass]="{'timerContainer':true, 'timeEndDate':true}"
                                            [ngStyle]="{'display': isHideTimerFrom?'none':'block'}">
                                            <timepicker [(ngModel)]="fromDate"></timepicker>
                                            <button class="btn btn-info btn-sm" (click)="hideTimeFrom(fromDate)"><i
                                                    class="bi bi-check-lg"></i></button>
                                        </div>
                                        <input type="hidden" id="fromh" #fromh="ngModel" [(ngModel)]="fromDate"
                                            required />
                                        <div *ngIf="fromh.errors" class="alert alert-danger">
                                            <p>
                                                Este campo es requerido.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-xs-12 col-sm-4 text-start">
                                    <label class="fw-bold">Fecha y Hora de finalizaci&oacute;n</label>
                                    <div class="form-group">
                                        <div class="input-group" id="dateTimeBeginDate">
                                            <input type="text" class="form-control" bsDatepicker #dpEnd="bsDatepicker"
                                                [bsValue]="toDate"
                                                value="{{ toDate | date:'dd-MM-yyyy' }} {{ toDate | date:'shortTime' }}"
                                                [outsideClick]="true" [minDate]="fromDate" (onHidden)="showTimeTo()"
                                                (bsValueChange)="setDateTo($event)"
                                                [bsConfig]="{ containerClass:'theme-blue', dateInputFormat: 'DD-MM-YYYY hh:mm a' }"
                                                required [disabled]="disableMessageEdition">
                                            <div class="input-group-btn">
                                                <button class="btn btn-info" [disabled]="disableMessageEdition" (click)="dpEnd.show()"
                                                    [attr.aria-expanded]="dpEnd.isOpen"><i
                                                        class="bi bi-calendar-event-fill"></i></button>
                                            </div>
                                        </div>
                                        <div [ngClass]="{'timerContainer':true, 'timeEndDate':true}"
                                            [ngStyle]="{'display': isHideTimerTo?'none':'block'}">
                                            <timepicker [(ngModel)]="toDate"></timepicker>
                                            <div class="input-group-btn">
                                                <button class="btn btn-info" (click)="hideTimeTo(toDate)"><i
                                                        class="bi bi-check-lg"></i></button>
                                            </div>
                                        </div>
                                        <input type="hidden" id="toh" #toh="ngModel" [(ngModel)]="toDate" required />
                                        <div *ngIf="toh.errors" class="alert alert-danger">
                                            <p>
                                                Este campo es requerido.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="form-group text-start col-7">
                                    <label class="fw-bold">Previsualizaci&oacute;n del mensaje en el Panel</label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="form-group col-8 mt-0">
                                    <div class="card mt-0 custom-layout">
                                        <div class="row">
                                            <div class="col-md-12 card-custom">
                                                <img *ngIf="customMessage.icon" class="icon ms-3"
                                                    [src]="'../../../assets/images/'+customMessage.icon+'.svg'">
                                                <p class="card-text custom-message ms-3">
                                                    {{customMessage.description != "" ? customMessage.description :
                                                    " "}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="form-group col-12">
                                    <input type="button" class="float-end btn btn-info btn-sm ms-3"
                                        *ngIf="showConfirmEditionButton" value="Confirmar Edición"
                                        [disabled]="!validateModel()" (click)="onEditMessage()" />
                                    <input type="button" *ngIf="showConfirmEditionButton"
                                        class="float-end btn btn-sm btn-outline-secondary mr-3" value="Cancelar"
                                        (click)="cancelEdition()" />
                                    <input type="submit" *ngIf="showCreateMessageButton"
                                        class="float-end btn btn-info btn-sm" [disabled]="!validateModel()"
                                        value="Crear Mensaje" (click)="onSubmit()" />
                                    <input type="button" *ngIf="showPublishMessage"
                                        class="float-end btn btn-info btn-sm"
                                        [value]="activateMessage ? 'Publicar Mensaje':'Pausar Mensaje'"
                                        (click)="activationMessage()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-1 col-sm-1 col-md-2 col-lg-3"></div>
        </div>
        <div id="creationMessageModalShow" class="modal fade" tabindex="-1" role="dialog"
            aria-labelledby="creationMessageModalShow" aria-hidden="true" data-keyboard="false" data-backdrop="static"
            style="background:rgba(0, 0, 0, 0.88);">
            <div class="modal-dialog" role="document" style="top:30%">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <h6 class="text-center pb-4"><b>¿Confirmas la creación del mensaje?</b></h6>
                        <div class="row">
                            <div class="form-group text-start">
                                <p class="text-center">Para publicar el mensaje luego de creado debes activarlo.</p>
                            </div>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-outline-secondary" data-bs-dismiss="modal">No</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-info" (click)="createCustomMessage()" data-bs-dismiss="modal">Sí,
                                    crear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="editMessageModalShow" class="modal fade" tabindex="-1" role="dialog"
            aria-labelledby="editMessageModalShow" aria-hidden="true" data-keyboard="false" data-backdrop="static"
            style="background:rgba(0, 0, 0, 0.88);">
            <div class="modal-dialog" role="document" style="top:30%">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <div class="ui-g-12 content text-center">
                            <h6 class="text-center pb-4"><b>¿Confirmas la modificaci&oacute;n del mensaje?</b></h6>
                            <div class="row">
                                <div class="form-group text-start">
                                    <p class="text-center">Luego de editarlo deberás activarlo para publicarlo en el
                                        panel de las estaciones
                                        seleccionadas</p>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3 justify-content-center">
                            <div class="col-auto">
                                <button class="btn btn-outline-secondary" data-bs-dismiss="modal">No</button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-info" (click)="editCustomMessage()"
                                    data-bs-dismiss="modal">S&iacute;
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="activationMessageModalShow" class="modal fade" tabindex="-1" role="dialog"
            aria-labelledby="editMessageModalShow" aria-hidden="true" data-keyboard="false" data-backdrop="static"
            style="background:rgba(0, 0, 0, 0.88);">
            <div class="modal-dialog" role="document" style="top:30%">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <div *ngIf="activateMessage">
                            <div class="ui-g-12 content text-center">
                                <h6 class="text-center pb-4"><b>¿Confirmas la activación del mensaje?</b></h6>
                                <div class="row">
                                    <div class="form-group text-start">
                                        <p class="text-center">Si confirmas el mensaje será enviado al Panel de JPV</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-3 justify-content-center">
                                <div class="col-auto">
                                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal">No</button>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-info" (click)="okActivationMessage(true)"
                                        data-bs-dismiss="modal">S&iacute;
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="deactivateMessage">
                            <div class="ui-g-12 content text-center">
                                <h6 class="text-center pb-4"><b>¿Confirmas pausar el mensaje?</b></h6>
                                <div class="row">
                                    <div class="form-group text-start">
                                        <p class="text-center">Si confirmas el mensaje será quitado del Panel de JPV</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-3 justify-content-center">
                                <div class="col-auto">
                                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal">No</button>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-info" (click)="okActivationMessage(false)"
                                        data-bs-dismiss="modal">S&iacute;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>