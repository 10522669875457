export class ComandaOrderCombo {
    id: string;
    orderDate: number;
    updatedStatusDate: number;
    clientName: string;
    platform: string;
    platformIcon: string;
    status: StatusCombo;
    statusIcon: string;
    combos: ComandaCombo[];
    sellerName: string;
    pump: number;
    shortId: number;

    constructor() {
        this.combos = [];
    }
}

export class ComandaCombo {
    id: number;
    quantity: number;
    name: string;
    description: string;
    image: string;
    price: number;

    public constructor() {
        this.id = 0;
        this.quantity = 0;
        this.name = 'n/a';
        this.description = 'n/a';
        this.image = 'n/a';
        this.price = 0;
    }
}

export class ComandaComboUpdateRequest {
    status: string;
}

export enum StatusCombo {
    Pending = "Pending",
    Preparing = "Preparing",
    Prepared = "Prepared",
    Delivering = "Delivering",
    Delivered = "Delivered",
    Canceling = "Canceling",
    Canceled = "Canceled",
}

// Panel de playa
export class BehaviorCombo {
    public imagenType: ImagenType;
    public widthComponent: WidthComponent;

    public constructor() {
        this.imagenType = ImagenType.vertical;
        this.widthComponent = WidthComponent.all;
    }
}

export enum ImagenType {
    vertical = 'vertical',
    horizontal = 'horizontal',
}

export enum WidthComponent {
    all = 'col-xs-12',
    middle = 'col-xs-6'
}