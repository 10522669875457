<div *ngIf="isLoadData && orderCombo.combos.length > 0" [class]="'precompra-container'">
    <panel-precompra-combo *ngFor="let item of orderCombo.combos; let i = index" [class]="'precompra-container__combo'"
        [position]="i" [count]="orderCombo.combos.length" [combo]="item" (addEvent)="addElement($event)"
        (substrackEvent)="substrackElement($event)" (cancelEvent)="cancelElement($event)"
        (addProduct)="addProduct()">
    </panel-precompra-combo>
</div>
<div *ngIf="isLoadData && orderCombo.combos.length < 1" class="alert-container">
    <alert type="info">
        <h1>
            Lo sentimos no se muestra ningun resultado.
            Verifique que existan combos seleccionados y que la
            franja horaria sea correcta y se encuentre activa
        </h1>
    </alert>
</div>