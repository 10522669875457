import { AlertaPromo } from "src/app/modules/app.generated.module";

export enum TipoAlerta {
    Todas = 0,
    Alertas = 1,
    Ofertas = 2
}

export class AlertaPromoFilter extends AlertaPromo {
    public style: string;
    public alertasFilas: number;
    public promoFilas: number;
    public promoCounts: number;
    public colSpan: number;
    public rowSpan: number;
    public colStyle: string;
    public rowStyle: string;
    public clases: string;
}