import { Injectable } from "@angular/core";
import { ComandaCombo, ComandaComboUpdateRequest, ComandaOrderCombo, StatusCombo } from "src/app/models/comanda/comanda.model";
import { BehaviorSubject, Observable, catchError, map, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { EntityResponse, Status } from "src/app/models/entity-response.model";
import { AbstractService } from "./abstract.service";

@Injectable({
    providedIn: 'root'
})
export class ComandaService extends AbstractService {

    /* Properties */
    public comboToCancel: string;
    private apies: string;
    public dataCombos = new BehaviorSubject(<ComandaCombo[]>[]);
    currentDataCombos = this.dataCombos.asObservable();
    public totalAmount = new BehaviorSubject(0);
    currenttotalAmount = this.totalAmount.asObservable();
    private web_01: string = "/web01/api";

    /**
     * Initialize the preventa seervice
     * @param http
     * @param baseUrlServer is the url for call to back-end services
     */
    constructor(protected override http: HttpClient) {
        super(http)
        this.comboToCancel = "0";
        this.apies = "0";
    }

    setTotalAmount(total: number) {
        this.totalAmount.next(total)
    }

    setDataCombo(data: ComandaCombo[]) {
        this.dataCombos.next(data);
    }

    /**
     * Get the combos pendiente-preparados
     * @param apies
     */
    getPendingPreparedCombos(apies: string): Observable<ComandaOrderCombo[]> {
        let d: Date = new Date();
        d = new Date(d.setDate(d.getDate() - 7));
        const month = parseInt(d.getMonth().toString()) + 1;
        const beginDate = month + '-' + d.getDate() + '-' + d.getFullYear();


        return super.httpDirectGet(`${this.web_01}/prepurchase/order?apies=${apies}&isForBilling=&beginDate=${beginDate}&endDate=&page=&pageSize=&filterByUpdateDate=&origen=0&clientEmail=&status=Pending&status=Prepared&order=Pending&order=Prepared`)
        .pipe(map(response => {
                const comandaOrderCombo: ComandaOrderCombo[] = [];
                
                const comandas = response.data?.map((comanda:any) => {
                    return {
                        id: comanda.id,
                        orderDate: comanda.orderDate,
                        updatedStatusDate: comanda.updatedStatusDate,
                        clientName: comanda.clientName,
                        platform: comanda.platform,
                        platformIcon: comanda.platformIcon,
                        status: <StatusCombo>comanda.status,
                        statusIcon: comanda.statusIcon,
                        combos: comanda.combos,
                        sellerName: comanda.sellerName,
                        pump: comanda.pump,
                        shortId: comanda.shortId,
                    }
                })

                comandaOrderCombo.push(...comandas);
                return comandaOrderCombo;
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }

    /**
     * Get the combos entregados-cancelados
     * @param apies
     */
    getDeliveredCanceledCombos(apies: string): Observable<ComandaOrderCombo[]> {

        return super.httpDirectGet(`${this.web_01}/prepurchase/order?apies=${apies}&status=Delivered&status=Canceled&order=updateDate_desc&filterByUpdateDate=true`)
        .pipe(map(response => {
                const comandaOrderCombo: ComandaOrderCombo[] = [];
                
                const comandas = response.data?.map((comanda:any) => {
                    return {
                        id: comanda.id,
                        orderDate: comanda.orderDate,
                        updatedStatusDate: comanda.updatedStatusDate,
                        clientName: comanda.clientName,
                        platform: comanda.platform,
                        platformIcon: comanda.platformIcon,
                        status: <StatusCombo>comanda.status,
                        statusIcon: comanda.statusIcon,
                        combos: comanda.combos,
                        sellerName: comanda.sellerName,
                        pump: comanda.pump,
                        shortId: comanda.shortId,
                    }
                })

                comandaOrderCombo.push(...comandas);
                return comandaOrderCombo;
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }
    
    setComboStatus(apies: string, idCombo: string, status: StatusCombo) {
        const comandaCombo:ComandaComboUpdateRequest = new ComandaComboUpdateRequest();
        comandaCombo.status = status;

        return super.httpPatch(`${this.web_01}/prepurchase/order`, comandaCombo,`?apies=${apies}&order=${idCombo}`).pipe(
            map(response => {
                return <EntityResponse>response.data;
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }

    /**
     * Get combos for panel in ws
     * @param apies
     */
    getPanelCombos(apies: string): Observable<EntityResponse> {
        this.apies = apies;

        return super.httpDirectGetEntityResponse(`${this.web_01}/prepurchase/schedule/combo?apies=${apies}`).pipe(
            map(response => {
                const dataResult = response.data;
                this.setDataCombo((<ComandaCombo[]>dataResult).map((x) => { x.quantity = 0; return x }));
                return <EntityResponse>response;
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }

    getIsAvailablePanelCombos(apies: string): Observable<boolean> {
        this.apies = apies;

        return super.httpDirectGet(`${this.web_01}/prepurchase/schedule/combo?apies=${apies}`).pipe(
            map(response => {
                const dataResult = response.data;
                if (dataResult?.length > 0){
                    return true;
                }else {
                    return false;
                }
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }

    insertComboOrder(apies: string, data: ComandaOrderCombo): Observable<EntityResponse> {
        const dataCombos = data.combos.map((x) => { return { 'comboId': x.id, 'comboQty': x.quantity }; })

        const order = { 'shortId': data.shortId, 'pump': data.pump, 'sellerName': data.sellerName, 'combos': dataCombos }

        return super.httpPost(`${this.web_01}/prepurchase/order?apies=${apies}`, order).pipe(
            map((response) => {
                return <EntityResponse>response.data;
            }),
            catchError(error => {
                return throwError(() => new Error(error));
            })
        );
    }
}